import React, { useState } from "react";
import { Typography, Grid2, styled } from "@mui/material";

import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetModelById } from "../../redux/model/selectors";
import { selectorGetUserPermissionIsAdmin } from "../../redux/userPermission/selectors";
import { GetUserId } from "../../utilities/ApiUtils";
import { ITaskImpact } from "../../utilities/types/TaskImpact";
import { selectorGetTaskImpactsByTaskId } from "../../redux/taskImpact/selectors";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import ModalTaskImpactCreate from "./modals/ModalTaskImpactCreate";
import ModalTaskImpactUpdate from "./modals/ModalTaskImpactUpdate";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import HeaderIcon from "@mui/icons-material/AcUnitTwoTone";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import HeaderColor from "@mui/material/colors/blueGrey";
import { selectorGetFailureModeById } from "../../redux/failureMode/selectors";
import { useFetchTaskImpactsPageHook } from "./Hooks";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import NewTaskImpact from "./NewTaskImpact";
import { StepLabourEnum } from "../../utilities/types/TaskApplicability";
import { useFetchFailureModesPageHook } from "../failureMode/Hooks";

const DivWrapper = styled("div")(({ theme }) => ({
  transition: "background-color 0.3s ease-out",
  backgroundColor: "rgba(0,0,0,0.001)",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "rgba(0,0,0,0.03)",
  },
  "&:active": {
    cursor: "pointer",
    backgroundColor: "rgba(255,255,255,0.3)",
  },
  border: "1px solid #EEE",
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

type ITaskImpactsSectionProps = {
  modelId: string;
  taskId: string;
};

const TaskImpactsSection = ({ modelId, taskId }: ITaskImpactsSectionProps) => {
  const model = useSelector((state: RootState) => selectorGetModelById(state, modelId));
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state));
  const canEdit = isAdmin || (model != null && model.createdBy === GetUserId());
  const taskImpacts = useSelector((store: RootState) => selectorGetTaskImpactsByTaskId(store, taskId));

  const { fetching: fetchingTasks } = useFetchTaskImpactsPageHook({
    pageNumber: 1,
    pageSize: 1500,
    modelId: modelId || "",
    taskId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingFailureModes } = useFetchFailureModesPageHook({
    pageNumber: 1,
    pageSize: 200,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  return (
    <TaskImpactSectionDisplay
      taskImpacts={taskImpacts}
      canEdit={canEdit}
      taskId={taskId}
      modelId={modelId}
      loading={fetchingTasks || fetchingFailureModes}
    />
  );
};

const TaskImpactSectionDisplay = ({
  taskImpacts,
  canEdit,
  taskId,
  modelId,
  loading,
}: {
  taskImpacts: ITaskImpact[];
  canEdit: boolean;
  taskId: string;
  modelId: string;
  loading: boolean;
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  function toggleCreateModal() {
    setShowCreateModal(!showCreateModal);
  }

  return (
    <WidgetSectionBase
      headerText="Manage Task Impacts"
      subheaderText="Create and maintain task impacts"
      headerIcon={<HeaderIcon />}
      headerColor={HeaderColor[800]}
      fullWidthHeader={true}
    >
      <LoaderAbsoluteCentred loading={loading} />

      <NewTaskImpact taskId={taskId} modelId={modelId} />
      {taskImpacts && taskImpacts.length ? (
        <Grid2 container>
          {taskImpacts.map((x) => (
            <Grid2 size={{xs:12}} key={x.taskImpactId}>
              <TaskImpactDisplay taskImpact={x} canEdit={true} />
            </Grid2>
          ))}
        </Grid2>
      ) : (
        <WidgetNoResultsPlaceholder fade={true} icon={HeaderIcon} text="No task impacts" />
      )}
      <ModalTaskImpactCreate
        open={showCreateModal}
        onCancelCallback={toggleCreateModal}
        onCompleteCallback={toggleCreateModal}
        taskId={taskId}
        modelId={modelId}
      />
    </WidgetSectionBase>
  );
};

const TaskImpactDisplay = ({ taskImpact, canEdit }: { taskImpact: ITaskImpact; canEdit: boolean }) => {
  const failureMode = useSelector((store: RootState) => selectorGetFailureModeById(store, taskImpact.failureModeId));

  const [showEditModal, setShowEditModal] = useState(false);

  function toggleEditModal() {
    setShowEditModal(!showEditModal);
  }

  return (
    <AnimationWrapper>
      <div>
        <DivWrapper onClick={toggleEditModal}>
          <Typography variant="body1">{taskImpact.stepDescription}</Typography>
          <Typography variant="caption" component="span" color="textPrimary">
            Failure Mode:{" "}
          </Typography>
          <Typography variant="caption" component="span" color="textSecondary">
            {failureMode ? failureMode.name : ""}
          </Typography>
          <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
            Age Reduction Factor:{" "}
          </Typography>
          <Typography variant="caption" component="span" color="textSecondary">
            {taskImpact.ageReductionFactor}
          </Typography>
          <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
            Labour:{" "}
          </Typography>
          <Typography variant="caption" component="span" color="textSecondary">
            {StepLabourEnum[taskImpact.stepLabour]}
          </Typography>
          <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
            Labour QTY:{" "}
          </Typography>
          <Typography variant="caption" component="span" color="textSecondary">
            {taskImpact.labourQuantity}
          </Typography>
          <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
            Duration:{" "}
          </Typography>
          <Typography variant="caption" component="span" color="textSecondary">
            {taskImpact.duration}
          </Typography>
        </DivWrapper>
        <ModalTaskImpactUpdate
          open={showEditModal}
          onCancelCallback={toggleEditModal}
          onCompleteCallback={toggleEditModal}
          taskImpact={taskImpact}
          canEdit={canEdit}
        />
      </div>
    </AnimationWrapper>
  );
};

export default TaskImpactsSection;
