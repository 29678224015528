import React, { useState } from "react";
import { TextField, Grid2, Divider, Button } from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch } from "react-redux";
import { ICostBundle } from "../../../utilities/types/CostBundle";
import { fetchUpdateCostBundle, fetchDeleteCostBundle } from "../../../redux/costBundle/actions";

interface FormValues {
  name: string;
  code: string;
  cost: number;
  orderNumber: number;
}

interface FormProps {
  costBundle: ICostBundle;
  onCompleteCallback(costBundle?: ICostBundle): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ICostBundle, AnyAction>;
  canEdit: boolean;
}

const FormCostBundleUpdate = (formProps: FormProps) => {
  const {
    onCancelCallback,
    costBundle: { costBundleId },
    canEdit,
  } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch, costBundle } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var updatedCostBundle = await dispatch(
      fetchUpdateCostBundle({
        ...values,
        costBundleId: costBundle.costBundleId,
      })
    );

    setSubmitting(false);
    if (updatedCostBundle) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedCostBundle);
    }
  };

  return (
    <Formik
      initialValues={{
        ...formProps.costBundle,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteCostBundle({ costBundleId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={costBundleId} name="costBundleId" />
            <Grid2 container spacing={2}>
              <Grid2 size={{xs:12}}>
                <TextField
                variant="standard"
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "code", "Code Label")}
                  fullWidth
                  margin="normal"
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <TextField
                variant="standard"
                  onChange={props.handleChange}
                  multiline
                  rows={3}
                  fullWidth
                  margin="normal"
                  {...getFormikFieldProps(props, "name", "Name")}
                />
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                variant="standard"
                  onChange={props.handleChange}
                  type="number"
                  fullWidth
                  margin="normal"
                  {...getFormikFieldProps(props, "cost", "Cost")}
                />
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                variant="standard"
                  onChange={props.handleChange}
                  type="number"
                  fullWidth
                  margin="normal"
                  {...getFormikFieldProps(props, "orderNumber", "Order Number")}
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <Divider light={true} />
              </Grid2>
              <Grid2 size={{xs:12}}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  {canEdit ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{ flexBasis: "33%" }}
                      onClick={toggleDeleteConfirmation}
                    >
                      Delete
                    </Button>
                  ) : null}

                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    {canEdit ? (
                      <Button
                        type="submit"
                        disabled={submitting}
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: 16 }}
                      >
                        Update
                      </Button>
                    ) : null}
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting || deleting} />
                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete costBundle"
                  subtitle="Confirm costBundle delete"
                  description="Are you sure that you'd like to remove this cost bundle?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteHandler}
                  confirmButtonText="Delete"
                />
              </Grid2>
            </Grid2>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          code: Yup.string()
            .label("Code")
            .min(3, "Please input 3 characters or more")
            .max(200, "Please input 200 characters or less")
            .required("Please provide a code label"),
          name: Yup.string().label("Name").max(500, "Please input 500 characters or less"),
          cost: Yup.number().min(0, "Zero or more."),
          orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999"),
        });
      }}
    />
  );
};

export default FormCostBundleUpdate;
