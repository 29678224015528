import React from "react";
import { Grid2, Breadcrumbs, Link, styled } from "@mui/material";

import { Container } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import AnimationWrapper from "../../generic/animations/AnimationWrapper";
import { GetAdminPage } from "../../../routes/RouteLinkHelpers";
import GlobalIdentifiersWidget from "../GlobalIdentifiersWidget";
import { HomeIconLink } from "../../generic/HomeIconLink";

const GridWrapper = styled(Grid2)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

interface IPageIdentifiersManagementProps {
  routeProps: RouteComponentProps<any>;
}

const PageIdentifiersManagement = ({ routeProps }: IPageIdentifiersManagementProps) => {
  return (
    <Container maxWidth="xl">
      <AnimationWrapper>
        <GridWrapper size={{xs:12}}>
          <Breadcrumbs aria-label="breadcrumb">
            <HomeIconLink />
            <Link color="inherit" href={GetAdminPage()}>
              Admin
            </Link>
            <Link color="textPrimary" aria-current="page" style={{ textDecoration: "none" }}>
              Identifiers
            </Link>
          </Breadcrumbs>
        </GridWrapper>
      </AnimationWrapper>
      <AnimationWrapper>
        <Grid2 container spacing={5} style={{ marginTop: 16 }}>
          <Grid2 size={{xs:12}}>
            <GlobalIdentifiersWidget />
          </Grid2>
        </Grid2>
      </AnimationWrapper>
    </Container>
  );
};

export default PageIdentifiersManagement;
