import React, { useState } from "react";
import { TextField, Grid2, Divider, Button, FormControlLabel, Checkbox } from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch } from "react-redux";
import {
  fetchUpdateOutputTypeInputFieldListValue,
  fetchDeleteOutputTypeInputFieldListValue,
} from "../../../redux/outputTypeInputFieldListValue/actions";
import { IOutputTypeInputFieldListValue } from "../../../utilities/types/OutputTypeInputFieldListValue";

interface FormValues {
  displayText: string;
  value: number;
  orderNumber: number;
  isDefault: boolean;
}

interface FormProps {
  outputTypeInputFieldListValue: IOutputTypeInputFieldListValue;
  onCompleteCallback(outputTypeInputFieldCategory?: IOutputTypeInputFieldListValue): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IOutputTypeInputFieldListValue, AnyAction>;
}

const FormOutputTypeInputFieldListValueUpdate = (formProps: FormProps) => {
  const { onCancelCallback, outputTypeInputFieldListValue } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var outputTypeInputFieldCategory = await dispatch(
      fetchUpdateOutputTypeInputFieldListValue({
        ...values,
        outputTypeInputFieldListValueId:
        outputTypeInputFieldListValue.outputTypeInputFieldListValueId,
      })
    );

    setSubmitting(false);
    if (outputTypeInputFieldCategory) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(outputTypeInputFieldCategory);
    }
  };

  return (
    <Formik
      initialValues={{
        displayText: outputTypeInputFieldListValue.displayText,
        value: outputTypeInputFieldListValue.value,
        orderNumber: outputTypeInputFieldListValue.orderNumber,
        isDefault: outputTypeInputFieldListValue.isDefault,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          const {
            outputTypeInputFieldListValue: { outputTypeInputFieldListValueId },
          } = formProps;

          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(
            fetchDeleteOutputTypeInputFieldListValue({ outputTypeInputFieldListValueId })
          );

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        return (
          <form onSubmit={props.handleSubmit}>
            <Grid2 container spacing={2}>
            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "displayText", "Display Text")}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  fullWidth
                  margin="normal"
                  type="number"
                  variant="standard"
                  inputProps={{
                    step: "0.1",
                  }}
                  {...getFormikFieldProps(props, "value", "Value")}
                />
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "orderNumber", "Order Number")}
                />
              </Grid2>

              <Grid2 size={{xs:12, sm:6}} style={{ alignSelf: "flex-end" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.values.isDefault}
                      onChange={() => props.setFieldValue("isDefault", !props.values.isDefault)}
                      value={true}
                      color="primary"
                    />
                  }
                  label="Is Default"
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <Divider light={true} />
              </Grid2>
              <Grid2 size={{xs:12}}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ flexBasis: "33%" }}
                    onClick={toggleDeleteConfirmation}
                  >
                    Delete
                  </Button>
                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      type="submit"
                      disabled={submitting}
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: 16 }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting} />
                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete confirmation"
                  subtitle="Confirm category delete"
                  description="Are you sure that you'd like to remove this category?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteHandler}
                  confirmButtonText="Delete"
                />
              </Grid2>
            </Grid2>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          displayText: Yup.string()
            .label("Name")
            .min(1, "Please input 1 character or more")
            .max(50, "Please input 50 characters or less")
            .required("Please provide a name"),
          value: Yup.number()
            .label("Value")
            .min(-2147483647, "More than -2,147,483,647")
            .max(2147483647, "Less than 2,147,483,647"),
          orderNumber: Yup.number().label("Order Number").min(0, "Zero or more.").max(1000, "Less than 1,000"),
        });
      }}
    />
  );
};

export default FormOutputTypeInputFieldListValueUpdate;
