import React from "react";
import { Typography, Grid2, Divider, styled } from "@mui/material";
import { ICostBundle } from "../../utilities/types/CostBundle";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import PlaceholderIcon from "@mui/icons-material/ReceiptTwoTone";
import { FormatNumberWithCommas } from "../../utilities/Helpers";
import CostBundleSplitOut from "./CostBundleSplitOut";

const DivWrapper = styled("div")(({ theme }) => ({
  "& .costBundleWrapper": {
    padding: theme.spacing(2),
    position: "relative",
    cursor: "pointer",
    backgroundColor: "rgba(0,0,0,0)",
    transition: "background 0.25s ease-out !important",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.02)",
    },
  },
  "& .costBundleMainWrapper": {
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
}));

type ICostBundleReviewCausesTabProps = {
  costBundles: ICostBundle[];
};

const CostBundleReviewCausesTab = ({ costBundles }: ICostBundleReviewCausesTabProps) => {
  return <CostBundleReviewCausesTabDisplay costBundles={costBundles} />;
};

type ICostBundleReviewCausesTabDisplayProps = {
  costBundles: ICostBundle[];
};

const CostBundleReviewCausesTabDisplay = ({ costBundles }: ICostBundleReviewCausesTabDisplayProps) => {
  return (
    <DivWrapper>
      <AnimationWrapper>
        <div>
          {costBundles.map((costBundle) => {
            return (
              <>
                <Grid2 container className="costBundleWrapper">
                  <Grid2 size={{xs:12}}>
                    <div className="costBundleMainWrapper">
                      <div>
                        <Typography variant="body1" style={{ textTransform: "capitalize" }}>
                          {costBundle.code} -{" "}
                          <span style={{ opacity: 0.7 }}>${FormatNumberWithCommas(costBundle.cost)}</span>
                        </Typography>
                        <Typography variant="caption">{costBundle.name}</Typography>
                        <CostBundleSplitOut spitOut={costBundle.splitOut} />
                      </div>
                    </div>
                  </Grid2>
                </Grid2>
                <Divider light={true} />
              </>
            );
          })}
          {(!costBundles || !costBundles.length) && (
            <WidgetNoResultsPlaceholder text="No cost bundles" icon={PlaceholderIcon} flip={true} />
          )}
        </div>
      </AnimationWrapper>
    </DivWrapper>
  );
};

export default CostBundleReviewCausesTab;
