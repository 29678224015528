export interface ISolver {
    solverId: string,
    name: string,
    description: string,
    inputBucket: string,
    outputBucket: string,
    summaryOutputBucket: string,
    status: SolverStatus,
    mainImageId: string,
    created: Date,
    updated: Date,
    createdBy: string,
    updatedBy: string,
}

export enum SolverSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export enum SolverStatus {
    AdminOnly = 10,
    Preview = 20,
    Enabled = 30
}

export type TSolverInputFieldValue = {
    value?: number,
    valueString?: string,
    valueDateTime?: Date,
    solverInputFieldValueId?: string,
}
