import React, { useState } from "react";
import { Grid2, Button, Avatar, styled } from "@mui/material";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { useDispatch, useSelector } from "react-redux";
import { EnableComponentHierachy } from "../../redux/component/actions";
import { IModel } from "../../utilities/types/Model";
import { green } from "@mui/material/colors";
import AddCategoryIcon from "@mui/icons-material/AddCircleOutline";
import ModalFunctionCreate from "./modals/ModalFunctionCreate";
import { selectorGetModelById } from "../../redux/model/selectors";
import { RootState } from "../../redux";
import chatgptLogo from "../../assets/icons/chatgpt.svg";
import ModalAiSuggestFunction from "./modals/ModalAiSuggestFunction";

const GridWrapper = styled(Grid2)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  alignItems: "center",
  "& .btnWrapper": {
    display: "flex",
    alignItems: "stretch",
    gap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  "& .addComponentButton": {
    color: green[500],
    fontWeight: 500,
    textTransform: "capitalize",
    transition: "background 200ms ease-out",
    width: "100%",
  },
  "& .aiButton": {
    flexShrink: 0,
  },
  "& .icon": {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
}));

type IFunctionModelNewFunctionProps = {
  modelId: string;
  componentId: string;
};

const ModelBuilderNewFunction = ({ modelId, componentId }: IFunctionModelNewFunctionProps) => {
  const [showCreateFunctionModal, setShowCreateFunctionModal] = useState(false);
  const [loading] = useState(false);
  const [showAiSuggestModal, setShowAiSuggestModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const model = useSelector((store: RootState) => selectorGetModelById(store, modelId));

  function toggleShowCreateFunctionModal() {
    setShowCreateFunctionModal(!showCreateFunctionModal);
  }

  function onCompleteCallback() {
    dispatch(EnableComponentHierachy({ model: model as IModel }));
    setShowCreateFunctionModal(!showCreateFunctionModal);
  }

  function toggleShowAiSuggestModal() {
    setShowAiSuggestModal((prev) => !prev);
  }

  function onCompleteAiSuggestCallback() {
    toggleShowAiSuggestModal();
  }

  return (
    <GridWrapper container>
      <Grid2 size={{xs:12}}>
        <div className="btnWrapper">
          <Button
            id="btn-create-function"
            variant="outlined"
            className="addComponentButton"
            onClick={toggleShowCreateFunctionModal}
          >
            <AddCategoryIcon style={{ marginRight: "8px" }} /> Add a new function
          </Button>
          <Button id="btn-create-component" variant="outlined" className="aiButton" onClick={toggleShowAiSuggestModal}>
            <Avatar src={chatgptLogo} className="icon" />
          </Button>
        </div>
      </Grid2>
      <ModalFunctionCreate
        open={showCreateFunctionModal}
        onCancelCallback={toggleShowCreateFunctionModal}
        onCompleteCallback={onCompleteCallback}
        modelId={model?.modelId ?? ""}
        componentId={componentId}
      />
      <ModalAiSuggestFunction
        open={showAiSuggestModal}
        onCancelCallback={toggleShowAiSuggestModal}
        onCompleteCallback={onCompleteAiSuggestCallback}
        modelId={model?.modelId ?? ""}
        componentId={componentId}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </GridWrapper>
  );
};

export default ModelBuilderNewFunction;
