import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/DeveloperModeRounded";
import { IFailureMode } from "../../../utilities/types/FailureMode";
import FormFunctionalFailureClone from "../forms/FormFunctionalFailureClone";
import { IFunctionalFailure } from "../../../utilities/types/FunctionalFailure";
import { DivWrapper } from "./ModalFunctionalFailureUpdate";
import FunctionIcon from "@mui/icons-material/FunctionsOutlined";

export interface IModalFunctionalFailureCloneProps {
  open: boolean;
  onCancelCallback(): void;
  modelId: string;
  functionalFailure: IFunctionalFailure;
  onCompleteCallback(failureModes?: IFailureMode[]): void;
}

function ModalFunctionalFailureClone({
  onCancelCallback,
  open,
  onCompleteCallback,
  modelId,
  functionalFailure,
}: IModalFunctionalFailureCloneProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Clone a functional failure"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<DivWrapper><div className="iconWrapper">
        <FunctionIcon />
      </div></DivWrapper>}
    >
      <DialogContent>
        <FormFunctionalFailureClone
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
          functionalFailureId={functionalFailure.functionalFailureId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalFunctionalFailureClone;
