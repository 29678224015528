import React from "react";
import { TextField, Grid2, Divider, Button, Checkbox, FormControlLabel } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { IAssetInputFieldCategory } from "../../../utilities/types/AssetInputFieldCategory";
import { fetchCreateAssetInputFieldListValue } from "../../../redux/assetInputFieldListValue/actions";
import { IAssetInputFieldListValue } from "../../../utilities/types/AssetInputFieldListValue";
import StringListInput from "../../generic/widgets/StringListInput";

interface FormValues {
  displayText: string[];
  value: number;
  orderNumber: number;
  isDefault: boolean;
}

interface FormProps {
  assetInputFieldId: string;
  onCompleteCallback(assetInputFieldListValue?: IAssetInputFieldListValue): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IAssetInputFieldCategory, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  const { values, errors, handleChange } = props;

  const handleNameChange = (newStringList: string[]) => {
    handleChange({
      target: {
        name: "displayText",
        value: newStringList,
      },
    });
  };
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
        <Grid2 size={{xs:12}}>
          <StringListInput
            label="Display Text(s)"
            onStringListChange={handleNameChange}
            stringList={values.displayText}
            errors={errors.displayText}
          />
        </Grid2>

        <Grid2 size={{xs:12, sm:6}}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "value", "Value")}
            type="number"
            inputProps={{
              step: 0.1,
            }}
            fullWidth
            margin="normal"
            variant="standard"
          />
        </Grid2>

      <Grid2 size={{xs:12, sm:6}}>
          <TextField
            onChange={props.handleChange}
            type="number"
            fullWidth
            variant="standard"
            margin="normal"
            {...getFormikFieldProps(props, "orderNumber", "Order Number")}
          />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.values.isDefault}
                onChange={() => props.setFieldValue("isDefault", !props.values.isDefault)}
                value={true}
                color="primary"
              />
            }
            label="Is Default"
          />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <Divider light={true} />
        </Grid2>

        <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormAssetInputFieldListValueCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    displayText: [],
    value: 0,
    orderNumber: 1,
    isDefault: false,
  }),
  validationSchema: Yup.object().shape({
    displayText: Yup.array().of(
      Yup.string()
        .label("Display Text")
        .min(2, "Please input 2 character or more")
        .max(50, "Please input 50 characters or less")
        .required("Please provide a display value")
    ),
    value: Yup.number()
      .label("Value")
      .min(-2147483647, "More than -2,147,483,647")
      .max(2147483647, "Less than 2,147,483,647")
      .required("Please provide a value"),
    orderNumber: Yup.number().label("Order Number").min(0, "Zero or more.").max(999, "Less than 1000"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, assetInputFieldId } = props;

    const assetInputFieldListValuesToCreate = values.displayText.map((displayText) => ({
      ...values,
      assetInputFieldId,
      displayText,
    }));

    // Map dispatch via props
    var resp = await dispatch(
      fetchCreateAssetInputFieldListValue({ assetInputFieldListValues: assetInputFieldListValuesToCreate })
    );

    setSubmitting(false);

    if (resp) {
      onCompleteCallback(resp);
    }
  },
})(InnerForm);

export default FormAssetInputFieldListValueCreate;
