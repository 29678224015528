import React from "react";
import { TextField, Grid2, Divider, Button, Checkbox, FormControlLabel } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { fetchCreateSolverInputFieldListValue } from "../../../redux/solverInputFieldListValue/actions";
import { ISolverInputFieldListValue } from "../../../utilities/types/SolverInputFieldListValue";

interface FormValues {
  displayText: string;
  value: number;
  orderNumber: number;
  isDefault: boolean;
}

interface FormProps {
  solverInputFieldId: string;
  onCompleteCallback(solverInputFieldListValue?: ISolverInputFieldListValue): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ISolverInputFieldListValue, AnyAction>;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
      <Grid2 size={{xs:12, sm:6}}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "displayText", "Display Text")}
            fullWidth
            margin="normal"
            variant="standard"
          />
        </Grid2>

      <Grid2 size={{xs:12, sm:6}}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "value", "Value")}
            type="number"
            inputProps={{
              step: 0.1,
            }}
            fullWidth
            variant="standard"
            margin="normal"
          />
        </Grid2>

      <Grid2 size={{xs:12, sm:6}}>
          <TextField
            onChange={props.handleChange}
            type="number"
            fullWidth
            margin="normal"
            variant="standard"
            {...getFormikFieldProps(props, "orderNumber", "Order Number")}
          />
        </Grid2>

        <Grid2 size={{xs:12, sm:6}} style={{ alignSelf: "flex-end" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.values.isDefault}
                onChange={() => props.setFieldValue("isDefault", !props.values.isDefault)}
                value={true}
                color="primary"
              />
            }
            label="Is Default"
          />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <Divider light={true} />
        </Grid2>

        <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormSolverInputFieldListValueCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    displayText: "",
    value: 0,
    orderNumber: 1,
    isDefault: false,
  }),
  validationSchema: Yup.object().shape({
    displayText: Yup.string()
      .label("Display Text")
      .min(1, "Please input 1 character or more")
      .max(50, "Please input 50 characters or less")
      .required("Please provide a display value"),
    value: Yup.number()
      .label("Value")
      .min(-2147483647, "More than -2,147,483,647")
      .max(2147483647, "Less than 2,147,483,647")
      .required("Please provide a value"),
    orderNumber: Yup.number().label("Order Number").min(0, "Zero or more.").max(999, "Less than 1000"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, solverInputFieldId } = props;

    // Map dispatch via props
    var resp = await dispatch(
      fetchCreateSolverInputFieldListValue({
        ...values,
        solverInputFieldId,
      })
    );

    setSubmitting(false);

    if (resp) {
      onCompleteCallback(resp);
    }
  },
})(InnerForm);

export default FormSolverInputFieldListValueCreate;
