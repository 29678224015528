import { Button, DialogContent as MuiDialogContent, Divider, Grid2, InputBase, Typography, styled } from "@mui/material";
import HeaderIcon from "@mui/icons-material/SupervisorAccount";
import React from "react";
import { useDebounceState } from "../../../utilities/misc";
import { join } from "../../../utilities/Styles";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import { IUser, useAddRemoveContributor, useFetchContributors } from "../Hooks";

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: "0 !important",
  "& .columnContainer": {
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    maxHeight: "25rem",
    width: "100%",
    flex: "1 1 0%",
    overflow: "hidden",
    gap: `${theme.spacing(2)}px`,
  },
  "& .column": {
    maxHeight: "25rem",
    overflowX: "hidden",
    overflowY: "auto",
    paddingBottom: theme.spacing(2),
  },
  "& .contributorListItem": {
    padding: theme.spacing(2),
    transition: "background-color 0.3s ease-out",
    backgroundColor: "rgba(0,0,0,0.001)",
  },
  "& .listItemHighlight": {
    backgroundColor: "rgba(255, 0, 0, 0.2)",
  },
  "& .listItemHover": {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0,0,0,0.03)",
    },
    "&:active": {
      cursor: "pointer",
      backgroundColor: "rgba(255,255,255,0.3)",
    },
  },
}));

export interface IModalContributorDisplayProps {
  onCancelCallback(): void;
  open: boolean;
  modelId: string;
  canEdit: boolean;
}

type ContentProps = {
  modelId: string;
  onCancel: () => void;
};

export default function ContributorsModal({ modelId, onCancelCallback, open, canEdit }: IModalContributorDisplayProps) {
  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={canEdit ? "Manage Private Permissions" : "View moderators"}
      subtitle={
        canEdit
          ? "Click on a user to add/remove entity permissions."
          : "Users who have permissions for this asset class."
      }
      headerIcon={<HeaderIcon />}
      style={{ width: "90%", maxWidth: canEdit ? "50rem" : "25rem" }}
    >
      <DialogContent>
        {canEdit ? (
          <ContentAdmin modelId={modelId} onCancel={onCancelCallback} />
        ) : (
          <ContentReadOnly modelId={modelId} onCancel={onCancelCallback} />
        )}
      </DialogContent>
    </WidgetModalBase>
  );
}

function ContentReadOnly({ onCancel, modelId }: ContentProps) {
  const [fetching, , contributors] = useFetchContributors(modelId);

  return (
    <>
      <LoaderAbsoluteCentred loading={fetching} />
      <div className="column">
        <ContributorList contributors={contributors} />
      </div>
      <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
        <Button variant="text" onClick={onCancel}>
          Close
        </Button>
      </Grid2>
    </>
  );
}

function ContentAdmin({ onCancel, modelId }: ContentProps) {
  const [searchText, setSearchText] = useDebounceState("", 500);
  const [updating, addContributor, removeContributor] = useAddRemoveContributor();
  const [fetching, users, contributors] = useFetchContributors(modelId, searchText);

  const userList: ListItemProps[] = users.map((user) => ({
    ...user,
    onItemClick: () => addContributor({ modelId, userId: user.userDetailId }),
  }));

  const contributorList: ListItemProps[] = contributors.map((cuser) => ({
    ...cuser,
    onItemClick: () => removeContributor({ entityPermissionId: cuser.entityPermissionId }),
  }));

  return (
    <>
      <LoaderAbsoluteCentred loading={fetching || updating} />

      <InputBase
        type="text"
        placeholder="Search users"
        inputProps={{ "aria-label": "Search users" }}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Divider style={{ margin: 4 }} />

      <div className="columnContainer">
        <div className="column">
          <Typography variant="overline">Users</Typography>
          <ContributorList contributors={userList} emptyListText="No users" />
        </div>
        <div className="column">
          <Typography variant="overline">Have Permissions</Typography>
          <ContributorList contributors={contributorList} />
        </div>
      </div>
      <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
        <Button disabled={updating} variant="text" onClick={onCancel}>
          Close
        </Button>
      </Grid2>
    </>
  );
}

type ListItemProps = IUser & { onItemClick?: () => void };
type ListProps = { contributors: ListItemProps[]; emptyListText?: string };

function ContributorList({ contributors, emptyListText }: ListProps) {
  return (
    <>
      {contributors.length ? (
        contributors.map((contributor, i) => (
          <>
            {i > 0 && (
              <Grid2 size={{xs:12}}>
                <Divider light={true} />
              </Grid2>
            )}
            <Grid2 size={{xs:12}}>
              <ContributorListItem {...contributor} />
            </Grid2>
          </>
        ))
      ) : (
        <Grid2 size={{xs:12}}>
          <WidgetNoResultsPlaceholder text={emptyListText ?? "No moderators"} flip={true} icon={HeaderIcon} />
        </Grid2>
      )}
    </>
  );
}

function ContributorListItem({ displayName, company, title, requestedAccess, onItemClick }: ListItemProps) {
  return (
    <div
      onClick={onItemClick}
      className={join("contributorListItem", requestedAccess && "listItemHighlight", onItemClick && "listItemHover")}
    >
      <Typography variant="body1">{displayName}</Typography>
      <Typography variant="body2">
        {company}
        {company && title && " - "}
        {title && (
          <Typography variant="body2" component="span" color="textSecondary">
            {title}
          </Typography>
        )}
      </Typography>
    </div>
  );
}
