import React from "react";
import { Typography, Grid2, Divider, styled } from "@mui/material";

import { ITask, TaskTypeEnum } from "../../utilities/types/Task";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import PlaceholderIcon from "@mui/icons-material/AssignmentTwoTone";

const DivWrapper = styled("div")(({ theme }) => ({
  "& .taskWrapper": {
    padding: theme.spacing(1),
    position: "relative",
  },
  "& .taskMainWrapper": {
    display: "inline-block",
    marginLeft: theme.spacing(1),
    width: "100%",
    padding: 16,
  },
}));

type ITaskReviewCausesTabProps = {
  tasks: ITask[];
};

const TaskReviewCausesTab = ({ tasks }: ITaskReviewCausesTabProps) => {
  return <TaskReviewCausesTabDisplay tasks={tasks} />;
};

type ITaskReviewCausesTabDisplayProps = {
  tasks: ITask[];
};

const TaskReviewCausesTabDisplay = ({ tasks }: ITaskReviewCausesTabDisplayProps) => {
  return (
    <DivWrapper>
      <AnimationWrapper>
        <div>
          {tasks.map((task) => {
            return (
              <>
                <Grid2 container className="taskWrapper">
                  <Grid2 size={{xs:9}}>
                    <div className="taskMainWrapper">
                      <Typography variant="body1" style={{ textTransform: "capitalize" }}>
                        {task.name} - <span style={{ opacity: 0.7 }}>{task.description}</span>
                      </Typography>
                      <div style={{ marginTop: 8 }}>
                        <Typography variant="caption">
                          Type:
                          <span style={{ opacity: 0.8 }}>
                            {task.type === TaskTypeEnum.Impact
                              ? "Planned/corrective"
                              : task.type === TaskTypeEnum.Applicability
                              ? "Inspection/monitoring"
                              : task.type}
                          </span>
                        </Typography>
                        <br />
                        <Typography variant="caption">
                          Duration: <span style={{ opacity: 0.8 }}>{task.duration}</span>
                        </Typography>
                        <br />
                        <Typography variant="caption">
                          Ramp Time: <span style={{ opacity: 0.8 }}>{task.rampTime}</span>
                        </Typography>
                        <br />
                      </div>
                    </div>
                  </Grid2>
                </Grid2>
                <Divider light={true} />
              </>
            );
          })}
          {(!tasks || !tasks.length) && (
            <WidgetNoResultsPlaceholder text="No tasks" icon={PlaceholderIcon} flip={true} />
          )}
        </div>
      </AnimationWrapper>
    </DivWrapper>
  );
};

export default TaskReviewCausesTab;
