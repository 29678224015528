import React, { useState } from "react";
import {
  TextField,
  Grid2,
  Divider,
  Button,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { IPlan, PlanTypeEnum } from "../../../utilities/types/Plan";
import { fetchUpdatePlan, fetchDeletePlan } from "../../../redux/plan/actions";
import { selectorGetEventsByModelId } from "../../../redux/event/selectors";
import { IEvent } from "../../../utilities/types/Event";
import { Autocomplete } from "@mui/lab";
import TimeConverter from "../../taskApplicability/forms/TimeConverter";

interface FormValues {
  name: string;
  code: string;
  orderNumber: number;
  eventInterval: number;
  doNotOptimise: boolean;
}

interface FormProps {
  plan: IPlan;
  primaryEventObj?: IEvent;
  onCompleteCallback(plan?: IPlan): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IPlan, AnyAction>;
  canEdit: boolean;
}

const FormPlanUpdate = (formProps: FormProps) => {
  const {
    onCancelCallback,
    plan: { planId, modelId },
    canEdit,
  } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);
  const events = useSelector((store: RootState) => selectorGetEventsByModelId(store, modelId));

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch, plan } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var updatedPlan = await dispatch(
      fetchUpdatePlan({
        ...values,
        planId: plan.planId,
      })
    );

    setSubmitting(false);
    if (updatedPlan) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedPlan);
    }
  };

  return (
    <Formik
      initialValues={{
        ...formProps.plan,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeletePlan({ planId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={planId} name="planId" />
            <Grid2 container spacing={2}>
              <Grid2 size={{xs:12}}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "code", "Code Label")}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <TextField
                  onChange={props.handleChange}
                  multiline
                  rows={3}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "name", "Name")}
                />
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "orderNumber", "Order Number")}
                />
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <TimeConverter
                  label="Event Interval"
                  defaultValueOverride={props.values.eventInterval}
                  onValueChangeCallback={(value) => {
                    props.setFieldValue("eventInterval", value);
                  }}
                  error={props.errors.eventInterval}
                />
              </Grid2>
              <Grid2 size={{xs:12}} style={{ alignSelf: "flex-end" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.values.doNotOptimise}
                      onChange={() => props.setFieldValue("doNotOptimise", !props.values.doNotOptimise)}
                      value={true}
                      color="primary"
                    />
                  }
                  label="Do Not Optimise"
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <FormControl fullWidth={true}>
                  <Autocomplete
                    {...getFormikFieldProps(props, "primaryEventObj", "Primary Event")}
                    style={{ display: "block" }}
                    options={events}
                    getOptionLabel={(option) => option.name}
                    defaultValue={events.find((element) => {
                      return element.eventId === props.values.primaryEventId;
                    })}
                    onChange={(_, value) => {
                      props.setFieldValue("primaryEventId", value ? value.eventId : "");
                      props.setFieldValue("primaryEventObj", value);
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} label="Event" />}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={{xs:12}}>
                <FormControl fullWidth={true}>
                  <InputLabel id="lbl-plan-type" variant="standard">
                    Type
                  </InputLabel>
                  <Select
                    labelId="lbl-plan-type"
                    variant="standard"
                    {...getFormikFieldProps(props, "planType", "Plan Type")}
                    onChange={(e) => {
                      props.setFieldValue("planType", e.target.value);
                    }}
                    style={{ display: "block" }}
                    id="txt-plan-type"
                  >
                    <MenuItem value={PlanTypeEnum.Recurring}>Recurring</MenuItem>
                    <MenuItem value={PlanTypeEnum.OneOff}>One-Off</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>

              <Grid2 size={{xs:12}}>
                <Divider light={true} />
              </Grid2>
              <Grid2 size={{xs:12}}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  {canEdit ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{ flexBasis: "33%" }}
                      onClick={toggleDeleteConfirmation}
                    >
                      Delete
                    </Button>
                  ) : null}

                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    {canEdit ? (
                      <Button
                        type="submit"
                        disabled={submitting}
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: 16 }}
                      >
                        Update
                      </Button>
                    ) : null}
                  </div>

                  <LoaderAbsoluteCentred loading={submitting || deleting} />
                  <WidgetModalConfirmationDialog
                    open={showDeleteConfirmation}
                    title="Delete plan"
                    subtitle="Confirm plan delete"
                    description="Are you sure that you'd like to remove this plan?"
                    onCancelCallback={toggleDeleteConfirmation}
                    onConfirmCallback={deleteHandler}
                    confirmButtonText="Delete"
                  />
                </div>
              </Grid2>
            </Grid2>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          code: Yup.string()
            .label("Code")
            .min(3, "Please input 3 characters or more")
            .max(200, "Please input 200 characters or less")
            .required("Please provide a code label"),
          name: Yup.string().label("name").max(500, "Please input 500 characters or less"),
          orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 99999"),
          eventInterval: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 99999"),
        });
      }}
    />
  );
};

export default FormPlanUpdate;
