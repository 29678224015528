import React, { useState } from "react";
import {
  Typography,
  Grid2,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetModelById } from "../../redux/model/selectors";
import { IModel, ModelStatusEnum } from "../../utilities/types/Model";
import { CauseActionTypeEnum, ICause } from "../../utilities/types/Cause";
import { useFetchCausesPageHook } from "./Hooks";
import { selectorGetCausesByModelId } from "../../redux/cause/selectors";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { fetchSearchCauses } from "../../redux/cause/actions";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import PlaceholderIcon from "@mui/icons-material/ViewAgenda";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import AddIcon from "@mui/icons-material/InputTwoTone";
import PlusIcon from "@mui/icons-material/Add";
import EditComponentIcon from "@mui/icons-material/EditOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IdentifierMappingTypeEnum } from "../../utilities/types/IdentifierMapping";
import IdentifierMappingsRenderer from "../identifierMapping/IdentifierMappingsRenderer";
import { useFetchCauseAssetInputFieldMappingsPageHook } from "../causeAssetInputFieldMapping/Hooks";
import DiscussionsIcon from "../discussion/DiscussionIcon";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { selectorGetUserPermissionIsAdmin } from "../../redux/userPermission/selectors";
import { EmptyGuid } from "../../utilities/Helpers";
import ModalCauseCreate from "./modals/ModalCauseCreate";
import ModalCauseUpdate from "./modals/ModalCauseUpdate";
import ModelBuilderNewCause from "./ModelBuilderNewCause";
import CauseAssetInputFieldMappingsRenderer from "../causeAssetInputFieldMapping/CauseAssetInputFieldMappingsRenderer";
import ModalCauseAssetInputFieldMappingCreate from "../causeAssetInputFieldMapping/modals/ModalCauseAssetInputFieldMappingCreate";

const DivWrapper = styled("div")(({ theme }) => ({
  "& .causeWrapper": {
    position: "relative",
    backgroundColor: "rgba(0,0,0,0)",
  },
  "& .causeMainWrapper": {
    display: "inline-block",
    marginLeft: theme.spacing(3),
  },
  "& .causeDivider": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  "& .causeDescription": {
    whiteSpace: "pre-line",
  },
  "& .orangeDraft": {
    color: "orange",
  },
  "& .MuiAccordionSummary-content": {
    margin: "8px 0",
    "&..Mui-expanded": {
      margin: "8px 0",
    },
  },
  "& .header": {
    fontWeight: "bold",
  },
  "& .headerBar": {
    display: "flex",
    justifyContent: "space-between",
    gap: 8,
    marginLeft: "auto",
    marginRight: 4,
  },
  "& .headerLeft": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 4,
  },
}));

type ICauseModelBuilderTabProps = {
  modelId: string;
  canEdit: boolean;
};

const CauseModelBuilderTab = ({ modelId, canEdit }: ICauseModelBuilderTabProps) => {
  const model = useSelector((state: RootState) => selectorGetModelById(state, modelId));
  const dispatch = useDispatch();
  const causes = useSelector((store: RootState) => selectorGetCausesByModelId(store, modelId));


  const { fetching: fetchingCauses } = useFetchCausesPageHook({
    pageNumber: 1,
    pageSize: 200,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingCauseInputMappings } = useFetchCauseAssetInputFieldMappingsPageHook({
    pageNumber: 1,
    pageSize: 200,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const onUploadComplete = async (causeId: string) => {
    await dispatch(fetchSearchCauses({ causeId, modelId, pageNumber: 1, pageSize: 1 }));
  };

  return (
    <CauseModelBuilderTabDisplay
      model={model}
      canEdit={canEdit}
      causes={causes}
      loading={fetchingCauseInputMappings || fetchingCauses}
      onUploadComplete={onUploadComplete}
    />
  );
};

type ICauseModelBuilderTabDisplayProps = {
  canEdit: boolean;
  model?: IModel;
  causes: ICause[];
  loading: boolean;
  onUploadComplete(causeId: string): void;
};

const CauseModelBuilderTabDisplay = ({
  model,
  causes,
  loading,
  canEdit,
  onUploadComplete,
}: ICauseModelBuilderTabDisplayProps) => {
  const [showCreateCauseModal, setShowCreateCauseModal] = useState(false);
  const [causeToUpdate, setCauseToUpdate] = useState<ICause>();


const toggleShowCreateCauseModal=()=>setShowCreateCauseModal(!showCreateCauseModal)

const onCauseClick=(cause: ICause)=> setCauseToUpdate(cause)
  

  const hideCauseUpdate=()=> setCauseToUpdate(undefined);
  

  if (!model) return <Typography variant="caption">Model not found.</Typography>;
  return (
    <DivWrapper>
      <ModelBuilderNewCause model={model} />

      {loading ? (
        <LoaderAbsoluteCentred loading={loading} />
      ) : (
        <AnimationWrapper>
          <div>
            {causes.map((cause) => (
              <ModelBuilderCause
               cause={cause}
                canEdit={canEdit}
                onUploadComplete={onUploadComplete}
                onCauseClick={onCauseClick}
              />
            ))}
            {(!causes || !causes.length) && (
              <WidgetNoResultsPlaceholder text="No causes" icon={PlaceholderIcon} flip={true} />
            )}
          </div>
        </AnimationWrapper>
      )}

      <ModalCauseCreate
        open={showCreateCauseModal}
        onCancelCallback={toggleShowCreateCauseModal}
        onCompleteCallback={toggleShowCreateCauseModal}
        modelId={model.modelId}
      />

      {/* Update */}
      {causeToUpdate && (
        <ModalCauseUpdate
          open={causeToUpdate !== undefined}
          onCancelCallback={hideCauseUpdate}
          onCompleteCallback={hideCauseUpdate}
          cause={causeToUpdate}
          canEdit={canEdit}
        />
      )}
    </DivWrapper>
  );
};

interface IModelBuilderCauseProps {
  cause: ICause;
  canEdit: boolean;
  onUploadComplete(causeId: string): void;
  onCauseClick(cause: ICause): void;
}

function ModelBuilderCause({ cause, onCauseClick, canEdit }: IModelBuilderCauseProps) {
  const [loading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [showAddMappingModal, setShowAddMappingModal] = useState(false);
  const { modelId, causeId } = cause;
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state));

  async function onCauseClickWrapper() {
    setMenuAnchorEl(undefined);
    onCauseClick(cause);
  }

  async function toggleShowAddMappingModal() {
    setShowAddMappingModal(!showAddMappingModal);
    setMenuAnchorEl(undefined);
  }

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          <div className="headerLeft">
            {cause.status === ModelStatusEnum.Draft ? (
              <Typography className="orangeDraft">{cause.name} (Draft)</Typography>
            ) : (
              <Typography className="header">
                {cause.name} - {CauseActionTypeEnum[cause.actionType]}
              </Typography>
            )}
            <IdentifierMappingsRenderer
              type={IdentifierMappingTypeEnum.Cause}
              targetId={String(cause.causeId)}
              modelId={cause.modelId}
              canEdit={canEdit}
              loading={false}
              showValues={true}
              secondaryTargetId={EmptyGuid}
            />
          </div>
          <div className="headerBar" onClick={(e) => e.stopPropagation()}>
            {canEdit ? (
              <>
                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={menuAnchorEl}
                  keepMounted
                  open={Boolean(menuAnchorEl)}
                  onClose={() => setMenuAnchorEl(undefined)}
                >
                  <MenuItem onClick={toggleShowAddMappingModal}>
                    <ListItemIcon>
                      <AddIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Link input field" />
                  </MenuItem>
                  <MenuItem onClick={onCauseClickWrapper}>
                    <ListItemIcon>
                      <EditComponentIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={isAdmin ? "Edit Cause" : "Suggest cause edits"} />
                  </MenuItem>
                </Menu>
              </>
            ) : null}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid2 container className="causeWrapper">
            <Grid2 size={{xs:12, md:6}}>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 16,
                    marginBottom: 4,
                  }}
                >
                  <Typography variant="overline" color="textSecondary">
                    Input fields
                  </Typography>
                  <IconButton onClick={toggleShowAddMappingModal} size="small">
                    <PlusIcon />
                  </IconButton>
                </div>
                <CauseAssetInputFieldMappingsRenderer
                  causeId={cause.causeId}
                  modelId={modelId}
                  canEdit={canEdit}
                  loading={loading}
                />
              </div>
            </Grid2>
            <Grid2 size={{xs:10, md:5}}>
              <div className="causeMainWrapper">
                <div>
                  <Typography variant="overline" color="textSecondary">
                    Logic
                  </Typography>
                  <Typography variant="body2" color="textSecondary" className="causeDescription">
                    {cause.description}
                  </Typography>
                </div>
              </div>
            </Grid2>

            <Grid2 size={{xs:2, md:1}} style={{ textAlign: "right" }}>
              <DiscussionsIcon
                discussionCount={cause.discussionCount}
                entityId={cause.causeId}
                entityType={EntityTypeEnum.Cause}
                canDiscuss={false}
              />
            </Grid2>
            <LoaderAbsoluteCentred loading={loading} />
            <ModalCauseAssetInputFieldMappingCreate
              open={showAddMappingModal}
              onCancelCallback={toggleShowAddMappingModal}
              modelId={modelId}
              causeId={causeId}
              onCompleteCallback={toggleShowAddMappingModal}
            />
          </Grid2>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default CauseModelBuilderTab;
