import React, { useState } from "react";
import { Grid2, Breadcrumbs, Link, styled } from "@mui/material";

import { Container } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";
import SolverDetailsSummaryDisplay from "../SolverDetailsSummary";
import { RootState } from "../../../redux";
import { selectorGetUserPermissionOfType } from "../../../redux/userPermission/selectors";
import { GetUserId } from "../../../utilities/ApiUtils";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import HeaderIcon from "@mui/icons-material/AccountTree";
import { selectorGetSolverById } from "../../../redux/solver/selectors";
import SolverDetailsSolverInputsDisplay from "../SolverDetailsSolverInputs";
import SolverDetailsOverviewDisplay from "../SolverDetailsOverview";
import SolverDetailsTechnicalInfoDisplay from "../SolverDetailsTechnicalInfo";
import SolverDetailsUseCasesDisplay from "../SolverDetailsUseCases";
import SolverDetailsReferencesDisplay from "../SolverDetailsReferences";
import { selectorGetSolverDetailsBySolverId } from "../../../redux/solverDetails/selectors";
import { useFetchSolverDetailsPageHook } from "../Hooks";
import { UserPermissionTypeEnum } from "../../../utilities/types/UserPermission";
import { HomeIconLink } from "../../generic/HomeIconLink";
import SolverDetailsCollapseCard from "../SolverDetailsCollapseCard";

const GridWrapper = styled(Grid2)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const PageSolverDetails = (routeProps: RouteComponentProps<any>) => {
  const {
    match: {
      params: { solverId },
    },
  } = routeProps;
  const [showCollapse, setShowCollapse] = useState<boolean>(false);
  const hasSolverDetailsEditPermissions = useSelector((state: RootState) =>
    selectorGetUserPermissionOfType(state, GetUserId(), UserPermissionTypeEnum.ServicesSolverDetailsUpdate)
  );
  const hasSolverUpdatePermissions = useSelector((state: RootState) =>
    selectorGetUserPermissionOfType(state, GetUserId(), UserPermissionTypeEnum.ServicesSolverUpdate)
  );

  const solver = useSelector((store: RootState) => selectorGetSolverById(store, solverId));
  const solverDetails = useSelector((store: RootState) => selectorGetSolverDetailsBySolverId(store, solverId));
  const canEdit = hasSolverDetailsEditPermissions || (solver && solver.createdBy === GetUserId()) ? true : false;

  useFetchSolverDetailsPageHook({
    pageNumber: 1,
    pageSize: 1,
    solverId,
    minPageNumberToFetch: 1,
  });

  if (!solver) {
    let description = !canEdit ? "View details permission may be required." : "";
    return (
      <Container maxWidth="xl" style={{ marginTop: 16 }}>
        <WidgetNoResultsPlaceholder text="Solver not found" description={description} fade={true} icon={HeaderIcon} />
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <GridWrapper size={{xs:12}}>
        <Breadcrumbs aria-label="breadcrumb">
          <HomeIconLink />
          <Link color="inherit" href="/solvers">
            Solver
          </Link>
          <Link
            color="textPrimary"
            aria-current="page"
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            {solver?.name || "Solver"}
          </Link>
        </Breadcrumbs>
      </GridWrapper>
      <SolverDetailsCollapseCard
        solver={solver}
        solverDetails={solverDetails}
        showCollapse={showCollapse}
        setShowCollapse={setShowCollapse}
        canEdit={canEdit}
      >
        <Grid2 container style={{ padding: 16 }}>
         <Grid2 size={{xs:12, md:6}}>
            <SolverDetailsSummaryDisplay solver={solver} canEdit={hasSolverUpdatePermissions} />
          </Grid2>
         <Grid2 size={{xs:12, md:6}}>
            <SolverDetailsSolverInputsDisplay solver={solver} canEdit={false} />
          </Grid2>
        </Grid2>
      </SolverDetailsCollapseCard>

      <Grid2 container spacing={4} style={{ marginTop: 16 }}>
        <Grid2 size={{xs:12}}>
          <SolverDetailsOverviewDisplay solverDetails={solverDetails} canEdit={canEdit} />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <SolverDetailsTechnicalInfoDisplay solverDetails={solverDetails} canEdit={canEdit} />
        </Grid2>
        <Grid2 size={{xs:12}}>
          <SolverDetailsUseCasesDisplay solverDetails={solverDetails} canEdit={canEdit} />
        </Grid2>
        <Grid2 size={{xs:12}}>
          <SolverDetailsReferencesDisplay solverDetails={solverDetails} canEdit={canEdit} />
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default PageSolverDetails;
