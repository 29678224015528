import React from "react";
import { TextField, Grid2, Divider, Button } from "@mui/material";
import { IModel, ModelStatusEnum } from "../../../utilities/types/Model";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { fetchCreateModel } from "../../../redux/model/actions";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";

interface FormValues {
  title: string;
  description: string;
  versionNumber: number;
}

interface FormProps {
  title?: string;
  description?: string;
  versionNumber?: number;
  status: ModelStatusEnum;
  onCompleteCallback(model?: IModel): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IModel, AnyAction>;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
        <Grid2 size={{xs:12}}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "title", "Name of Asset Type")}
            fullWidth
            margin="normal"
            placeholder="E.g. Pipes"
            variant="standard"
          />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <TextField
            onChange={props.handleChange}
            multiline
            rows={3}
            fullWidth
            margin="normal"
            variant="standard"
            {...getFormikFieldProps(props, "description", "Description of Asset Type")}
            placeholder="E.g. Steel, Cement, and Fibreglass Pipes up to 500mm"
          />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <Divider light={true} />
        </Grid2>
        <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormModelCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    title: "",
    description: "",
    versionNumber: 1,
  }),
  validationSchema: Yup.object().shape({
    title: Yup.string()
      .label("Title")
      .min(3, "Please input 3 characters or more")
      .max(50, "Please input 50 characters or less")
      .required("Please input login name"),
    description: Yup.string().label("Description").max(500, "Please input 500 characters or less"),
    versionNumber: Yup.number().label("Version Number").min(0, "Zero or more.").max(999, "Less than 1000"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch } = props;

    // Map dispatch via props
    var model = await dispatch(
      fetchCreateModel({
        ...values,
      })
    );

    setSubmitting(false);
    onCompleteCallback(model);
  },
})(InnerForm);

export default FormModelCreate;
