import React, { useState } from "react";
import Backgroundimage from "../../../assets/img/mining-site.jpg";
import { Grid2, Paper, Typography, Divider, Button, useTheme, styled } from "@mui/material";
import { Configuration } from "../../../utilities/Constants";
import { Logout, GetUserId } from "../../../utilities/ApiUtils";
import { GetLoginUrl, GetSignUpUrl } from "../../../routes/RouteLinkHelpers";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux";
import { selectorGetUserPermissionsByUserId } from "../../../redux/userPermission/selectors";
import { WidgetNoResultsPlaceholder } from "../widgets/WidgetNoResultsPlaceholder";
import LoadingIcon from "@mui/icons-material/SettingsTwoTone";
import AnimationWrapper from "../animations/AnimationWrapper";
import { selectorGetTermsAgreementsByUserId } from "../../../redux/termsAgreement/selectors";
import { useFetchTermsAgreementsPageHook } from "../../termsAgreement/Hooks";
import { ITermsAgreement } from "../../../utilities/types/TermsAgreement";
import { IUserPermission } from "../../../utilities/types/UserPermission";
import { fetchCreateTermsAgreement } from "../../../redux/termsAgreement/actions";
import LoaderAbsoluteCentred from "../loaders/LoaderAbsoluteCentred";
import TermsAndConditions from "../../termsAgreement/TermsAndConditions";
import { selectorGetSystemState } from "../../../redux/systemState/actions";
import { Box } from "@mui/system";

const DivWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  backgroundImage: `url(${Backgroundimage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1000001,
  "& .backgroundImageOverlay": {
    display: "flex",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000001,
    backgroundColor: "rgba(57,71,77,0.6)",
  },
  "& .logo": {
    height: 50,
  },
  "& .termsWrapper": {
    maxHeight: 400,
    overflowY: "auto",
    whiteSpace: "pre-wrap",
    maxWidth: 400,
    overflowX: "hidden",
    padding: 16,
    position: "relative",
    textAlign: "left",
    border: "1px solid #eee",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(0,0,0,0.02)",
  },
}));

function PageNoPermissions() {
  const userPermissions = useSelector((store: RootState) => selectorGetUserPermissionsByUserId(store, GetUserId()));
  const termsAgreements = useSelector((store: RootState) => selectorGetTermsAgreementsByUserId(store, GetUserId()));
  const userId = useSelector((store: RootState) => selectorGetSystemState(store))?.userId || "";

  // Retrieve user terms agreement
  const { fetching: fetchingTermsAgreement } = useFetchTermsAgreementsPageHook({
    pageNumber: 1,
    pageSize: 1,
    minPageNumberToFetch: 1,
    userId,
  });
  if (userId && userPermissions.length && termsAgreements.length) {
    return null;
  }

  return (
    <AnimationWrapper>
      <DivWrapper>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} className="backgroundImageOverlay">
          <Grid2 container alignContent="center" alignItems="center" justifyContent="center" direction="column">
           <Grid2 alignItems={'center'} justifyContent={'center'} size={{xs:12, md:6,lg:12,}} style={{ textAlign: "center" }}>
              <Paper style={{ padding: 32 }}>
                <img src="/logo_dark.png" alt="Modla" className="logo" />
                <Divider color="light" style={{ marginBottom: 16 }} />
                <Content
                  loading={fetchingTermsAgreement}
                  userId={userId}
                  termsAgreements={termsAgreements}
                  userPermissions={userPermissions}
                />
              </Paper>
            </Grid2>
          </Grid2>
        </Box>
      </DivWrapper>
    </AnimationWrapper>
  );
}

interface IContentProps {
  loading: boolean;
  userId: string;
  termsAgreements: ITermsAgreement[];
  userPermissions: IUserPermission[];
}

function Content({ loading, userId, termsAgreements, userPermissions }: IContentProps) {
  if (loading) return <LoadingPlaceholder />;
  if (userId) {
    if (!termsAgreements || termsAgreements.length === 0) {
      return <TermsAgreement />;
    }

    if (!userPermissions || userPermissions.length === 0) {
      return <AwaitingApproval />;
    }
  }

  return <NotLoggedIn />;
}

function TermsAgreement() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [creating, setCreating] = useState(false);
  const submitTermsAgreement = async () => {
    setCreating(true);
    await dispatch(fetchCreateTermsAgreement());
    setCreating(false);
  };

  return (
    <div>
      <p>TERMS AND CONDITIONS</p>
      <Divider color="light" style={{ marginBottom: theme.spacing(2) }} />
      <div className="termsWrapper">
        <TermsAndConditions />

        <div style={{ marginTop: theme.spacing(2), position: "relative", textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={submitTermsAgreement}
            style={{ position: "relative" }}
            {...(creating ? { disabled: true } : null)}
          >
            <LoaderAbsoluteCentred loading={creating} />I Agree
          </Button>

          <a
            href={Configuration.COGNITO_ENDPOINT_LOGOUT}
            onClick={Logout}
            target="_self"
            style={{ marginLeft: "16px", textDecoration: "none", color: "inherit" }}
          >
            <Button variant="outlined" size="small" style={{ position: "relative" }}>
              Sign Out
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

function LoadingPlaceholder() {
  return (
    <WidgetNoResultsPlaceholder
      spin={true}
      icon={LoadingIcon}
      text="Loading"
      description="Preparing your environment."
    />
  );
}

function NotLoggedIn() {
  return (
    <div style={{ maxWidth: 250 }}>
      <Typography variant="caption" display="inline">
        Please login or signup to browse{" "}
      </Typography>
      <Typography variant="caption" display="inline" component="h1">
        {" "}
        Assetwiki{" "}
      </Typography>{" "}
      <Typography variant="caption" display="inline">
        content.
      </Typography>
      <a href={GetLoginUrl()} style={{ textDecoration: "none", color: "inherit" }}>
        <Button style={{ marginTop: 16 }} variant="contained" color="primary" fullWidth component="a">
          Login
        </Button>
      </a>
      <a href={GetSignUpUrl()} style={{ textDecoration: "none", color: "inherit" }}>
        <Button style={{ marginTop: 8 }} variant="outlined" fullWidth component="a">
          Sign Up
        </Button>
      </a>
      <a href={"https://modla.co"} style={{ textDecoration: "none", color: "inherit" }}>
        <Typography variant="caption">Hosted by Modla</Typography>
      </a>
    </div>
  );
}

function AwaitingApproval() {
  return (
    <div style={{ maxWidth: 200 }}>
      <Typography variant="caption">Your membership request has been received and is awaiting approval.</Typography>
      <a
        href={Configuration.COGNITO_ENDPOINT_LOGOUT}
        onClick={Logout}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Button style={{ marginTop: 16 }} variant="contained" color="primary" fullWidth component="a">
          Logout
        </Button>
      </a>
    </div>
  );
}

export default PageNoPermissions;
