import React from "react";
import { TextField, Grid2, Divider, Button } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { IAssetInputFieldCategory } from "../../../utilities/types/AssetInputFieldCategory";
import { fetchCreateAssetInputFieldCategory } from "../../../redux/assetInputFieldCategory/actions";

interface FormValues {
  name: string;
  description: string;
  orderNumber: number;
  modelId: string;
}

interface FormProps {
  name?: string;
  description?: string;
  orderNumber?: number;
  modelId: string;
  onCompleteCallback(assetInputFieldCategory?: IAssetInputFieldCategory): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IAssetInputFieldCategory, AnyAction>;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
      <Grid2 size={{xs:12, sm:6}}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "name", "Name")}
            fullWidth
            margin="normal"
            variant="standard"
          />
        </Grid2>

      <Grid2 size={{xs:12, sm:6}}>
          <TextField
            onChange={props.handleChange}
            type="number"
            fullWidth
            margin="normal"
            variant="standard"
            {...getFormikFieldProps(props, "orderNumber", "Order Number")}
          />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <TextField
            onChange={props.handleChange}
            multiline
            rows={3}
            fullWidth
            margin="normal"
            variant="standard"
            {...getFormikFieldProps(props, "description", "Description")}
          />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <Divider light={true} />
        </Grid2>
        <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormAssetInputFieldCategoryCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    name: "",
    description: "",
    orderNumber: 1,
    modelId: "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .label("Title")
      .min(3, "Please input 3 characters or more")
      .max(50, "Please input 50 characters or less")
      .required("Please provide a name"),
    description: Yup.string().label("Description").max(500, "Please input 500 characters or less"),
    versionNumber: Yup.number().label("Order Number").min(0, "Zero or more.").max(999, "Less than 1000"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId } = props;

    // Map dispatch via props
    var model = await dispatch(
      fetchCreateAssetInputFieldCategory({
        ...values,
        modelId,
      })
    );

    setSubmitting(false);
    onCompleteCallback(model);
  },
})(InnerForm);

export default FormAssetInputFieldCategoryCreate;
