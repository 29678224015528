import { CardActions, Grid2, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../redux";
import { selectorGetModelsByUpdatedDesc } from "../../redux/model/selectors";
import { GetModelLinkByModel } from "../../routes/RouteLinkHelpers";
import { useIsLoggedIn } from "../../utilities/ApiUtils";
import { join } from "../../utilities/Styles";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { FileTypeEnum } from "../../utilities/types/File";
import { IModel } from "../../utilities/types/Model";
import FavouriteButton from "../favourite/FavouriteButton";
import FileRendererSingle from "../file/renderers/FileRendererSingle";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";

const IMAGE_HEIGHT = 125;

const DivWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: IMAGE_HEIGHT * 1.5,
  border: "1px solid #EEE",
  backgroundColor: "#FFF",
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0 0 4px rgba(0,0,255,0.0)",
  transition: "box-shadow 0.4s ease-in-out",
  cursor: "pointer",
  overflow: "hidden",
  "&:hover": {
    boxShadow: "0 0 4px rgba(0,0,255,0.5)",
  },
  "& .imageWrapper": {
    height: IMAGE_HEIGHT,
    "& img": {
      borderBottom: "1px solid rgba(0,0,0,0.05)",
    },
  },
  "& .actionsWrapper": {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 2,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    fontSize: 0.875,
  },
  "& .centerActions": {
    padding: theme.spacing(1),
    justifyContent: "center",
    textAlign: "center",
  },
}));

interface IModelsRecentWidgetProps {
  loading: boolean;
}

const ModelsRecentWidget = ({ loading }: IModelsRecentWidgetProps) => {
  const recentModels = useSelector((store: RootState) => selectorGetModelsByUpdatedDesc(store));

  if (loading) {
    return <LoaderAbsoluteCentred loading={loading} />;
  }

  return (
    <Grid2 container spacing={3}>
      {[...Array(6)].map((e, i) => {
        return (
          <Grid2 size={{xs:12,sm:6,md:2}} key={i}>
            <ResultListing model={recentModels.length - 1 >= i ? recentModels[i] : undefined} loading={loading} />
          </Grid2>
        );
      })}
    </Grid2>
  );
};

const ResultListing = ({ model }: { model: IModel | undefined; loading: boolean }) => {
  const isLoggedIn = useIsLoggedIn();
  const [viewDetails, setViewDetails] = useState(false);

  const toggleViewDetails = () => {
    setViewDetails(!viewDetails);
  };

  if (!model) return null;

  return (
    <DivWrapper onClick={toggleViewDetails}>
      <div className="imageWrapper">
        <Link to={GetModelLinkByModel(model)}>
          <FileRendererSingle
            fileId={model.mainImageId || ""}
            canUpload={false}
            fileType={FileTypeEnum.Image}
            entityId={String(model.modelId)}
            entityType={EntityTypeEnum.Model}
            height={IMAGE_HEIGHT}
          />
        </Link>
      </div>
      <CardActions disableSpacing className={join("actionsWrapper", !isLoggedIn && "centerActions")}>
        {isLoggedIn ? (
          <>
            <FavouriteButton entityType={EntityTypeEnum.Model} entityId={model?.modelId ?? ""} />
            <Typography variant="body2">{model.title}</Typography>
          </>
        ) : (
          <Typography variant="body2">{model.title}</Typography>
        )}
      </CardActions>
    </DivWrapper>
  );
};

export default ModelsRecentWidget;
