import React, { useState } from "react";
import { Typography, Grid2, styled } from "@mui/material";

import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetModelById } from "../../redux/model/selectors";
import { selectorGetUserPermissionIsAdmin } from "../../redux/userPermission/selectors";
import { GetUserId } from "../../utilities/ApiUtils";
import { ITaskApplicability, StepLabourEnum } from "../../utilities/types/TaskApplicability";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import ModalTaskApplicabilityCreate from "./modals/ModalTaskApplicabilityCreate";
import ModalTaskApplicabilityUpdate from "./modals/ModalTaskApplicabilityUpdate";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import HeaderColor from "@mui/material/colors/blueGrey";
import HeaderIcon from "@mui/icons-material/ArtTrackTwoTone";
import { useFetchTaskApplicabilitiesPageHook } from "./Hooks";
import { selectorGetFailureModeById } from "../../redux/failureMode/selectors";
import { selectorGetTaskApplicabilitiesByTaskId } from "../../redux/taskApplicability/selectors";
import IdentifierMappingsRenderer from "../identifierMapping/IdentifierMappingsRenderer";
import { IdentifierMappingTypeEnum } from "../../utilities/types/IdentifierMapping";
import { useFetchIdentifierMappingsPageHook } from "../identifierMapping/Hooks";
import { useFetchIdentifiersPageHook } from "../identifier/Hooks";
import { red } from "@mui/material/colors";
import NewTaskApplicability from "./NewTaskApplicability";
import { selectorGetEventById } from "../../redux/event/selectors";
import { useFetchFailureModesPageHook } from "../failureMode/Hooks";
import { useFetchEventsPageHook } from "../event/Hooks";

const DivWrapper = styled("div")(({ theme }) => ({
  transition: "background-color 0.3s ease-out",
  backgroundColor: "rgba(0,0,0,0.001)",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "rgba(0,0,0,0.03)",
  },
  "&:active": {
    cursor: "pointer",
    backgroundColor: "rgba(255,255,255,0.3)",
  },
  border: "1px solid #EEE",
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,

  "& .redText": {
    color: red[500],
  },
}));

type ITaskApplicabilitiesSectionProps = {
  modelId: string;
  taskId: string;
};

const TaskApplicabilitiesSection = ({ modelId, taskId }: ITaskApplicabilitiesSectionProps) => {
  const model = useSelector((state: RootState) => selectorGetModelById(state, modelId));
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state));
  const canEdit = isAdmin || (model != null && model.createdBy === GetUserId());
  const taskApplicabilities = useSelector((store: RootState) => selectorGetTaskApplicabilitiesByTaskId(store, taskId));

  const { fetching: fetchingTasks } = useFetchTaskApplicabilitiesPageHook({
    pageNumber: 1,
    pageSize: 1000,
    modelId: modelId || "",
    taskId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingIdentifierMappings } = useFetchIdentifierMappingsPageHook({
    pageNumber: 1,
    pageSize: 5000,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingIdentifiers } = useFetchIdentifiersPageHook({
    pageNumber: 1,
    pageSize: 200,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingFailureModes } = useFetchFailureModesPageHook({
    pageNumber: 1,
    pageSize: 200,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingEvents } = useFetchEventsPageHook({
    pageNumber: 1,
    pageSize: 100,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  return (
    <TaskApplicabilitySectionDisplay
      taskApplicabilities={taskApplicabilities}
      canEdit={canEdit}
      taskId={taskId}
      modelId={modelId}
      loading={
        fetchingTasks || fetchingIdentifierMappings || fetchingIdentifiers || fetchingEvents || fetchingFailureModes
      }
    />
  );
};

const TaskApplicabilitySectionDisplay = ({
  taskApplicabilities,
  canEdit,
  taskId,
  modelId,
  loading,
}: {
  taskApplicabilities: ITaskApplicability[];
  canEdit: boolean;
  taskId: string;
  modelId: string;
  loading: boolean;
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  function toggleCreateModal() {
    setShowCreateModal(!showCreateModal);
  }

  return (
    <WidgetSectionBase
      headerText="Manage Applicabilities"
      subheaderText="Create and maintain task applicabilities"
      headerIcon={<HeaderIcon />}
      headerColor={HeaderColor[800]}
      fullWidthHeader={true}
    >
      <LoaderAbsoluteCentred loading={loading} />

      <NewTaskApplicability taskId={taskId} modelId={modelId} />
      {taskApplicabilities && taskApplicabilities.length ? (
        <Grid2 container>
          {taskApplicabilities.map((x) => (
            <Grid2 size={{xs:12}}>
              <TaskApplicabilityDisplay taskApplicability={x} canEdit={true} />
            </Grid2>
          ))}
        </Grid2>
      ) : (
        <WidgetNoResultsPlaceholder flip={true} icon={HeaderIcon} text="No applicabilities" />
      )}
      <ModalTaskApplicabilityCreate
        open={showCreateModal}
        onCancelCallback={toggleCreateModal}
        onCompleteCallback={toggleCreateModal}
        taskId={taskId}
        modelId={modelId}
      />
    </WidgetSectionBase>
  );
};

const TaskApplicabilityDisplay = ({
  taskApplicability,
  canEdit,
}: {
  taskApplicability: ITaskApplicability;
  canEdit: boolean;
}) => {
  const failureMode = useSelector((store: RootState) =>
    selectorGetFailureModeById(store, taskApplicability.failureModeId)
  );
  const subsequentEvent = useSelector((store: RootState) =>
    selectorGetEventById(store, taskApplicability.subsequentEventId ?? "")
  );

  const [showEditModal, setShowEditModal] = useState(false);

  function toggleEditModal() {
    setShowEditModal(!showEditModal);
  }

  return (
    <>
      <DivWrapper>
        <div onClick={toggleEditModal}>
          <Typography variant="body1">{taskApplicability.stepDescription}</Typography>
          <Typography variant="caption" component="span" color="textPrimary">
            Failure Mode:{" "}
          </Typography>
          <Typography variant="caption" component="span" color="textSecondary">
            {failureMode ? failureMode.name : ""}
          </Typography>
          <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
            Detection Probability:{" "}
          </Typography>
          <Typography variant="caption" component="span" color="textSecondary">
            {taskApplicability.detectionProbability}
          </Typography>
          <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
            PF Interval:{" "}
          </Typography>
          <Typography variant="caption" component="span" color="textSecondary">
            {taskApplicability.pfInterval}
          </Typography>
          <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
            Subsequent Event:{" "}
          </Typography>
          {subsequentEvent ? (
            <Typography variant="caption" component="span" color="textSecondary">
              {subsequentEvent?.description}
            </Typography>
          ) : (
            <Typography variant="caption" component="span" className="redText">
              Empty
            </Typography>
          )}
          <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
            Labour:{" "}
          </Typography>
          <Typography variant="caption" component="span" color="textSecondary">
            {StepLabourEnum[taskApplicability.stepLabour]}
          </Typography>
          <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
            Labour QTY:{" "}
          </Typography>
          <Typography variant="caption" component="span" color="textSecondary">
            {taskApplicability.labourQuantity}
          </Typography>
          <Typography variant="caption" component="span" color="textPrimary" style={{ marginLeft: 16 }}>
            Duration:{" "}
          </Typography>
          <Typography variant="caption" component="span" color="textSecondary">
            {taskApplicability.duration}
          </Typography>
        </div>

        <IdentifierMappingsRenderer
          canEdit={canEdit}
          modelId={taskApplicability.modelId}
          type={IdentifierMappingTypeEnum.TaskApplicability}
          targetId={String(taskApplicability.taskApplicabilityId)}
          loading={false}
          showValues={false}
        />
      </DivWrapper>

      <ModalTaskApplicabilityUpdate
        open={showEditModal}
        onCancelCallback={toggleEditModal}
        onCompleteCallback={toggleEditModal}
        taskApplicability={taskApplicability}
        canEdit={canEdit}
      />
    </>
  );
};

export default TaskApplicabilitiesSection;
