import LinkIcon from "@mui/icons-material/Link";
import React, { useState } from "react";
import { TextField, Grid2, Divider, Button, Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import { ModelStatusEnum, IModel, Maturity } from "../../../utilities/types/Model";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { fetchDeleteModel, fetchUpdateModel } from "../../../redux/model/actions";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import { useDispatch, useSelector } from "react-redux";
import { selectorGetUserPermissionIsAdmin } from "../../../redux/userPermission/selectors";
import { GetUserId } from "../../../utilities/ApiUtils";
import { MaturityLabel } from "../../../utilities/labels";
import { GetModelDefinitionsLink } from "../../../routes/RouteLinkHelpers";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";

interface FormValues {
  title: string;
  description?: string;
  versionNumber: number;
  modelId: string;
  status: ModelStatusEnum;
}

interface FormProps {
  modelId: string;
  title: string;
  description?: string;
  versionNumber: number;
  embedCode: string;
  status: ModelStatusEnum;
  maturity: Maturity;
  onCompleteCallback(model?: IModel): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IModel, AnyAction>;
}

const FormModelUpdate = (formProps: FormProps) => {
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);
  const { onCancelCallback, modelId } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state, GetUserId()));

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var model = await dispatch(
      fetchUpdateModel({
        ...values,
      })
    );

    UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
    setSubmitting(false);
    onCompleteCallback(model);
  };

  return (
    <Formik
      initialValues={{
        title: formProps.title,
        description: formProps.description,
        versionNumber: formProps.versionNumber,
        modelId: formProps.modelId,
        status: formProps.status,
        maturity: formProps.maturity,
        embedCode: formProps.embedCode,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteModel({ modelId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }
        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={formProps.modelId} name="modelId" />
            <Grid2 container spacing={2}>
            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "title", "Title")}
                  fullWidth
                  variant="standard"
                  margin="normal"
                />
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "versionNumber", "Version Number")}
                />
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <FormControl fullWidth={true}>
                  <InputLabel id="lbl-model-type" variant="standard">
                    Type
                  </InputLabel>
                  <Select
                    labelId="lbl-model-type"
                    variant="standard"
                    {...getFormikFieldProps(props, "status", "Status")}
                    onChange={(e) => {
                      props.setFieldValue("status", e.target.value);
                    }}
                    style={{ display: "block" }}
                    id="txt-model-type"
                    disabled={!isAdmin}
                  >
                    <MenuItem value={ModelStatusEnum.Private}>Private</MenuItem>
                    <MenuItem value={ModelStatusEnum.Public}>Public</MenuItem>
                    <MenuItem value={ModelStatusEnum.Draft}>Draft</MenuItem>
                    <MenuItem value={ModelStatusEnum.PendingEdits}>Pending Edits</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <FormControl fullWidth={true}>
                  <InputLabel id="lbl-maturity" variant="standard">
                    Maturity
                  </InputLabel>
                  <Select
                    labelId="lbl-maturity"
                    variant="standard"
                    {...getFormikFieldProps(props, "maturity", "Maturity")}
                    onChange={(e) => {
                      props.setFieldValue("maturity", e.target.value);
                    }}
                    style={{ display: "block" }}
                    id="txt-maturity"
                    disabled={!isAdmin}
                  >
                    <MenuItem value={Maturity.UnderConstruction}>{MaturityLabel.UnderConstruction}</MenuItem>
                    <MenuItem value={Maturity.Low}>{MaturityLabel.Low}</MenuItem>
                    <MenuItem value={Maturity.Medium}>{MaturityLabel.Medium}</MenuItem>
                    <MenuItem value={Maturity.High}>{MaturityLabel.High}</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>

              <Grid2 size={{xs:12}}>
                <TextField
                  onChange={props.handleChange}
                  multiline
                  rows={3}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "description", "Description")}
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <TextField
                  onChange={props.handleChange}
                  multiline
                  rows={3}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "embedCode", "Embed Code")}
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <Divider light={true} />
              </Grid2>
              <Grid2 size={{xs:3}}>
                <Button
                  disabled={submitting}
                  variant="text"
                  onClick={() => window.open(GetModelDefinitionsLink(formProps.modelId), "_blank")}
                >
                  <LinkIcon />
                </Button>
              </Grid2>
              <Grid2 size={{xs:9}} style={{ textAlign: "right" }}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ flexBasis: "33%" }}
                    onClick={toggleDeleteConfirmation}
                  >
                    Delete
                  </Button>
                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button disabled={submitting} variant="text" onClick={onCancelCallback}>
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button type="submit" disabled={submitting} variant="outlined" color="primary">
                      Update
                    </Button>
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting || deleting} />
                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete model"
                  subtitle="Confirm model delete"
                  description="Are you sure that you'd like to remove this model?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteHandler}
                  confirmButtonText="Delete"
                />
              </Grid2>
            </Grid2>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          title: Yup.string()
            .label("Title")
            .min(3, "Please input 3 characters or more")
            .max(50, "Please input 50 characters or less")
            .required("Please input login name"),
          description: Yup.string().label("Description").max(500, "Please input 500 characters or less"),
          versionNumber: Yup.number().label("Version Number").min(0, "Zero or more.").max(999, "Less than 1000"),
        });
      }}
    />
  );
};

export default FormModelUpdate;
