import { Button, Grid2 } from "@mui/material";
import { FormikProps, withFormik } from "formik";
import React from "react";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";

import { RootState } from "../../../redux";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { fetchCreateTaskImpact } from "../../../redux/taskImpact/actions";
import { ITaskImpact } from "../../../utilities/types/TaskImpact";
import { LinkModeTypeEnum, LinkToModesTable } from "../../component/linkToModesTable";

interface FormValues {
  failureModeIds: string[];
  ageReductionFactor: number;
  orderNumber: number;
  stepDescription: string;
  stepLabour: number;
  acceptableLimit: string;
  duration: number;
  labourQuantity: number;
}

interface FormProps {
  taskId: string;
  modelId: string;
  onCompleteCallback(taskImpactMapping?: ITaskImpact[]): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ITaskImpact[], AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  const { setFieldValue, modelId } = props;

  const error = props.errors["failureModeIds"] ?? "";

  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
        <Grid2 size={{xs:12}}>
          <LinkToModesTable
            modelId={modelId}
            fieldName="failureModeIds"
            setFieldValue={setFieldValue}
            error={error as string}
            targetIds={props.values.failureModeIds}
            showSelection={false}
            showCheckboxCondition={(rowData) => rowData.showCheckbox}
            specificType={LinkModeTypeEnum.FailureMode}
          />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <div style={{ flex: "1" }} />
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Add
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormImpactMappingCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    modelId: "",
    failureModeIds: [],
    taskId: "",
    ageReductionFactor: 1,
    pfInterval: 0,
    orderNumber: 0,
    stepDescription: "",
    stepLabour: 0,
    labourQuantity: 1,
    duration: 0,
    acceptableLimit: "",
  }),
  validationSchema: Yup.object().shape({
    failureModeIds: Yup.string().label("Failure Modes").required("Please provide at least one failure mode"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, taskId } = props;

    // Map dispatch via props
    var createdTaskImpactMapping = await dispatch(
      fetchCreateTaskImpact({
        ...values,
        taskId,
      })
    );

    setSubmitting(false);

    if (createdTaskImpactMapping) onCompleteCallback(createdTaskImpactMapping);
  },
})(InnerForm);

export default FormImpactMappingCreate;
