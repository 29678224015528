import {
  AppBar,
  Breadcrumbs,
  Button,
  Container as MuiContainer,
  Grid2,
  Link,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";

import CopyIcon from "@mui/icons-material/Code";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { RootState } from "../../../redux";
import { selectorGetAssetInputFieldsByModelId } from "../../../redux/assetInputField/selectors";
import { selectorGetAssetInputFieldCategoriesByModelId } from "../../../redux/assetInputFieldCategory/selectors";
import { selectorGetAssetInputFieldListValuesByModelId } from "../../../redux/assetInputFieldListValue/selectors";
import { selectorGetCostBundlesByModelId } from "../../../redux/costBundle/selectors";
import { selectorGetIdentifiersByModelId } from "../../../redux/identifier/selectors";
import { selectorGetModelById } from "../../../redux/model/selectors";
import { selectorGetPlansByModelId } from "../../../redux/plan/selectors";
import { GetModelLinkByModelId } from "../../../routes/RouteLinkHelpers";
import { DeHumanizeString } from "../../../utilities/Helpers";
import { ScrollPretty } from "../../../utilities/Styles";
import { AssetInputFieldTypeEnum, IAssetInputField } from "../../../utilities/types/AssetInputField";
import { IAssetInputFieldCategory } from "../../../utilities/types/AssetInputFieldCategory";
import { IAssetInputFieldListValue } from "../../../utilities/types/AssetInputFieldListValue";
import { ICostBundle } from "../../../utilities/types/CostBundle";
import { IIdentifier } from "../../../utilities/types/Identifier";
import { IModel } from "../../../utilities/types/Model";
import { IPlan } from "../../../utilities/types/Plan";
import { useFetchAssetInputFieldsPageHook } from "../../assetInputField/Hooks";
import { useFetchAssetInputFieldCategoriesPageHook } from "../../assetInputFieldCategory/Hooks";
import { useFetchAssetInputFieldListValuesPageHook } from "../../assetInputFieldListValue/Hooks";
import { useFetchCostBundlesPageHook } from "../../costBundle/Hooks";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import { useFetchIdentifiersPageHook } from "../../identifier/Hooks";
import { useFetchPlansPageHook } from "../../plan/Hooks";
import { HomeIconLink } from "../../generic/HomeIconLink";

const Container = styled(MuiContainer)(({ theme }) => ({
  ...ScrollPretty,
  "& .codeGroups": {
    width: "100%",
  },
  "& .codeGroup": {
    width: "100%",
  },
  "& .codeGroupTitlebar": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "6px",
    marginBottom: "6px",
    alignItems: "center",
  },
  "& .codeBlock": {
    width: "100%",
    overflow: "auto",
    border: "none",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(0,0,0,0.8)",
    color: "#FFF",
    fontFamily:
      "Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif",
    padding: theme.spacing(1),
    marginTop: 0,
  },
  "& .breadcrumbWrapper": {
    marginTop: `${theme.spacing(2)} !important`,
  },
  "& .appBar": {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    border: "1px solid #EEE",
    borderRadius: theme.shape.borderRadius,
  },
}));

function ModalModelDefinitions(routeProps: RouteComponentProps<any>) {
  const {
    match: {
      params: { modelName, modelId },
    },
  } = routeProps;

  const fetching = useFetchData(modelId);
  const { model, modelFields, modelFieldValues, identifiers, modelFieldCategories, plans, costBundles } =
    useGetData(modelId);

  const [value, setValue] = React.useState(0);
  const handleChange = (event: any, newValue: React.SetStateAction<number>) => setValue(newValue);

  return (
    <Container maxWidth="xl">
      <Grid2 size={{xs:12}} className="breadcrumbWrapper">
        <Breadcrumbs aria-label="breadcrumb">
          <HomeIconLink />
          <Link color="inherit" href="/search">
            Search
          </Link>
          <Link color="inherit" href={GetModelLinkByModelId(modelId, modelName)}>
            {model?.title || "Model"}
          </Link>
          <Link
            color="textPrimary"
            aria-current="page"
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            Definitions
          </Link>
        </Breadcrumbs>
      </Grid2>
      <Grid2 container spacing={5} style={{ marginTop: 16 }}>
        {!model ? (
          <WidgetNoResultsPlaceholder text="No model" />
        ) : (
          <>
            <AppBar position="static" className="appBar">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Model" {...a11yProps(0)} />
                <Tab label="Fields" {...a11yProps(1)} />
                <Tab label="Field Values" {...a11yProps(2)} />
                <Tab label="Identifiers" {...a11yProps(3)} />
                <Tab label="Cost Bundles" {...a11yProps(4)} />
                <Tab label="Plans" {...a11yProps(6)} />
              </Tabs>
            </AppBar>

            <TabPanel
              value={value}
              index={0}
              code={{
                title: `${model.title}.cs`,
                code: ClassModelDefinitions({ model }),
              }}
            />

            <TabPanel
              value={value}
              index={1}
              code={{
                title: "Field Definitions",
                code: ClassFieldDefinitions({ model, modelFields, modelFieldCategories }),
              }}
            />

            <TabPanel
              value={value}
              index={2}
              code={{
                title: "Field Value Definitions",
                code: ClassFieldListValueDefinitions({ model, modelFields, modelFieldValues }),
              }}
            />

            <TabPanel
              value={value}
              index={3}
              code={{
                title: "Identifier Definitions",
                code: IdentifierDefinitions({ model, identifiers }),
              }}
            />

            <TabPanel
              value={value}
              index={4}
              code={[
                {
                  title: "Cost Bundle Definitions",
                  code: CostBundleDefinitions({ model, costBundles }),
                },
                {
                  title: "Cost Bundle Overrides",
                  code: CostBundleOverrides({ model, costBundles }),
                },
              ]}
            />

            <TabPanel
              value={value}
              index={5}
              code={[
                {
                  title: "Plan Definitions",
                  code: PlanDefinitions({ model, plans }),
                },
                {
                  title: "Plan Overrides",
                  code: PlanOverrides({ model, plans }),
                },
              ]}
            />
          </>
        )}
      </Grid2>
      <LoaderAbsoluteCentred loading={fetching} />
    </Container>
  );
}

function useGetData(modelId: string) {
  const model = useSelector((store: RootState) => selectorGetModelById(store, modelId));
  const modelFields = useSelector((store: RootState) => selectorGetAssetInputFieldsByModelId(store, modelId));
  const modelFieldValues = useSelector((store: RootState) =>
    selectorGetAssetInputFieldListValuesByModelId(store, modelId)
  );
  const identifiers = useSelector((store: RootState) => selectorGetIdentifiersByModelId(store, modelId));
  const modelFieldCategories = useSelector((store: RootState) =>
    selectorGetAssetInputFieldCategoriesByModelId(store, modelId)
  );
  const plans = useSelector((store: RootState) => selectorGetPlansByModelId(store, modelId));
  const costBundles = useSelector((store: RootState) => selectorGetCostBundlesByModelId(store, modelId));
  return { model, modelFields, modelFieldValues, identifiers, modelFieldCategories, plans, costBundles };
}

function useFetchData(modelId: string): boolean {
  const { fetching: fetchingAssetInputFields } = useFetchAssetInputFieldsPageHook({
    pageNumber: 1,
    pageSize: 500,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingAssetInputFieldListValues } = useFetchAssetInputFieldListValuesPageHook({
    pageNumber: 1,
    pageSize: 500,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingIdentifiers } = useFetchIdentifiersPageHook({
    pageNumber: 1,
    pageSize: 200,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingAssetInputFieldCategories } = useFetchAssetInputFieldCategoriesPageHook({
    pageNumber: 1,
    pageSize: 20,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingPlans } = useFetchPlansPageHook({
    pageNumber: 1,
    pageSize: 100,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingCostBundles } = useFetchCostBundlesPageHook({
    pageNumber: 1,
    pageSize: 500,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  return (
    fetchingAssetInputFields ||
    fetchingAssetInputFieldListValues ||
    fetchingIdentifiers ||
    fetchingAssetInputFieldCategories ||
    fetchingPlans ||
    fetchingCostBundles
  );
}

interface ITabCodeGroup {
  title: string;
  code: string;
}

interface ITabPanelProps {
  value: number;
  index: number;
  code: ITabCodeGroup | ITabCodeGroup[];
}

function a11yProps(index: number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function TabPanel({ value, index, code }: ITabPanelProps) {
  const codeGroups: ITabCodeGroup[] = Array.isArray(code) ? code : [code]; // Handle single vs multiple groups
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      className="codeGroups"
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {codeGroups.map((group) => (
        <TabPanelCodeGroup {...group} />
      ))}
    </div>
  );
}

enum Copied {
  Na,
  Copied,
  Failed,
}

function TabPanelCodeGroup(group: ITabCodeGroup) {
  const ref = useRef<HTMLDivElement>();
  const [copied, setCopied] = useState<Copied>(Copied.Na);
  const copy = () => {
    const copied = copyToClipboard(ref.current as any, group.code);
    setCopied(copied ? Copied.Copied : Copied.Failed);
    setTimeout(() => setCopied(Copied.Na), 1000);
  };
  return (
    <div className="codeGroup">
      <div ref={ref as any} className="codeGroupTitlebar">
        <Typography variant="overline">{group.title}</Typography>
        <Button onClick={copy} variant="contained" color="primary" size="small" startIcon={<CopyIcon />}>
          {copied === Copied.Failed ? "Failed" : copied === Copied.Copied ? "Copied" : "Copy"}
        </Button>
      </div>
      <pre className="codeBlock">{group.code}</pre>
    </div>
  );
}

function copyToClipboard(container: HTMLDivElement, message: string): boolean {
  var textArea = document.createElement("textarea");
  textArea.value = message;
  textArea.style.opacity = "0";
  container.prepend(textArea);
  textArea.focus();
  textArea.select();

  try {
    return document.execCommand("copy");
  } catch (err) {
    return false;
  } finally {
    container.removeChild(textArea);
  }
}

interface PlanProps {
  model: IModel;
  plans: IPlan[];
}

function PlanDefinitions({ model, plans }: PlanProps) {
  var planDefinition = `public static class ${DeHumanizeString(model.title)}Plans\r\n`;
  planDefinition += `{\r\n`;

  // Add each identifier to the definition
  for (var plan of plans) {
    planDefinition += `\tpublic static readonly Guid ${DeHumanizeString(plan.code)} = new Guid("${plan.planId}");\r\n`;
  }

  planDefinition += `}\r\n`;

  return planDefinition;
}

function PlanOverrides({ model, plans }: PlanProps) {
  const modelName = DeHumanizeString(model.title);
  return plans
    .map((plan) => DeHumanizeString(plan.code))
    .map(
      (name) =>
        `specificAsset.Plans.Where(x => x.PlanId == ${modelName}Plans.${name}).FirstOrDefault().EventInterval = (int)inputFieldValueMap[Guid.Parse(${modelName}Fields.${name})].Value;`
    )
    .join("\r\n");
}

interface CostBundleProps {
  model: IModel;
  costBundles: ICostBundle[];
}

function CostBundleDefinitions({ model, costBundles }: CostBundleProps) {
  var costBundleDefinition = `public static class ${DeHumanizeString(model.title)}CostBundles\r\n`;
  costBundleDefinition += `{\r\n`;

  // Add each identifier to the definition
  for (var costBundle of costBundles) {
    costBundleDefinition += `\tpublic static readonly Guid ${DeHumanizeString(costBundle.code)} = new Guid("${
      costBundle.costBundleId
    }");\r\n`;
  }

  costBundleDefinition += `}\r\n`;

  return costBundleDefinition;
}

function CostBundleOverrides({ model, costBundles }: CostBundleProps) {
  const modelName = DeHumanizeString(model.title);
  return costBundles
    .map((costBundle) => DeHumanizeString(costBundle.code))
    .map(
      (name) =>
        `HelperFunctions.OverrideCostBundle(inputFieldValueMap, specificAsset.CostBundles, ${modelName}CostBundles.${name}.ToString(), ${modelName}Fields.${name}.ToString(), costBreakouts);`
    )
    .join("\r\n");
}

function IdentifierDefinitions({ model, identifiers }: { model: IModel; identifiers: IIdentifier[] }) {
  var identifiersDefinition = `public static class ${DeHumanizeString(model.title)}Identifiers\r\n`;
  identifiersDefinition += `{\r\n`;

  // Add each identifier to the definition
  for (var identifier of identifiers) {
    identifiersDefinition += `\tpublic static readonly Guid ${DeHumanizeString(identifier.code)} = new Guid("${
      identifier.identifierId
    }");\r\n`;
  }

  identifiersDefinition += `}\r\n`;

  return identifiersDefinition;
}

function ClassFieldListValueDefinitions({
  model,
  modelFields,
  modelFieldValues,
}: {
  model: IModel;
  modelFields: IAssetInputField[];
  modelFieldValues: IAssetInputFieldListValue[];
}) {
  var fieldsDefinition = `public static class ${DeHumanizeString(model.title)}FieldValues\r\n`;
  fieldsDefinition += `{\r\n`;

  // Add each field to the definition
  for (var modelField of modelFields) {
    // We only need this for enumerations
    if (modelField.type !== AssetInputFieldTypeEnum.CustomEnumeration) continue;

    fieldsDefinition += `\tpublic static class ${DeHumanizeString(modelField.label)}FieldValues\r\n`;
    fieldsDefinition += `\t{\r\n`;

    // Add each of the field value properties
    for (var fieldValue of modelFieldValues) {
      // Skip values that don't belong to this field
      if (fieldValue.assetInputFieldId !== modelField.assetInputFieldId) continue;

      fieldsDefinition += `\t\tpublic const string ${DeHumanizeString(fieldValue.displayText)} = "${
        fieldValue.assetInputFieldListValueId
      }";\r\n`;
    }

    fieldsDefinition += `\t}\r\n`;
    fieldsDefinition += `\r\n`;
  }

  fieldsDefinition += `}\r\n`;

  return fieldsDefinition;
}

function ClassFieldDefinitions({
  model,
  modelFields,
  modelFieldCategories,
}: {
  model: IModel;
  modelFields: IAssetInputField[];
  modelFieldCategories: IAssetInputFieldCategory[];
}) {
  var fieldsDefinition = `public static class ${DeHumanizeString(model.title)}Fields\r\n`;
  fieldsDefinition += `{\r\n`;

  for (var category of modelFieldCategories) {
    fieldsDefinition += `\r\n`;
    fieldsDefinition += `\t// ${category.name} \r\n`;

    // Add each field to the definition
    for (var modelField of modelFields) {
      if (modelField.assetInputFieldCategoryId !== category.assetInputFieldCategoryId) continue;
      fieldsDefinition += `\tpublic const string ${DeHumanizeString(modelField.label)} = "${
        modelField.assetInputFieldId
      }";\r\n`;
    }
  }

  fieldsDefinition += `}\r\n`;

  return fieldsDefinition;
}

function ClassModelDefinitions({ model }: { model: IModel }): string {
  return `public class ${model.title} : DecisionApplierBase
{
    public static readonly Guid ImplementationId = new Guid("${model.modelId}");

    public ${model.title}(Model baseAsset) : base(baseAsset)
    {
    }
}`;
}

export default ModalModelDefinitions;
