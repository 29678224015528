import React, { useEffect } from "react";
import { Grid2, useTheme } from "@mui/material";
import {
  Container as MuiContainer,
  Typography,
  Breadcrumbs,
  Link,
  styled,
} from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux";
import { fetchSearchSolvers } from "../../../redux/solver/actions";
import { HomeIconLink } from "../../generic/HomeIconLink";
import AssetJobsEnquiryContainer from "../../assetJobs/AssetJobsEnquiryContainer";
import SolverJobsEnquiryContainer from "../../solverJobs/SolverJobsEnquiryContainer";
import OutputJobsEnquiryContainer from "../../outputJobs/OutputJobsEnquiryContainer";

const Container = styled(MuiContainer)(({ theme }) => ({
  "& .breadcrumbWrapper": {
    marginTop: theme.spacing(2),
  },
}));

const DashboardPage = (routeProps: RouteComponentProps<any>) => {
  const theme = useTheme();
  const solversExist = useSelector(
    (store: RootState) => Object.values(store.solvers.byId).length
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!solversExist) {
      dispatch(fetchSearchSolvers({ pageNumber: 1, pageSize: 50 }));
    }
  }, [solversExist, dispatch]);

  return (
    <Container maxWidth="xl" id="dashboard">
      <Grid2 container>
        <Grid2 size={{xs:9}} className="breadcrumbWrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <HomeIconLink />
            <Link color="textPrimary" aria-current="page">
              Dashboard
            </Link>
          </Breadcrumbs>
        </Grid2>
        <Grid2
          container
          spacing={{ xs: 4, md: 3, lg: 3, xl: 4 }}
          style={{ marginTop: 16 }}
        >
          <Grid2
            size={{
              xs:12,
            md:6,
            lg:4}
            }
            style={{ marginTop: theme.spacing(2) }}
          >
            <AssetJobsEnquiryContainer canEdit={true} />
          </Grid2>
          <Grid2
           size={{
            xs:12,
            md:6,
            lg:4}}
            style={{ marginTop: theme.spacing(2) }}
          >
            <SolverJobsEnquiryContainer canEdit={true} />
          </Grid2>
          <Grid2
            size={{
              xs:12,
              md:6,
              lg:4}}
            style={{ marginTop: theme.spacing(2) }}
          >
            <OutputJobsEnquiryContainer canEdit={true} />
          </Grid2>
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default DashboardPage;
