import React, { useState } from "react";
import {
  Typography,
  List,
  ListItemText,
  Grid2,
  Button,
  Divider,
  FormControlLabel,
  Switch,
  styled,
  ListItemButton,
} from "@mui/material";

import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { useDispatch } from "react-redux";

import { ShowMessage } from "../../../utilities/Helpers";
import AnimationWrapper from "../../generic/animations/AnimationWrapper";
import { ISelectedFunctionalFailure, useFetchAiSuggestFunctionFailuresPageHook } from "../Hooks";
import { IFunctionalFailure } from "../../../utilities/types/FunctionalFailure";
import { fetchCreateFunctionalFailure } from "../../../redux/functionalFailure/actions";
import { AiSuggestedListSkeleton } from "../../generic/skeletons/AiSuggestedListSkeleton";

const GridWrapper = styled(Grid2)(({ theme }) => ({
  "& .listContainer": {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    gap: theme.spacing(1),
  },
  "& .listWrapper": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: "#f5f5f5",
    border: "1px solid #EEE",
    borderRadius: "4px",
    maxHeight: 330,
    height: "100%",
    overflowY: "auto",
  },
  "& .listItem": {
    background: "#FFF",
    border: "1px solid #EEE",
    borderRadius: "4px",
  },
}));

interface FormFunctionFailureAiSuggestProps {
  modelId: string;
  componentId: string;
  functionId: string;
  onCompleteCallback(): void;
  onCancelCallback(): void;
}

const FormFunctionFailureAiSuggest: React.FC<FormFunctionFailureAiSuggestProps> = ({
  modelId,
  componentId,
  functionId,
  onCompleteCallback,
  onCancelCallback,
}) => {
  const dispatch = useDispatch();
  const [inheritIdentifiers, setInheritIdentifiers] = useState<boolean>(true);
  const { fetching, allResultsSet, setAllResultsSet } = useFetchAiSuggestFunctionFailuresPageHook({
    pageNumber: 1,
    pageSize: 500,
    minPageNumberToFetch: 1,
    modelId,
    componentId,
    functionId,
  });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<IFunctionalFailure[]>([]);

  const handleSelect = (item: IFunctionalFailure, index: number, isFromSelectedItem: boolean) => {
    if (isFromSelectedItem) {
      // Remove the item from the selected list
      const newSelectedItems = selectedItems.filter(
        (selectedItem: IFunctionalFailure) => selectedItem.functionalFailureId !== item.functionalFailureId
      );
      setSelectedItems(newSelectedItems);

      const newSuggestedItems = allResultsSet.map((suggestedItem: IFunctionalFailure, indexKey) =>
        indexKey.toString() === item.functionalFailureId ? { ...suggestedItem, selected: false } : suggestedItem
      );

      // Add the item back to the suggested list
      setAllResultsSet(newSuggestedItems); // Add the item back to the suggested list
    } else {
      // Remove the item from the suggested list
      const newSuggestedItems = allResultsSet.map((suggestedItem: IFunctionalFailure, indexKey) =>
        indexKey === index ? { ...suggestedItem, selected: true } : suggestedItem
      );
      setAllResultsSet(newSuggestedItems);

      // Add the item to the selected list
      setSelectedItems([...selectedItems, { ...item, functionalFailureId: index.toString() }]);
    }
  };

  async function handleAdd() {
    if (selectedItems.length === 0) {
      ShowMessage("Please select at least one item from the suggested list.");
      return;
    }
    setSubmitting(true);
    const functionalFailuresList = selectedItems.map((item: IFunctionalFailure) => ({
      modelId,
      name: item.name,
      description: item.description,
      orderNumber: item.orderNumber,
      inheritIdentifiers: inheritIdentifiers,
      functionId,
    }));

    const createdFunctionalFailure: any = await dispatch(
      fetchCreateFunctionalFailure({
        functionalFailures: functionalFailuresList,
      })
    );

    setSubmitting(false);

    if (createdFunctionalFailure) onCompleteCallback();
  }

  return (
    <GridWrapper container spacing={2}>
      <Grid2 size={{sm:6, xs:12}}>
        <div className="listContainer">
          <Typography variant="body1" component="span">
            Suggested
          </Typography>
          <List component="nav" className="listWrapper">
            {fetching ? (
              <AiSuggestedListSkeleton />
            ) : (
              allResultsSet.map(
                (item: ISelectedFunctionalFailure, index) =>
                  !item?.selected && (
                    <AnimationWrapper key={`suggested_${index}`}>
                      <ListItemButton className="listItem" onClick={() => handleSelect(item, index, false)}>
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </AnimationWrapper>
                  )
              )
            )}
          </List>
        </div>
      </Grid2>

      <Grid2 size={{sm:6, xs:12}}>
        <div className="listContainer">
          <Typography variant="body1" component="span">
            Selected
          </Typography>
          <List component="nav" className="listWrapper">
            {selectedItems.map((item: IFunctionalFailure, index) => (
              <AnimationWrapper key={`selected_${index}`}>
                <ListItemButton className="listItem" onClick={() => handleSelect(item, index, true)}>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </AnimationWrapper>
            ))}
          </List>
        </div>
      </Grid2>
      <Grid2 size={{xs:12}}>
        <FormControlLabel
          control={
            <Switch
              checked={inheritIdentifiers}
              onChange={() => {
                setInheritIdentifiers((prev) => !prev);
              }}
              name="InheritIdentifiers"
            />
          }
          label="Inherit Identifiers"
        />
      </Grid2>
      <Grid2 size={{xs:12}}>
        <Divider light={true} />
      </Grid2>
      <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
        <Button variant="text" disabled={submitting} style={{ marginRight: 16 }} onClick={onCancelCallback}>
          Close
        </Button>
        <Button variant="outlined" disabled={submitting} color="primary" onClick={handleAdd}>
          Add
        </Button>
      </Grid2>
      <LoaderAbsoluteCentred loading={submitting} />
    </GridWrapper>
  );
};

export default FormFunctionFailureAiSuggest;
