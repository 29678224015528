import React, { useState } from "react";
import {
  TextField,
  Grid2,
  Divider,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  getFormikFieldProps,
  ShowMessage,
  UpdateMessage,
} from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { ISolver, SolverStatus } from "../../../utilities/types/Solver";
import {
  fetchUpdateSolver,
  fetchDeleteSolver,
} from "../../../redux/solver/actions";

interface FormValues {
  solverId: string;
  name: string;
  description: string;
  inputBucket: string;
  outputBucket: string;
  summaryOutputBucket: string;
  status: SolverStatus;
}

interface FormProps {
  solver: ISolver;
  onCompleteCallback(solver?: ISolver): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ISolver, AnyAction>;
  canEdit: boolean;
}

const FormSolverUpdate = (formProps: FormProps) => {
  const {
    onCancelCallback,
    solver: { solverId },
    canEdit,
  } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const toggleDeleteConfirmation = () =>
    setShowDeleteConfirmation(!showDeleteConfirmation);

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch, solver } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var updatedSolver = await dispatch(
      fetchUpdateSolver({
        ...values,
        solverId: solver.solverId,
      })
    );

    setSubmitting(false);
    if (updatedSolver) {
      UpdateMessage({
        ...message,
        text: "Updated",
        type: MessageTypeEnum.Success,
      });
      onCompleteCallback(updatedSolver);
    }
  };

  return (
    <Formik
      initialValues={{
        ...formProps.solver,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        const { dispatch } = formProps;

        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage(
            "Removing...",
            MessageTypeEnum.Information
          );
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteSolver({ solverId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({
              ...message,
              text: "Removed",
              type: MessageTypeEnum.Success,
            });
            onCancelCallback();
          }
        }

        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={solverId} name="solverId" />

            <Grid2 container spacing={2}>
            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "name", "Name")}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <TextField
                  onChange={props.handleChange}
                  multiline
                  rows={3}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "description", "Description")}
                />
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "inputBucket", "Input Bucket")}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(
                    props,
                    "outputBucket",
                    "Output Bucket"
                  )}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(
                    props,
                    "summaryOutputBucket",
                    "Summary Bucket"
                  )}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <FormControl fullWidth={true}>
                  <InputLabel id="lbl-solver-status" variant="standard">
                    Status
                  </InputLabel>
                  <Select
                    {...getFormikFieldProps(props, "status", "status")}
                    labelId="lbl-model-status"
                    style={{ display: "block" }}
                    id="txt-solver-status"
                    variant="standard"
                    defaultValue={formProps.solver.status}
                    onChange={(e) => {
                      props.setFieldValue("status", e.target.value);
                    }}
                  >
                    <MenuItem value={SolverStatus.AdminOnly}>
                      Admin Only
                    </MenuItem>
                    <MenuItem value={SolverStatus.Preview}>Preview</MenuItem>
                    <MenuItem value={SolverStatus.Enabled}>Enabled</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={{xs:12}}>
                <Divider light={true} />
              </Grid2>
              <Grid2 size={{xs:12}}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  {canEdit ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{ flexBasis: "33%" }}
                      onClick={toggleDeleteConfirmation}
                    >
                      Delete
                    </Button>
                  ) : null}

                  <div
                    style={{
                      flexBasis: "66%",
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <Button
                      disabled={submitting}
                      variant="text"
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    {canEdit ? (
                      <Button
                        type="submit"
                        disabled={submitting}
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: 16 }}
                      >
                        Update
                      </Button>
                    ) : null}
                  </div>

                  <LoaderAbsoluteCentred loading={submitting || deleting} />
                  <WidgetModalConfirmationDialog
                    open={showDeleteConfirmation}
                    title="Delete solver"
                    subtitle="Confirm solver delete"
                    description="Are you sure that you'd like to remove this solver?"
                    onCancelCallback={toggleDeleteConfirmation}
                    onConfirmCallback={deleteHandler}
                    confirmButtonText="Delete"
                  />
                </div>
              </Grid2>
            </Grid2>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          name: Yup.string()
            .label("Label")
            .min(3, "Please input 3 characters or more")
            .max(50, "Please input 50 characters or less")
            .required("Please provide a name"),
          description: Yup.string()
            .label("Description")
            .max(500, "Please input 500 characters or less"),
          inputBucket: Yup.string()
            .label("Label")
            .min(3, "Please input 3 characters or more")
            .max(50, "Please input 50 characters or less")
            .required("Please provide an input bucket"),
          outputBucket: Yup.string()
            .label("Label")
            .min(3, "Please input 3 characters or more")
            .max(50, "Please input 50 characters or less")
            .required("Please provide an output bucket"),
          summaryOutputBucket: Yup.string()
            .label("Label")
            .min(3, "Please input 3 characters or more")
            .max(50, "Please input 50 characters or less")
            .required("Please provide a  output bucket"),
        });
      }}
    />
  );
};

export default FormSolverUpdate;
