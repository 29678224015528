import React from "react";
import { Grid2, Divider, Button, FormControl, Switch, Typography } from "@mui/material";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { ITask, PrimaryLabourEnum, TaskTypeEnum } from "../../../utilities/types/Task";
import { fetchCreateTask } from "../../../redux/task/actions";
import StringListInput from "../../generic/widgets/StringListInput";

interface FormValues {
  name: string[];
  description: string;
  primaryLabour: number;
  type: TaskTypeEnum;
  rampTime: number;
  duration: number;
  orderNumber: number;
}

interface FormProps {
  modelId: string;
  onCompleteCallback(task?: ITask): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ITask, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  const { values, errors, handleChange } = props;

  const handleNameChange = (newStringList: string[]) => {
    handleChange({
      target: {
        name: "name",
        value: newStringList,
      },
    });
  };
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
        <Grid2 size={{xs:12}}>
          <StringListInput label="Task Name(s)" onStringListChange={handleNameChange} stringList={values.name} errors={errors.name} />
        </Grid2>
        <Grid2 size={{xs:12}}>
          <FormControl fullWidth={true}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ marginRight: "10px" }}>Planned</Typography>
              <Switch
                checked={props.values.type === TaskTypeEnum.Impact}
                onChange={() => {
                  props.setFieldValue(
                    "type",
                    props.values.type === TaskTypeEnum.Impact ? TaskTypeEnum.Applicability : TaskTypeEnum.Impact
                  );
                }}
                name="type-switch"
              />
              <Typography style={{ marginRight: "10px" }}>Corrective</Typography>
            </div>
          </FormControl>
        </Grid2>

        <Grid2 size={{xs:12}}>
          <Divider light={true} />
        </Grid2>
        <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormTaskCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    name: [],
    description: "",
    primaryLabour: PrimaryLabourEnum.Operations,
    rampTime: 0,
    duration: 0,
    orderNumber: 0,
    type: TaskTypeEnum.Impact,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.array().of(
      Yup.string()
        .label("Label")
        .min(3, "Please input 3 characters or more")
        .max(500, "Please input 500 characters or less")
        .required("Please provide a name")
    ),
    description: Yup.string().label("Description").max(500, "Please input 500 characters or less"),
    rampTime: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999999"),
    duration: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999999"),
    orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999, "Less than 999"),
    type: Yup.number().required(),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId } = props;

    const tasksToCreate = values.name.map((name) => ({
      ...values,
      modelId,
      name,
    }));
    // Map dispatch via props
    var createdTask = await dispatch(fetchCreateTask({ tasks: tasksToCreate }));

    setSubmitting(false);

    if (createdTask) onCompleteCallback(createdTask);
  },
})(InnerForm);

export default FormTaskCreate;
