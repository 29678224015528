import React, { useState } from "react";
import { Grid2, Typography } from "@mui/material";
import { Container } from "@mui/material";
import { useFetchSolversPageHook } from "../Hooks";
import { RouteComponentProps } from "react-router-dom";
import SolverSearchWidget from "../SolverSearchWidget";
import SolverSearchResultWidget from "../SolverSearchResultWidget";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import SearchIcon from "@mui/icons-material/Search";
import AnimationWrapper from "../../generic/animations/AnimationWrapper";
import AddNewSolverWidget from "../AddNewSolverWidget";
import { ISolver } from "../../../utilities/types/Solver";
import { sortSolversByNameAsc } from "../../../redux/solver/selectors";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { selectorGetUserPermissionOfType } from "../../../redux/userPermission/selectors";
import { GetUserId } from "../../../utilities/ApiUtils";
import { UserPermissionTypeEnum } from "../../../utilities/types/UserPermission";

const PageSolverSearch = (routeProps: RouteComponentProps<any>) => {
  const [searchResults, setSearchResults] = useState<ISolver[]>([]);

  // Fetch first page of solvers
  useFetchSolversPageHook({
    pageNumber: 1,
    pageSize: 50,
    minPageNumberToFetch: 1,
  });

  const setReturnedResults = (solvers: ISolver[], morePages: boolean) => setSearchResults(solvers);
  const canCreateSolvers = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesSolverCreate)
  );

  return (
    <Container maxWidth="xl">
      <Grid2 container>
         <Grid2 size={{xs:12}} style={{ marginTop: 16 }}>
          <Typography variant="h5">Search Solvers</Typography>
        </Grid2>

         <Grid2 size={{xs:12}} style={{ marginTop: 16 }}>
          <SolverSearchWidget setReturnedResultSet={setReturnedResults} />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <Grid2 container spacing={3} style={{ marginTop: 16 }} id="search-results-wrapper">
            {canCreateSolvers ? (
              <AnimationWrapper key={"Test"}>
                <Grid2 size={{xs:12 ,sm:6, md:4}}>
                  <AddNewSolverWidget />
                </Grid2>
              </AnimationWrapper>
            ) : null}
            {searchResults.length ? (
              sortSolversByNameAsc(searchResults).map((searchResult, i) => {
                //if (i >= 9) return null;

                return (
                  <AnimationWrapper key={searchResult.solverId}>
                    <Grid2 size={{xs:12 ,sm:6, md:4}}>
                      <SolverSearchResultWidget solver={searchResult} loading={false} />
                    </Grid2>
                  </AnimationWrapper>
                );
              })
            ) : (
              <Grid2 size={{xs:12}}>
                <WidgetNoResultsPlaceholder text="No results" icon={SearchIcon} />
              </Grid2>
            )}
          </Grid2>
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default PageSolverSearch;
