import {
  Button,
  DialogActions,
  Divider,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import HeaderIcon from "@mui/icons-material/PersonTwoTone";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { fetchCreateEntityPermissions, fetchEntityisValid } from "../../../redux/entityPermission/actions";
import { selectorGetEntityPermissionsByUserIdAndEntityId } from "../../../redux/entityPermission/selectors";
import { selectorGetUserDetailById } from "../../../redux/userDetail/selectors";
import { useDebounceCallback } from "../../../utilities/misc";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import { EntityPermissionTypeEnum } from "../../../utilities/types/EntityPermission";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import EntityPermissionPicklist from "../EntityPermissionPicklist";

export interface IModalEntityPermissionsPermissionSelectProps {
  open: boolean;
  onCancelCallback(): void;
  userDetailId: string;
  entityId?: string;
  entityType?: EntityTypeEnum;
}

enum EntityValidateState {
  Success,
  Error,
}

function ModalEntityPermissionsPermissionSelect({
  onCancelCallback,
  open,
  userDetailId,
  entityId,
  entityType,
}: IModalEntityPermissionsPermissionSelectProps) {
  const dispatch = useDispatch();
  const entityPermissions = useSelector((store: RootState) =>
    selectorGetEntityPermissionsByUserIdAndEntityId(store, userDetailId, entityId || "")
  );
  const userDetail = useSelector((store: RootState) => selectorGetUserDetailById(store, userDetailId));
  const [loading, setLoading] = useState(false);
  const [selectedEntityPermissions, setSelectedEntityPermissions] = useState<number[]>([]);
  const [selectedEntityId, setSelectedEntityId] = useState(entityId || "");
  const [selectedEntityType, setSelectedEntityType] = useState(entityType || 0);
  const [entityTypeOptions] = useState(
    Object.values(EntityTypeEnum)
      .filter((x) => !isNaN(Number(x)))
      .sort((a, b) =>
        EntityTypeEnum[Number(a)] === EntityTypeEnum[Number(b)]
          ? 0
          : EntityTypeEnum[Number(a)] < EntityTypeEnum[Number(b)]
          ? -1
          : 1
      )
      .map((x) => {
        return <MenuItem value={x}>{EntityTypeEnum[Number(x)]}</MenuItem>;
      })
  );

  const [entityMessage, entityState] = useCheckEntity(selectedEntityId, selectedEntityType);

  async function onSave() {
    setLoading(true);

    var resp = await dispatch(
      fetchCreateEntityPermissions({
        targetUserId: userDetailId,
        entityId: selectedEntityId,
        entityType: selectedEntityType,
        entityPermissions: selectedEntityPermissions,
      })
    );

    setLoading(false);

    if (resp != null) onCancelCallback();
  }

  function getEntityIdFieldValue(e: any) {
    setSelectedEntityId(e?.target?.value || "");
  }

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={`Entity permissions`}
      subtitle={`Configuring entity permissions for ${userDetail?.displayName || "N/A"}.`}
      headerIcon={<HeaderIcon />}
      style={{ maxWidth: 600, minWidth: 550 }}
    >
      <DialogContent>
        <Grid2 container spacing={1}>
          <Grid2 size={{xs:6}}>
            <TextField
              onChange={getEntityIdFieldValue}
              fullWidth
              variant="standard"
              label="Entity Id"
              placeholder="Entity Id"
              margin="none"
            />
          </Grid2>
          <Grid2 size={{xs:6}}>
            <FormControl fullWidth={true}>
              <InputLabel id="lbl-entity-type" variant="standard">
                Entity Type
              </InputLabel>
              <Select
                labelId="lbl-model-status"
                style={{ display: "block" }}
                id="txt-entity-type"
                variant="standard"
                onChange={(e) => setSelectedEntityType(Number(e?.target?.value))}
              >
                {entityTypeOptions}
              </Select>
            </FormControl>
          </Grid2>
          {entityMessage && (
            <Grid2 size={{xs:12}}>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  color:
                    entityState === EntityValidateState.Success
                      ? "green"
                      : entityState === EntityValidateState.Error
                      ? "red"
                      : undefined,
                }}
              >
                {entityState === EntityValidateState.Success && <CheckCircleIcon style={{ marginRight: "5px" }} />}
                {entityState === EntityValidateState.Error && <ErrorIcon style={{ marginRight: "5px" }} />}
                <span>{entityMessage}</span>
              </p>
            </Grid2>
          )}
          <Grid2 size={{xs:12}}>
            <EntityPermissionPicklist
              userDetailId={userDetailId}
              onSelectedItemsChange={setSelectedEntityPermissions}
              allEntityPermissions={Object.values(EntityPermissionTypeEnum)
                .filter((x) => !isNaN(Number(x)))
                .map((x) => Number(x))}
              entityPermissionMappings={entityPermissions.map((x) => Number(x.type))}
              leftLabel="Available"
              rightLabel="Assigned"
            />
          </Grid2>
        </Grid2>
      </DialogContent>
      <Divider light={true} style={{ marginBottom: 16, marginTop: 8 }} />
      <DialogActions>
        <Button variant="outlined" onClick={onCancelCallback} fullWidth={true}>
          Close
        </Button>
        <Button variant="contained" color="primary" onClick={onSave} fullWidth={true}>
          Add
        </Button>
      </DialogActions>
      <LoaderAbsoluteCentred loading={loading} />
    </WidgetModalBase>
  );
}

function useCheckEntity(
  entityId: string | undefined,
  entityType: EntityTypeEnum | undefined
): [string?, EntityValidateState?] {
  const [message, setMessage] = useState<{ message?: string; state?: EntityValidateState }>({});

  // Only callback once the user stops typing
  useDebounceCallback(
    () => {
      if (entityId && entityType) {
        setMessage({ message: "Checking entity exists..." });
        fetchEntityisValid({ entityId, entityType }).then((isValid) =>
          setMessage({
            state: isValid ? EntityValidateState.Success : EntityValidateState.Error,
            message: isValid ? "The entity is valid." : "The entity doesn't exist or the type is invalid.",
          })
        );
      } else setMessage({});
    },
    250,
    [entityId, entityType]
  );

  return [message.message, message.state];
}

export default ModalEntityPermissionsPermissionSelect;
