import { Button, Divider, Grid2, TextField } from "@mui/material";
import { InjectedFormikProps, withFormik } from "formik";
import React from "react";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { IIdentifierMapping } from "../../../utilities/types/IdentifierMapping";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { fetchUpdateIdentifierMapping } from "../../../redux/identifierMapping/actions";

interface FormValues {
  identifierMappingValue?: number;
}

interface FormProps {
  identifierMapping: IIdentifierMapping;
  onCompleteCallback(identifierMapping?: IIdentifierMapping[]): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IIdentifierMapping, AnyAction>;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
        <Grid2 size={{xs:12}}>
          <TextField
            onChange={props.handleChange}
            type="number"
            fullWidth
            inputProps={{ step: "0.1", required: false }}
            defaultValue={0}
            margin="normal"
            variant="standard"
            {...getFormikFieldProps(props, "identifierMappingValue", "Value")}
          />
        </Grid2>
        <Grid2 size={{xs:12}}>
          <Divider light={true} />
        </Grid2>
        <Grid2  size={{xs:12}}>
          <div style={{ flex: "1" }} />
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Update
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormIdentifierMappingUpdate = withFormik<FormProps, FormValues>({
  mapPropsToValues: (props) => ({
    identifierMappingValue: Number(props.identifierMapping.identifierMappingValue) || 0, // Cast to number and provide a default value if it's not a number
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, identifierMapping } = props;

    // Map dispatch via props
    var updatedIdentifierMapping = await dispatch(
      fetchUpdateIdentifierMapping({
        ...values,
        identifierMappingId: identifierMapping.identifierMappingId,
        modelId: identifierMapping.modelId,
        targetIds: [identifierMapping.targetId],
        identifierIds: [identifierMapping.identifierId],
        type: identifierMapping.mappingType,
      })
    );

    setSubmitting(false);

    if (updatedIdentifierMapping) onCompleteCallback(updatedIdentifierMapping);
  },
})(InnerForm);

export default FormIdentifierMappingUpdate;
