import { useState } from "react";

import {
  CircularProgress,
  Container,
  Grid2,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import LayersIcon from "@mui/icons-material/Layers";
import { useDispatch, useSelector } from "react-redux";
import { ReactFlowInstance, ReactFlowProvider } from "reactflow";

import { RootState } from "../../../redux";
import {
  selectorGetAllWorkflows,
  selectorGetSelectedWorkflow,
} from "../../../redux/workflow/selectors";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { useFetchWorkflowsPageHook } from "../Hooks";
import WorkflowHeaderWidget from "../WorkflowHeaderWidget";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import {
  fetchRunWorkflow,
  fetchUpdateWorkflow,
} from "../../../redux/workflow/actions";
import { IWorkflow } from "../../../utilities/types/Workflow";
import { ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { MessageTypeEnum } from "../../../utilities/types/Message";

import ReactflowWidget from "../ReactflowWidget";

const WorkflowPage = (routeProps: RouteComponentProps<any>) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [rfInstance, setRfInstance] = useState<ReactFlowInstance | null>(null);

  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const { fetching } = useFetchWorkflowsPageHook({
    page: 1,
    maxPageSize: 500,
    minPageNumberToFetch: 1,
  });

  const allWorkflows = useSelector((store: RootState) =>
    selectorGetAllWorkflows(store)
  );
  const selectedWorkFlow = useSelector((store: RootState) =>
    selectorGetSelectedWorkflow(store)
  );

  async function handleRunWorkflow(workflowId: string) {
    if (!workflowId) return;
    setApiLoading(true);
    await dispatch(
      fetchRunWorkflow({
        workflowId: workflowId,
      })
    );
    setApiLoading(false);
  }

  async function handleSaveWorkflow(workflow: IWorkflow) {
    if (!workflow) return;
    const message = await ShowMessage("Saving", MessageTypeEnum.Information);
    setApiLoading(true);
    let flowData: any = {};
    if (rfInstance) {
      flowData = rfInstance.toObject();
      if (rfInstance.getNodes().length > 0) {
        rfInstance.setNodes(
          rfInstance.getNodes().map((node: any) => {
            if (node.data?.summaryOutputTypeInputFieldValueMap) {
              delete node.data.summaryOutputTypeInputFieldValueMap;
            }
            return node;
          })
        );
      }
    }
    const savedWorkflow = await dispatch(
      fetchUpdateWorkflow({
        ...workflow,
        workflowObject: flowData,
      })
    );
    setApiLoading(false);
    if (savedWorkflow !== null) {
      UpdateMessage({
        ...message,
        text: "Saved",
        type: MessageTypeEnum.Success,
      });
    }
  }

  return (
    <Container maxWidth="xl" id="dashboard" disableGutters={isSmallDevice}>
      <Grid2 container flexDirection={'column'}>
         <Grid2 size={{xs:12}} style={{ marginTop: 16 }}>
          <WorkflowHeaderWidget
            allWorkflows={allWorkflows}
            selectedWorkFlow={selectedWorkFlow}
            handleRunWorkflow={handleRunWorkflow}
            handleSaveWorkflow={handleSaveWorkflow}
            setApiLoading={setApiLoading}
          />
        </Grid2>

        <Grid2
          container
          spacing={isSmallDevice ? 0 : 5}
          style={{ marginTop: 16 }}
        >
          {!selectedWorkFlow ? (
            <Grid2 size={{xs:12}}>
              <WidgetNoResultsPlaceholder
                text={
                  allWorkflows.length > 0
                    ? "Please remember to select a workflow to proceed."
                    : "Currently, no workflows have been created for your account."
                }
                description={
                  allWorkflows.length > 0
                    ? "Feel free to select one from the dropdown menu."
                    : "Feel free to create a new workflow by clicking the plus icon above."
                }
                icon={LayersIcon}
              />
            </Grid2>
          ) : (
            <Grid2 size={{xs:12,md:12,lg:12,xl:12}}>
              <ReactFlowProvider>
                <ReactflowWidget
                  setRfInstance={setRfInstance}
                  selectedWorkFlow={selectedWorkFlow}
                />
              </ReactFlowProvider>
            </Grid2>
          )}
        </Grid2>
      </Grid2>

      <LoaderAbsoluteCentred loading={fetching || apiLoading} />
    </Container>
  );
};

export default WorkflowPage;
