import React from "react";
import { TextField, Grid2, Divider, Button, MenuItem, FormControl, Select, InputLabel } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { CauseActionTypeEnum, ICause } from "../../../utilities/types/Cause";
import { fetchCreateCause } from "../../../redux/cause/actions";

interface FormValues {
  name: string;
  description: string;
  disabled: boolean;
  orderNumber: number;
  actionType: CauseActionTypeEnum;
}

interface FormProps {
  modelId: string;
  onCompleteCallback(cause?: ICause): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ICause, AnyAction>;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
        <Grid2 size={{xs:12}}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "name", "Cause Logic Name")}
            fullWidth
            margin="normal"
            variant="standard"
          />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <TextField
            onChange={props.handleChange}
            multiline
            rows={9}
            variant="standard"
            fullWidth
            margin="normal"
            {...getFormikFieldProps(props, "description", "Description of Logic")}
          />
        </Grid2>
        <Grid2 size={{xs:12}}>
          <FormControl fullWidth={true}>
            <InputLabel id="lbl-model-type" variant="standard">
              Type
            </InputLabel>
            <Select
              labelId="lbl-model-type"
              {...getFormikFieldProps(props, "actionType", "Action Type")}
              onChange={(e) => {
                props.setFieldValue("actionType", e.target.value);
              }}
              style={{ display: "block" }}
              variant="standard"
              id="txt-model-type"
            >
              <MenuItem value={CauseActionTypeEnum.Enable}>Enable</MenuItem>
              <MenuItem value={CauseActionTypeEnum.ChangeBeta}>ChangeBeta</MenuItem>
              <MenuItem value={CauseActionTypeEnum.ChangeEta}>ChangeEta</MenuItem>
              <MenuItem value={CauseActionTypeEnum.ChangeGamma}>ChangeGamma</MenuItem>
              <MenuItem value={CauseActionTypeEnum.DoNothing}>DoNothing</MenuItem>
              <MenuItem value={CauseActionTypeEnum.NewEta}>NewEta</MenuItem>
              <MenuItem value={CauseActionTypeEnum.NewBeta}>NewBeta</MenuItem>
              <MenuItem value={CauseActionTypeEnum.NewGamma}>NewGamma</MenuItem>
              <MenuItem value={CauseActionTypeEnum.InitialAge}>InitialAge</MenuItem>
              <MenuItem value={CauseActionTypeEnum.ChangePF}>ChangePF</MenuItem>
              <MenuItem value={CauseActionTypeEnum.SetIAtoPercentOfEta}>SetIAtoPercentOfEta</MenuItem>
              <MenuItem value={CauseActionTypeEnum.SetQuantity}>SetQuantity</MenuItem>
              <MenuItem value={CauseActionTypeEnum.AddPercentOfEtatoIA}>AddPercentOfEtatoIA</MenuItem>
              <MenuItem value={CauseActionTypeEnum.AddToInitialAge}>AddToInitialAge</MenuItem>
              <MenuItem value={CauseActionTypeEnum.AddToEta}>AddToEta</MenuItem>
              <MenuItem value={CauseActionTypeEnum.OverwriteCostBundles}>OverwriteCostBundles</MenuItem>
              <MenuItem value={CauseActionTypeEnum.OverwritePlanIntervals}>OverwritePlanIntervals</MenuItem>
              <MenuItem value={CauseActionTypeEnum.ScaleCosts}>ScaleCosts</MenuItem>
              <MenuItem value={CauseActionTypeEnum.DisablePlan}>DisablePlan</MenuItem>
              <MenuItem value={CauseActionTypeEnum.AddToCosts}>AddToCosts</MenuItem>
              <MenuItem value={CauseActionTypeEnum.SetPlanInterval}>SetPlanInterval</MenuItem>
              <MenuItem value={CauseActionTypeEnum.SetPlanOffset}>SetPlanOffset</MenuItem>
              <MenuItem value={CauseActionTypeEnum.AddToCostsAndSplitout}>AddToCostsAndSplitout</MenuItem>
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 size={{xs:12}}></Grid2>

        <Grid2 size={{xs:12}}>
          <Divider light={true} />
        </Grid2>
        <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormCauseCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    name: "",
    description: "",
    disabled: false,
    orderNumber: 0,
    actionType: CauseActionTypeEnum.Enable,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .label("Label")
      .min(3, "Please input 3 characters or more")
      .max(50, "Please input 50 characters or less")
      .required("Please provide a name"),
    description: Yup.string().label("Description").max(2500, "Please input 2500 characters or less"),
    orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId } = props;

    // Map dispatch via props
    var createdCause = await dispatch(
      fetchCreateCause({
        ...values,
        modelId,
      })
    );

    setSubmitting(false);

    if (createdCause) onCompleteCallback(createdCause);
  },
})(InnerForm);

export default FormCauseCreate;
