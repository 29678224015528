import React, { useEffect, useState } from "react";

import {Grid2 as GridLayout} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { Typography, ListItemAvatar, Avatar, styled, ListItemButton } from "@mui/material";
import { UserPermissionTypeEnum } from "../../utilities/types/UserPermission";
import { HumanizeString, AddSpacesToSentence } from "../../utilities/Helpers";

const GridWrapper = styled(GridLayout)(({ theme }) => ({
  margin: "auto",
  "& .paper": {
    width: 400,
    height: 500,
    overflow: "auto",
    border: "1px solid rgba(0,0,0,0.1)",
    boxShadow: "none",
    padding: 0,
  },
  "& .listItem": {
    border: "1px solid rgba(0,0,0,0.1)",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    width: "calc(100% - 16px)",
    overflow: "hidden",
    backgroundColor: "rgba(255,255,255,1)",
  },
  "& .listItemAvatar": {
    width: "60px",
    height: "20px",
    fontSize: "0.75rem",
  },
  "& .listLeft": {
    backgroundColor: "rgba(0,0,0,0.02)",
  },
  "& .listRight": {
    backgroundColor: "rgba(0,0,0,0.02)",
  },
}));

function not(a: number[], b: number[]) {
  return a.filter((aVal) => !b.some((c) => c === aVal));
}

function getUserRolePermissionsByMappings(userPermissions: number[], mappings: number[]) {
  var test = userPermissions.filter((x) => mappings.some((y) => y === x));
  return test;
}

interface IUserRolePermissionPickListProps {
  userRoleId: string;
  onSelectedItemsChange(mappings: number[]): void;
  allUserRolePermissions: number[];
  userRolePermissionMappings: number[];
  leftLabel?: string;
  rightLabel?: string;
}

export default function UserRolePermissionPickList({
  allUserRolePermissions,
  userRolePermissionMappings,
  onSelectedItemsChange,
  leftLabel,
  rightLabel,
}: IUserRolePermissionPickListProps) {
  const [right, setRight] = useState<number[]>(
    getUserRolePermissionsByMappings(allUserRolePermissions, userRolePermissionMappings)
  );
  const [left, setLeft] = useState<number[]>(not(allUserRolePermissions, right));
  const [assignedMappingsCount, setAssignedMappingsCount] = useState<number>(userRolePermissionMappings.length);
  const [userRolePermissionsCount, setUserRolePermissionsCount] = useState<number>(allUserRolePermissions.length);

  function selectItem(clickedItem: number, rightItems: boolean) {
    if (rightItems) {
      let mergedItems = [...left, clickedItem];
      setLeft(mergedItems);
      setRight(not(allUserRolePermissions, mergedItems));
    } else {
      let mergedItems = [...right, clickedItem];
      setRight(mergedItems);
      setLeft(not(allUserRolePermissions, mergedItems));
    }
  }

  // Allow parent component to hook into current items
  useEffect(() => {
    onSelectedItemsChange(right);
  }, [right, onSelectedItemsChange]);

  // Ensure that mappings not initially available are still catered for
  useEffect(() => {
    if (
      userRolePermissionMappings.length !== assignedMappingsCount ||
      allUserRolePermissions.length !== userRolePermissionsCount
    ) {
      setAssignedMappingsCount(userRolePermissionMappings.length);
      setUserRolePermissionsCount(allUserRolePermissions.length);

      var rightUserPermissions = getUserRolePermissionsByMappings(allUserRolePermissions, userRolePermissionMappings);
      setRight(rightUserPermissions);
      setLeft(not(allUserRolePermissions, rightUserPermissions));
    }
  }, [userRolePermissionMappings, assignedMappingsCount, allUserRolePermissions, userRolePermissionsCount]);

  const customList = (items: number[], right: boolean) => (
    <Paper className={`paper ${right ? "listRight" : "listLeft"}`}>
      <List dense component="div" role="list" style={{ paddingTop: 0 }}>
        {items.map((value) => {
          return <CustomListItem userPermission={value} onClick={() => selectItem(Number(value), right)} />;
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <GridWrapper container spacing={2} justifyContent="center" alignItems="center">
      <GridLayout>
        <Typography variant="overline">{leftLabel || "Unlinked"}</Typography>
        {customList(left, false)}
      </GridLayout>
      <GridLayout>
        <Typography variant="overline">{rightLabel || "Linked"}</Typography>
        {customList(right, true)}
      </GridLayout>
    </GridWrapper>
  );
}

function CustomListItem({ userPermission, onClick }: { userPermission: number; onClick(): void }) {
  const labelId = `transfer-list-item-${userPermission}-label`;

  const label = UserPermissionTypeEnum[userPermission];

  return (
    <ListItemButton key={userPermission} role="listitem" onClick={onClick} className="listItem">
      <ListItemAvatar>
        <Avatar className="listItemAvatar">{userPermission}</Avatar>
      </ListItemAvatar>
      <ListItemText id={labelId} primary={HumanizeString(AddSpacesToSentence(label))} />
    </ListItemButton>
  );
}
