import React, { useState } from "react";
import { TextField, Grid2, Divider, Button } from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import { fetchUpdateSolverHistory, fetchDeleteSolverHistory } from "../../../redux/solverHistory/actions";
import { ISolverHistory } from "../../../utilities/types/SolverHistory";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch } from "react-redux";

interface FormValues {
  title: string;
  description?: string;
  versionNumber: number;
  solverHistoryId: string;
}

interface FormProps {
  solverHistoryId: string;
  title: string;
  description?: string;
  versionNumber: number;
  onCompleteCallback(solverHistory?: ISolverHistory): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ISolverHistory, AnyAction>;
  canEdit: boolean;
}

const FormSolverHistoryUpdate = (formProps: FormProps) => {
  const { solverHistoryId } = formProps;
  const { onCancelCallback, canEdit } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const disabled = canEdit ? {} : { disabled: true };
  const [deletingSolverHistory, setDeletingSolverHistory] = useState(false);
  const dispatch = useDispatch();

  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var solverHistory = await dispatch(
      fetchUpdateSolverHistory({
        ...values,
      })
    );

    UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
    setSubmitting(false);
    onCompleteCallback(solverHistory);
    onCancelCallback();
  };

  async function deleteSolverHistory() {
    // Inform user
    setDeletingSolverHistory(true);
    var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
    setShowDeleteConfirmation(false);

    // Perform delete
    var resp = await dispatch(fetchDeleteSolverHistory({ solverHistoryId }));
    if (resp != null) {
      UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
    }

    // Cleanup
    setDeletingSolverHistory(false);
    onCancelCallback();
  }

  return (
    <Formik
      initialValues={{
        title: formProps.title,
        description: formProps.description,
        versionNumber: formProps.versionNumber,
        solverHistoryId: formProps.solverHistoryId,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={formProps.solverHistoryId} name="solverHistoryId" />
            <Grid2 container spacing={2}>
            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "title", "Title")}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...disabled}
                />
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  type="number"
                  fullWidth
                  {...disabled}
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "versionNumber", "Version Number")}
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <TextField
                  onChange={props.handleChange}
                  multiline
                  rows={3}
                  {...disabled}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "description", "Description")}
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <Divider light={true} />
              </Grid2>
              <Grid2 size={{xs:12}}>
                <div style={{ display: "flex" }}>
                  <div style={{ flexBasis: "25%", display: "flex", justifyContent: "flex-start" }}>
                    {canEdit ? (
                      <Button
                        disabled={submitting}
                        variant="contained"
                        color="secondary"
                        onClick={toggleDeleteConfirmation}
                      >
                        Delete
                      </Button>
                    ) : null}
                  </div>
                  <div style={{ flexBasis: "75%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    {canEdit ? (
                      <Button
                        type="submit"
                        disabled={submitting}
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: 16 }}
                      >
                        Update
                      </Button>
                    ) : null}
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting || deletingSolverHistory} />
                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete confirmation"
                  subtitle="Confirm history delete"
                  description="Are you sure that you'd like to remove this history entry?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteSolverHistory}
                  confirmButtonText="Delete"
                />
              </Grid2>
            </Grid2>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          title: Yup.string()
            .label("Title")
            .min(3, "Please input 3 characters or more")
            .max(50, "Please input 50 characters or less")
            .required("Please input login name"),
          description: Yup.string().label("Description").max(500, "Please input 500 characters or less"),
          versionNumber: Yup.number().label("Version Number").min(0, "Zero or more.").max(999, "Less than 1000"),
        });
      }}
    />
  );
};

export default FormSolverHistoryUpdate;
