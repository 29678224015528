import React from "react";
import { Grid2, Breadcrumbs, Link, Typography, styled } from "@mui/material";

import { Container } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import TaskMaintenanceSection from "../TaskMaintenanceSection";
import TaskImpactsSection from "../../taskImpact/TaskImpactsSection";
import TaskApplicabilitiesSection from "../../taskApplicability/TaskApplicabilitiesSection";
import { HomeIconLink } from "../../generic/HomeIconLink";

const GridWrapper = styled(Grid2)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const PageTaskDetails = (routeProps: RouteComponentProps<any>) => {
  const {
    match: {
      params: { modelId, taskId, modelName },
    },
  } = routeProps;

  if (!modelId) return <Typography variant="caption">Model not found.</Typography>;

  return (
    <Container maxWidth="xl">
      <GridWrapper size={{xs:12}}>
        <Breadcrumbs aria-label="breadcrumb">
          <HomeIconLink />
          <Link color="inherit" href={`/Search/${modelName}/${modelId}`}>
            Model
          </Link>
          <Link color="textPrimary" aria-current="page">
            Tasks
          </Link>
        </Breadcrumbs>
      </GridWrapper>
      <Grid2 container spacing={5} style={{ marginTop: 16 }}>
        <Grid2 size={{xs:12}}>
          <TaskMaintenanceSection taskId={taskId} modelId={modelId} />
        </Grid2>
         <Grid2 size={{xs:12}} style={{ marginTop: 16 }}>
          <TaskApplicabilitiesSection taskId={taskId} modelId={modelId} />
        </Grid2>
         <Grid2 size={{xs:12}} style={{ marginTop: 16 }}>
          <TaskImpactsSection taskId={taskId} modelId={modelId} />
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default PageTaskDetails;
