import React, { useState } from "react";
import { Grid2 } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import { selectorGetFunctionsByComponentId } from "../../redux/function/selectors";
import { IFunction } from "../../utilities/types/Function";
import FunctionRenderer from "./FunctionRenderer";
import FunctionalFailuresRenderer from "../functionalFailure/FunctionalFailuresRenderer";
import ModalFunctionUpdate from "./modals/ModalFunctionUpdate";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import FunctionIcon from "@mui/icons-material/FunctionsOutlined";
import ModelBuilderNewFunction from "./ModelBuilderNewFunction";
import { IComponent } from "../../utilities/types/Component";

type IFunctionsRendererProps = {
  canEdit: boolean;
  component: IComponent;
  loading: boolean;
};

const FunctionsRenderer = (props: IFunctionsRendererProps) => {
  const functions = useSelector((store: RootState) =>
    selectorGetFunctionsByComponentId(store, props.component.componentId)
  );

  return <FunctionsRendererDisplay {...props} functions={functions} />;
};

interface IFunctionsRendererDisplayProps extends IFunctionsRendererProps {
  functions: IFunction[];
}

function FunctionsRendererDisplay(props: IFunctionsRendererDisplayProps) {
  const { functions, canEdit } = props;
  const [loading] = useState(false);
  const [functionToUpdate, setFunctionToUpdate] = useState<IFunction>();

  function toggleUpdateFunction() {
    setFunctionToUpdate(undefined);
  }

  return (
    <AnimationWrapper>
      <Grid2 container>
       <Grid2 size={{xs:2 ,sm:1}}></Grid2>
        <Grid2 size={{xs:10, sm:11}}>
          <ModelBuilderNewFunction componentId={props.component.componentId} modelId={props.component.modelId} />
          {functions.map((componentFunction) => {
            return (
              <React.Fragment key={componentFunction.functionId}>
                <FunctionRenderer
                  functionId={componentFunction.functionId}
                  canEdit={canEdit}
                  onFunctionClick={() => setFunctionToUpdate(componentFunction)}
                />

                <FunctionalFailuresRenderer function={componentFunction} canEdit={canEdit} loading={loading} />
              </React.Fragment>
            );
          })}

          {functions.length === 0 ? (
            <div style={{ paddingBottom: 32 }}>
              <WidgetNoResultsPlaceholder
                text="No functions"
                description={canEdit ? "Functions can be added using the component menu above." : ""}
                icon={FunctionIcon}
                flip={true}
              />
            </div>
          ) : null}

          <LoaderAbsoluteCentred loading={loading} />
          {functionToUpdate !== undefined ? (
            <ModalFunctionUpdate
              open={Boolean(functionToUpdate)}
              componentFunction={functionToUpdate}
              onCancelCallback={toggleUpdateFunction}
              onCompleteCallback={toggleUpdateFunction}
              canEdit={canEdit}
            />
          ) : null}
        </Grid2>
      </Grid2>
    </AnimationWrapper>
  );
}

export default FunctionsRenderer;
