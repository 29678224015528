import React, { useState } from "react";
import { Grid2, Button, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { useDispatch } from "react-redux";
import { EnableComponentHierachy } from "../../redux/component/actions";
import { IModel } from "../../utilities/types/Model";
import { green } from "@mui/material/colors";
import AddCategoryIcon from "@mui/icons-material/AddCircleOutline";
import ModalComponentCreate from "./modals/ModalComponentCreate";
import ModalAiSuggestComponent from "./modals/ModalAiSuggestComponent";
import chatgptLogo from "../../assets/icons/chatgpt.svg";

const GridWrapper = styled(Grid2)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  alignItems: "center",
}));

const ButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "stretch",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
  },
  "& .addComponentButton": {
    color: green[500],
    fontWeight: 500,
    textTransform: "capitalize",
    transition: "background 200ms ease-out",
    width: "100%",
  },
  "& .aiButton": {
    flexShrink: 0,
  },
  "& .icon": {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
}));

type IComponentModelNewModelProps = {
  model?: IModel;
};

const ModelBuilderNewComponent = ({ model }: IComponentModelNewModelProps) => {
  const [showCreateComponentModal, setShowCreateComponentModal] = useState<boolean>(false);
  const [showAiSuggestModal, setShowAiSuggestModal] = useState<boolean>(false);
  const [loading] = useState(false);
  const dispatch = useDispatch();

  function toggleShowCreateComponentModal() {
    setShowCreateComponentModal(!showCreateComponentModal);
  }

  function onCompleteCallback() {
    dispatch(EnableComponentHierachy({ model: model as IModel }));
    setShowCreateComponentModal(!showCreateComponentModal);
  }

  function toggleShowAiSuggestModal() {
    setShowAiSuggestModal((prev) => !prev);
  }

  function onCompleteAiSuggestCallback() {
    toggleShowAiSuggestModal();
  }

  return (
    <GridWrapper container>
      <Grid2 size={{xs:12}}>
        <ButtonWrapper>
          <Button
            id="btn-create-component"
            variant="outlined"
            className="addComponentButton"
            onClick={toggleShowCreateComponentModal}
          >
            <AddCategoryIcon style={{ marginRight: "8px" }} /> Add a new component
          </Button>
          <Button id="btn-create-component" variant="outlined" className="aiButton" onClick={toggleShowAiSuggestModal}>
            <Avatar src={chatgptLogo} className="icon" />
          </Button>
        </ButtonWrapper>
      </Grid2>
      <ModalComponentCreate
        open={showCreateComponentModal}
        onCancelCallback={toggleShowCreateComponentModal}
        onCompleteCallback={onCompleteCallback}
        modelId={model?.modelId ?? ""}
      />
      <ModalAiSuggestComponent
        open={showAiSuggestModal}
        onCancelCallback={toggleShowAiSuggestModal}
        onCompleteCallback={onCompleteAiSuggestCallback}
        modelId={model?.modelId ?? ""}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </GridWrapper>
  );
};

export default ModelBuilderNewComponent;
