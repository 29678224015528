import {
  Button,
  Chip,
  Divider,
  Grid2,
  IconButton,
  InputBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
  styled,
  Link,
} from "@mui/material";
import HeaderColor from "@mui/material/colors/blueGrey";

import { Link as RouterLink } from "react-router-dom";

import PlaceholderIcon from "@mui/icons-material/AssessmentTwoTone";
import DownloadIcon from "@mui/icons-material/CloudDownloadOutlined";
import HeaderIcon from "@mui/icons-material/GetApp";
import CheckboxIconFilled from "@mui/icons-material/RadioButtonCheckedRounded";
import CheckboxIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchSearchOutputTypes } from "../../redux/outputType/actions";
import { selectorGetOutputTypeById } from "../../redux/outputType/selectors";
import { selectorGetUserPermissionIsAdmin } from "../../redux/userPermission/selectors";
import { GetUserId } from "../../utilities/ApiUtils";
import { ShowMessage, ToTitleCase } from "../../utilities/Helpers";
import { FileTypeEnum } from "../../utilities/types/File";
import { MessageTypeEnum } from "../../utilities/types/Message";
import { useFileSourceSingleHook } from "../file/Hooks";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import { GetOutputJobLinkByOutputId } from "../../routes/RouteLinkHelpers";
import { displayFormattedDate } from "../../utilities/formatter";
import {
  IOutputJob,
  OutputJobStatusEnum,
} from "../../utilities/types/OutputJob";
import { useFetchOutputJobReportPageHook } from "./Hooks";
import { sortOutputJobByCreatedDescending } from "../../redux/outputJob/selectors";
import { getStatusColor } from "../../utilities/Constants";

const DivWrapper = styled("div")(({ theme }) => ({
  minHeight: 500,
  "& .searchRoot": {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    boxShadow: "none",
    border: "1px solid #DEDEDE",
  },
  "& .creatorBar": {
    width: 5,
    height: "80%",
    marginLeft: theme.spacing(1),
    borderRadius: 28,
    background: HeaderColor[800],
  },
  "& .input": {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  "& .iconButton": {
    padding: 10,
  },
  "& .divider": {
    height: 28,
    margin: 4,
  },
  "& .rowsWrapper": {
    maxHeight: 400,
    overflowY: "auto",
  },
}));

type IOutputJobsEnquiryContainerProps = {
  canEdit: boolean;
};

const OutputJobsEnquiryContainer = ({
  canEdit,
}: IOutputJobsEnquiryContainerProps) => {
  const [searchStatus, setSearchStatusInternal] =
    useState<OutputJobStatusEnum>();
  const [searchText, setSearchTextInternal] = useState<string>();
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();

  const { outputJobs, fetching, morePages } = useFetchOutputJobReportPageHook({
    pageNumber: pageNumber,
    pageSize: 20,
    status: searchStatus,
    searchText,
  });

  function setSearchStatus(status?: OutputJobStatusEnum) {
    setSearchStatusInternal(status);
    setPageNumber(1);
  }

  function setSearchText(text?: string) {
    setSearchTextInternal(text);
    setPageNumber(1);
  }

  useEffect(() => {
    dispatch(fetchSearchOutputTypes({ pageNumber: 1, pageSize: 30 }));
  }, [dispatch]);

  return (
    <OutputJobEnquiryContainerDisplay
      canEdit={canEdit}
      loading={fetching}
      outputJobs={sortOutputJobByCreatedDescending(outputJobs as IOutputJob[])}
      onSetSearchStatus={setSearchStatus}
      onSetSearchText={setSearchText}
      setPageNumber={setPageNumber}
      pageNumber={pageNumber}
      hasMorePages={morePages}
    />
  );
};

type ISingleAssetOutputJobEnquiryContainerDisplayProps = {
  canEdit: boolean;
  outputJobs: IOutputJob[];
  loading: boolean;
  onSetSearchStatus(newStatus?: OutputJobStatusEnum): void;
  onSetSearchText(newText?: string): void;
  pageNumber: number;
  setPageNumber(page: number): void;
  hasMorePages: boolean;
};

const OutputJobEnquiryContainerDisplay = ({
  outputJobs,
  hasMorePages,
  setPageNumber,
  pageNumber,
  loading,
  onSetSearchStatus,
  onSetSearchText,
}: ISingleAssetOutputJobEnquiryContainerDisplayProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [statusFilter, setJobStatusToFilterBy] =
    useState<OutputJobStatusEnum>();
  const [searchText, setSearchTextFilter] = useState<string>();

  function updateJobStatusViaMenu(newStatus?: OutputJobStatusEnum) {
    setJobStatusToFilterBy(newStatus);
    setMenuAnchorEl(undefined);
    onSetSearchStatus(newStatus);
  }

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  function onSearchTextChangeHandler(event: any) {
    setSearchTextFilter(event.target.value);
  }

  function onSearchClick(e: any) {
    e.preventDefault();
    onSetSearchText(searchText);
    return false;
  }

  return (
    <WidgetSectionBase
      headerText="Outputs"
      subheaderText="Browse outputs."
      headerIcon={<HeaderIcon />}
      fullWidthHeader={true}
      style={{ height: "100%" }}
    >
      <DivWrapper>
        <Paper component="form" className="searchRoot" onSubmit={onSearchClick}>
          <Button
            className="iconButton"
            aria-label="menu"
            onClick={handleMenuClick}
            size="small"
          >
            {ToTitleCase(
              statusFilter
                ? OutputJobStatusEnum[statusFilter].toString()
                : "All"
            )}
          </Button>
          <Divider className="divider" orientation="vertical" />
          <Menu
            id="simple-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(undefined)}
          >
            <div style={{ paddingRight: 16, paddingLeft: 16 }}>
              <Typography variant="overline">Status Filters</Typography>
            </div>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(undefined)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === undefined} />
              </ListItemIcon>
              <ListItemText primary="None" />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() =>
                updateJobStatusViaMenu(OutputJobStatusEnum.Pending)
              }
            >
              <ListItemIcon>
                <StatusFilterIcon
                  checked={statusFilter === OutputJobStatusEnum.Pending}
                />
              </ListItemIcon>
              <ListItemText primary="Pending" />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() =>
                updateJobStatusViaMenu(OutputJobStatusEnum.InProgress)
              }
            >
              <ListItemIcon>
                <StatusFilterIcon
                  checked={statusFilter === OutputJobStatusEnum.InProgress}
                />
              </ListItemIcon>
              <ListItemText primary="In Progress" />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => updateJobStatusViaMenu(OutputJobStatusEnum.Failed)}
            >
              <ListItemIcon>
                <StatusFilterIcon
                  checked={statusFilter === OutputJobStatusEnum.Failed}
                />
              </ListItemIcon>
              <ListItemText primary="Failed" />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() =>
                updateJobStatusViaMenu(OutputJobStatusEnum.Complete)
              }
            >
              <ListItemIcon>
                <StatusFilterIcon
                  checked={statusFilter === OutputJobStatusEnum.Complete}
                />
              </ListItemIcon>
              <ListItemText primary="Complete" />
            </MenuItem>
          </Menu>
          <InputBase
            className="input"
            placeholder="Search Outputs"
            inputProps={{ "aria-label": "Search Outputs" }}
            onChange={onSearchTextChangeHandler}
            onSubmit={onSearchClick}
          />
          <Divider className="divider" orientation="vertical" />
          <IconButton
            className="iconButton"
            aria-label="search"
            onClick={onSearchClick}
          >
            <SearchIcon />
          </IconButton>
        </Paper>

        <div className="rowsWrapper">
          {loading ? (
            <LoaderAbsoluteCentred loading={loading} />
          ) : (
            <AnimationWrapper>
              <div>
                {outputJobs.length ? (
                  outputJobs.map((outputJob, i) => (
                    <OutputJobRow key={i} outputJob={outputJob} />
                  ))
                ) : (
                  <div style={{ marginTop: 24 }}>
                    <WidgetNoResultsPlaceholder
                      text="No reports"
                      flip={true}
                      icon={PlaceholderIcon}
                    />
                  </div>
                )}
              </div>
            </AnimationWrapper>
          )}
        </div>
      </DivWrapper>
      <div style={{ textAlign: "center" }}>
        <Grid2 container spacing={3}>
          <Grid2 size={{xs:6}}>
            <Button
              onClick={() => setPageNumber(pageNumber - 1)}
              variant="outlined"
              fullWidth={true}
              {...(pageNumber === 1 ? { disabled: true } : {})}
            >
              Previous
            </Button>
          </Grid2>
          <Grid2 size={{xs:6}}>
            <Button
              onClick={() => setPageNumber(pageNumber + 1)}
              variant="outlined"
              fullWidth={true}
              {...(!hasMorePages ? { disabled: true } : {})}
            >
              Next
            </Button>
          </Grid2>
        </Grid2>
        <LoaderAbsoluteCentred loading={loading} />
      </div>
    </WidgetSectionBase>
  );
};

function StatusFilterIcon({ checked }: { checked: boolean }) {
  return checked ? (
    <CheckboxIconFilled fontSize="small" />
  ) : (
    <CheckboxIcon fontSize="small" />
  );
}

const GridWrapper = styled(Grid2)(({ theme }) => ({
  border: "1px solid #EEE",
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  "& .image": {
    objectFit: "cover",
    filter: "sepia(20%)",
    height: "100%",
    width: "100%",
    borderRadius: `${theme.shape.borderRadius}px`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  "& .imageWrapper": {
    width: "100%",
    height: "80%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    position: "relative",
    maxWidth: "100%",
    left: 0,
    bottom: 0,
    top: 0,
    overflow: "hidden",
    borderRight: "1px solid rgba(0,0,0,0.1)",
  },
  "& .reportContent": {
    marginLeft: theme.spacing(1),
  },
  "& .reportActions": {
    paddingRight: theme.spacing(1),
  },
  "& .imageGrid": {
    display: "flex",
    alignItems: "center",
  },
}));

export function OutputJobRow({ outputJob }: { outputJob: IOutputJob }) {
  const isOutputJob = !!outputJob.outputJobId;
  const failed = outputJob.status === OutputJobStatusEnum.Failed;

  const outputType = useSelector((store: RootState) =>
    selectorGetOutputTypeById(store, outputJob.outputTypeId ?? "")
  );

  const output = outputType;

  const imageUrl = useFileSourceSingleHook({
    fileId: output?.mainImageId ?? "",
    fileType: FileTypeEnum.Image,
  });

  const currentUserId = GetUserId();
  const isAdmin = useSelector((state: RootState) =>
    selectorGetUserPermissionIsAdmin(state, currentUserId)
  );

  function addUrlToClipboardIfLocal() {
    if (outputJob.localPath) {
      navigator.clipboard.writeText(outputJob.preSignedUrl);
      ShowMessage("Path copied to clipboard", MessageTypeEnum.Success);
    }
  }

  return (
    <>
      <Link
        component={RouterLink}
        to={
          isOutputJob
            ? GetOutputJobLinkByOutputId(outputJob.outputJobId as string)
            : ""
        }
        style={{ textDecoration: "none" }}
      >
        <GridWrapper container>
          <Grid2 size={{xs:3}} className="imageGrid">
            <div className="imageWrapper">
              <div
                className="image"
                style={{ backgroundImage: `url("${imageUrl}")` }}
              />
            </div>
            {isAdmin && outputJob.createdBy === currentUserId ? (
              <div className="creatorBar"></div>
            ) : null}
          </Grid2>
          <Grid2 size={{xs:8}}>
            <div className="reportContent">
              <Typography variant="body2">
                {isOutputJob ? outputJob.name : output?.name} -{" "}
                {displayFormattedDate(outputJob.created, "")}
              </Typography>

              <Chip
                label={OutputJobStatusEnum[outputJob.status]}
                variant="outlined"
                size="small"
                sx={{
                  transform: "scale(0.85)",
                  marginLeft: "-4px",
                  backgroundColor: getStatusColor(outputJob.status),
                  color: (theme) => theme.palette.common.white,
                }}
              />
              <Chip
                label={isOutputJob ? "Output Job" : ""}
                variant="outlined"
                size="small"
                style={{
                  transform: "scale(0.85)",
                  marginLeft: -4,
                }}
              />
            </div>
          </Grid2>
          <Grid2
            size={{xs:1}}
            className="reportActions"
            onClick={(event) => event.stopPropagation()}
          >
            {outputJob.status === OutputJobStatusEnum.Complete ? (
              <a
                href={outputJob.preSignedUrl}
                onClick={addUrlToClipboardIfLocal}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton>
                  <DownloadIcon />
                </IconButton>
              </a>
            ) : null}
          </Grid2>
        </GridWrapper>
      </Link>
    </>
  );
}

export default OutputJobsEnquiryContainer;
