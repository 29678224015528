import React from "react";
import {
  TextField,
  Grid2,
  Divider,
  Button,
  FormControlLabel,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { fetchCreateFeedback } from "../../../redux/feedback/actions";
import { grey } from "@mui/material/colors";
import { IFeedback, FeedbackTypeEnum } from "../../../utilities/types/Feedback";

interface FormValues {
  type: FeedbackTypeEnum;
  text: string;
  pleaseContact: boolean;
}

interface FormProps {
  onCompleteCallback(feedback?: IFeedback): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IFeedback, AnyAction>;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
        <Grid2 size={{xs:12}}>
          <FormControl fullWidth={true}>
            <InputLabel id="lbl-fb-type" variant="standard">
              FeedbackType
            </InputLabel>
            <Select
              labelId="lbl-fb-type"
              variant="standard"
              {...getFormikFieldProps(props, "type", "Type")}
              onChange={(e) => {
                props.setFieldValue("type", e.target.value);
              }}
              style={{ display: "block" }}
              id="txt-fb-type"
            >
              <MenuItem value={FeedbackTypeEnum.Bug}>Bug(s)</MenuItem>
              <MenuItem value={FeedbackTypeEnum.FeatureRequest}>Feature Request</MenuItem>
              <MenuItem value={FeedbackTypeEnum.UIUX}>User Interface User Experience</MenuItem>
              <MenuItem value={FeedbackTypeEnum.SolverRequest}>Solver Job Request</MenuItem>
              <MenuItem value={FeedbackTypeEnum.OutputRequest}>Output Request</MenuItem>
              <MenuItem value={FeedbackTypeEnum.Contributor}>Contributor</MenuItem>
              <MenuItem value={FeedbackTypeEnum.Other}>Other</MenuItem>
            </Select>
          </FormControl>
        </Grid2>

        <Grid2 size={{xs:12}}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "text", "Comments")}
            fullWidth
            margin="normal"
            multiline={true}
            variant="standard"
            rows={5}
          />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: grey[800] }}
                checked={props.values.pleaseContact}
                onChange={() => props.setFieldValue("pleaseContact", !props.values.pleaseContact)}
                value={true}
                color="primary"
              />
            }
            label="Please contact me back!"
          />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <Divider light={true} />
        </Grid2>

        <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Submit
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormFeedbackCreate = withFormik<FormProps, FormValues>({
  validationSchema: Yup.object().shape({
    text: Yup.string()
      .label("Label")
      .min(1, "Please input 1 character or more")
      .max(5000, "Please input 5000 characters or less")
      .required("Please provide some text."),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch } = props;

    // Map dispatch via props
    var createdFeedback = await dispatch(
      fetchCreateFeedback({
        ...values,
      })
    );

    setSubmitting(false);

    if (createdFeedback) onCompleteCallback(createdFeedback);
  },
})(InnerForm);

export default FormFeedbackCreate;
