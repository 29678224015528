import React from "react";
import { Breadcrumbs, Grid2 as MuiGrid, Link, styled, Typography } from "@mui/material";

import { Container } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import UserDetailsManagementWidget from "../../userDetails/UserDetailsManagementWidget";
import AdminManageBucketsWidget from "../AdminManageBucketsWidget";
import AdminImportsWidget from "../AdminImportsWidget";
import AdminManageFeedbackWidget from "../AdminManageFeedbackWidget";
import AdminManageIdentifiersWidget from "../AdminManageIdentifiersWidget";
import AdminManageSolversWidget from "../AdminManageSolversWidget";
import AdminManageOutputsWidget from "../AdminManageOutputsWidget";
import AnimationWrapper from "../../generic/animations/AnimationWrapper";
import { GetAdminPage } from "../../../routes/RouteLinkHelpers";
import AdminManageDiscussionsWidget from "../AdminManageDiscussionsWidget";
import AdminUserRolesManagementWidget from "../AdminUserRolesManagementWidget";
import { HomeIconLink } from "../../generic/HomeIconLink";
import FileUploadWidget from "../../file/FileUploadWidget";
import AdminFileListWidget from "../../file/AdminFileListWidget";

const CustomGrid = styled(MuiGrid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const PageAdminDashboard = (routeProps: RouteComponentProps<any>) => {
  return (
    <Container maxWidth="xl">
      <AnimationWrapper>
        <CustomGrid  size={{xs:12}}>
          <Breadcrumbs aria-label="breadcrumb">
            <HomeIconLink />
            <Link color="inherit" href={GetAdminPage()}>
              Admin
            </Link>
          </Breadcrumbs>
        </CustomGrid>
      </AnimationWrapper>
      <CustomGrid container spacing={4} style={{ marginTop: 16 }} alignItems="stretch" direction="row">
        <CustomGrid  size={{xs:12,sm:6 ,md:4}} >
          <AdminImportsWidget />
        </CustomGrid>
        <CustomGrid  size={{xs:12,sm:6 ,md:4}} >
          <UserDetailsManagementWidget />
        </CustomGrid>
        <CustomGrid  size={{xs:12,sm:6 ,md:4}} >
          <AdminManageBucketsWidget />
        </CustomGrid>
        <CustomGrid  size={{xs:12,sm:6 ,md:4}} >
          <AdminManageFeedbackWidget />
        </CustomGrid>
        <CustomGrid  size={{xs:12,sm:6 ,md:4}} >
          <AdminManageIdentifiersWidget />
        </CustomGrid>
        <CustomGrid  size={{xs:12,sm:6 ,md:4}} >
          <AdminManageSolversWidget />
        </CustomGrid>
        <CustomGrid  size={{xs:12,sm:6 ,md:4}} >
          <AdminManageOutputsWidget />
        </CustomGrid>
        <CustomGrid  size={{xs:12,sm:6 ,md:4}} >
          <AdminManageDiscussionsWidget />
        </CustomGrid>
        <CustomGrid  size={{xs:12,sm:6 ,md:4}} >
          <AdminUserRolesManagementWidget />
        </CustomGrid>
      </CustomGrid>
      <Typography variant="subtitle2" mt={4}>
        Tools
      </Typography>
      <CustomGrid container spacing={4} alignItems="stretch" direction="row">
        <CustomGrid  size={{xs:12,sm:6 ,md:4}} >
          <FileUploadWidget />
        </CustomGrid>
        <CustomGrid  size={{xs:12,sm:6 ,md:4}} >
          <AdminFileListWidget />
        </CustomGrid>
      </CustomGrid>
    </Container>
  );
};

export default PageAdminDashboard;
