import React, { useState } from "react";
import { TextField, Grid2, Divider, Button, FormControl, IconButton } from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { IEvent } from "../../../utilities/types/Event";
import { fetchUpdateEvent, fetchDeleteEvent } from "../../../redux/event/actions";
import { selectorGetTasksByModelId } from "../../../redux/task/selectors";
import { selectorGetCostBundlesByModelId } from "../../../redux/costBundle/selectors";
import AddIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ModalCostBundleCreate from "../../costBundle/modals/ModalCostBundleCreate";
import ModalTaskCreate from "../../task/modals/ModalTaskCreate";
import { Autocomplete } from "@mui/lab";
import { ICostBundle } from "../../../utilities/types/CostBundle";
import { ITask } from "../../../utilities/types/Task";
import { selectorGetUserPermissionOfType } from "../../../redux/userPermission/selectors";
import { UserPermissionTypeEnum } from "../../../utilities/types/UserPermission";
import { GetUserId } from "../../../utilities/ApiUtils";

interface FormValues {
  name: string;
  description: string;
  costBundleId: string;
  taskId: string;
}

interface FormProps {
  event: IEvent;
  costBundleObj?: ICostBundle;
  taskObj?: ITask;
  onCompleteCallback(event?: IEvent): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IEvent, AnyAction>;
  canEdit: boolean;
}

const FormEventUpdate = (formProps: FormProps) => {
  const {
    onCancelCallback,
    event: { eventId, modelId },
    canEdit,
  } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);
  const tasks = useSelector((store: RootState) => selectorGetTasksByModelId(store, modelId));
  const costBundles = useSelector((store: RootState) => selectorGetCostBundlesByModelId(store, modelId));
  const [showCostBundleCreate, setShowCostBundleCreate] = useState(false);
  const [showTaskCreate, setShowTaskCreate] = useState(false);
  const hasTaskCreatePermissions = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesTaskCreate)
  );
  const hasCostBundleCreatePermissions = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ServicesCostBundleCreate)
  );
  function toggleShowCostBundleCreate() {
    setShowCostBundleCreate(!showCostBundleCreate);
  }

  function toggleShowTaskCreate() {
    setShowTaskCreate(!showTaskCreate);
  }

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch, event } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var updatedEvent = await dispatch(
      fetchUpdateEvent({
        ...values,
        eventId: event.eventId,
      })
    );

    setSubmitting(false);
    if (updatedEvent) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedEvent);
    }
  };

  return (
    <Formik
      initialValues={{
        ...formProps.event,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteEvent({ eventId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={eventId} name="eventId" />
            <Grid2 container spacing={2}>
              <Grid2 size={{xs:12}}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "name", "Name")}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid2>

              <Grid2 size={{xs:12}}>
                <TextField
                  onChange={props.handleChange}
                  multiline
                  variant="standard"
                  rows={3}
                  fullWidth
                  margin="normal"
                  {...getFormikFieldProps(props, "description", "Description")}
                />
              </Grid2>

              <Grid2 size={{xs:10}}>
                <FormControl fullWidth={true}>
                  <Autocomplete
                    {...getFormikFieldProps(props, "costBundleObj", "Cost Bundle")}
                    style={{ display: "block" }}
                    options={costBundles}
                    defaultValue={costBundles.find((element) => {
                      return element.costBundleId === props.values.costBundleId;
                    })}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) => {
                      props.setFieldValue("costBundleId", value ? value.costBundleId : "");
                      props.setFieldValue("costBundleObj", value);
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} label="Cost Bundle" />}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={{xs:2}}>
                {hasCostBundleCreatePermissions ? (
                  <IconButton onClick={toggleShowCostBundleCreate} style={{ marginTop: 8 }}>
                    <AddIcon />
                  </IconButton>
                ) : null}
              </Grid2>

              <Grid2 size={{xs:10}}>
                <FormControl fullWidth={true}>
                  <Autocomplete
                    {...getFormikFieldProps(props, "taskObj", "Task")}
                    style={{ display: "block" }}
                    options={tasks}
                    defaultValue={tasks.find((element) => {
                      return element.taskId === props.values.taskId;
                    })}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) => {
                      props.setFieldValue("taskId", value ? value.taskId : "");
                      props.setFieldValue("taskObj", value);
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} label="Task" />}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={{xs:2}}>
                {hasTaskCreatePermissions ? (
                  <IconButton onClick={toggleShowTaskCreate} style={{ marginTop: 8 }}>
                    <AddIcon />
                  </IconButton>
                ) : null}
              </Grid2>
              <Grid2 size={{xs:12}}>
                <Divider light={true} />
              </Grid2>
              <Grid2 size={{xs:12}}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  {canEdit ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{ flexBasis: "33%" }}
                      onClick={toggleDeleteConfirmation}
                    >
                      Delete
                    </Button>
                  ) : null}

                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    {canEdit ? (
                      <Button
                        type="submit"
                        disabled={submitting}
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: 16 }}
                      >
                        Update
                      </Button>
                    ) : null}
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting || deleting} />
                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete event"
                  subtitle="Confirm event delete"
                  description="Are you sure that you'd like to remove this event?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteHandler}
                  confirmButtonText="Delete"
                />
                {showCostBundleCreate && (
                  <ModalCostBundleCreate
                    open={showCostBundleCreate}
                    onCancelCallback={toggleShowCostBundleCreate}
                    modelId={modelId}
                    onCompleteCallback={toggleShowCostBundleCreate}
                  />
                )}
                {showTaskCreate && (
                  <ModalTaskCreate
                    open={showTaskCreate}
                    onCancelCallback={toggleShowTaskCreate}
                    modelId={modelId}
                    onCompleteCallback={toggleShowTaskCreate}
                  />
                )}
              </Grid2>
            </Grid2>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          name: Yup.string()
            .label("Label")
            .min(3, "Please input 3 characters or more")
            .max(100, "Please input 100 characters or less")
            .required("Please provide a name"),
          description: Yup.string().label("Description").max(500, "Please input 500 characters or less"),
          costBundleId: Yup.string().label("Cost bundle").required(),
          taskId: Yup.string().label("Task").required(),
        });
      }}
    />
  );
};

export default FormEventUpdate;
