import React from "react";
import { Grid2, Button } from "@mui/material";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { ISolverDetails } from "../../../utilities/types/SolverDetails";
import { fetchCreateSolverDetails } from "../../../redux/solverDetails/actions";

interface FormValues {
  solverId: string;
  overview: string;
  technicalInfo: string;
  useCases: string;
  references: string;
}

interface FormProps {
  solverId: string;
  onCompleteCallback(solverDetails?: ISolverDetails): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ISolverDetails, AnyAction>;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
        <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormSolverCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    solverId: "",
    overview: "",
    technicalInfo: "",
    useCases: "",
    references: "",
  }),
  validationSchema: Yup.object().shape({}),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch } = props;
    values.solverId = props.solverId;
    // Map dispatch via props
    var createdSolver = await dispatch(
      fetchCreateSolverDetails({
        ...values,
      })
    );

    setSubmitting(false);
    onCompleteCallback(createdSolver);
  },
})(InnerForm);

export default FormSolverCreate;
