import { Button, Divider, FormControl, Grid2, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";
import { RootState } from "../../../redux";
import { fetchDeleteAssetInputField, fetchUpdateAssetInputField } from "../../../redux/assetInputField/actions";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { useSplitDateTime } from "../../../utilities/inputFields/dateTimeFieldUtils";
import {
  DEFAULT_DECIMAL_PLACES,
  MAX_DECIMAL_PLACES,
  roundToDecimalPlaces,
} from "../../../utilities/inputFields/numberFieldUtils";
import { yumInputFieldsWithDefaultDateTime } from "../../../utilities/inputFields/yumUitls";
import { AssetInputFieldTypeEnum as FieldType, IAssetInputField } from "../../../utilities/types/AssetInputField";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import AssetInputFieldListValueMaintenanceSection from "../../assetInputFieldListValue/AssetInputFieldListValueMaintenanceSection";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import AssetInputFieldDisplayDateTime from "../AssetInputFieldDisplayDateTime";
import { selectorGetAssetInputFieldCategoriesByModelId } from "../../../redux/assetInputFieldCategory/selectors";

interface FormValues {
  label: string;
  description: string;
  defaultValue?: number;
  defaultString?: string;
  minValue?: number;
  maxValue?: number;
  decimalPlaces?: number;
  orderNumber: number;
  type: FieldType;
  assetInputFieldCategoryId: string;
}

interface FormProps {
  assetInputField: IAssetInputField;
  onCompleteCallback(assetInputField?: IAssetInputField): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IAssetInputField, AnyAction>;
  canEdit: boolean;
}

const FormAssetInputFieldUpdate = (formProps: FormProps) => {
  const {
    onCancelCallback,
    assetInputField: { assetInputFieldId, modelId },
  } = formProps;

  const availableAssetInputFieldCategories = useSelector((store: RootState) =>
    selectorGetAssetInputFieldCategoriesByModelId(store, modelId)
  );
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const onSubmit = async (formValues: FormValues) => {
    const { onCompleteCallback, dispatch, assetInputField } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    setSubmitting(true);

    // Map dispatch via props
    var updatedAssetInputField = await dispatch(
      fetchUpdateAssetInputField({
        ...formValues,
        assetInputFieldId: assetInputField.assetInputFieldId,
      })
    );

    setSubmitting(false);
    if (updatedAssetInputField) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedAssetInputField);
    }
  };

  const [, , dateTimeValue] = useSplitDateTime(formProps.assetInputField.defaultDateTime);

  return (
    <Formik
      initialValues={{
        ...formProps.assetInputField,
        defaultDateTime: dateTimeValue,
        defaultString: formProps.assetInputField.defaultString ?? undefined,
        decimalPlaces: formProps.assetInputField.decimalPlaces ?? DEFAULT_DECIMAL_PLACES,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteAssetInputField({ assetInputFieldId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        const decimalPlaces = props.values.decimalPlaces;

        /** Handle numeric field updates that should limit their decimal places to the field value. */
        function handleDecimalFieldChange(field: keyof IAssetInputField) {
          return (e: React.ChangeEvent<any>) => {
            const newValue = roundToDecimalPlaces(parseFloat(e.currentTarget.value as string), decimalPlaces);
            props.setFieldValue(field, newValue);
          };
        }

        /** Handle the 'decimal places' field updates and limit dependent fields to the new value */
        function handleDecimalPlaceChange(...dependentFields: (keyof IAssetInputField)[]) {
          return (e: React.ChangeEvent<any>) => {
            const newValue = e.currentTarget.value as number | undefined;
            if (newValue) {
              const newDecimalPlaces = Math.round(e.currentTarget.value); // force integer
              props.setFieldValue("decimalPlaces", newDecimalPlaces);

              // Round all dependent fields to the new decimal places
              for (const field of dependentFields) {
                const newValue = roundToDecimalPlaces(parseFloat(props.values[field] as string), newDecimalPlaces);
                props.setFieldValue(field, newValue);
              }
            } else {
              props.setFieldValue("decimalPlaces", newValue); // allow clearing the field
            }
          };
        }

        // Removed 'helperText' from props so React doesn't complain
        const { helperText, ...typeProps } = getFormikFieldProps(props, "type", "Type");

        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={assetInputFieldId} name="assetInputFieldId" />
            <Grid2 container spacing={2}>
              <Grid2   size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "label", "Code Label")}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid2>

              <Grid2   size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "orderNumber", "Order Number")}
                />
              </Grid2>

              <Grid2  size={{xs:12}} >
                <TextField
                  onChange={props.handleChange}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "description", "Prompt")}
                />
              </Grid2>
              <Grid2   size={{xs:12}}>
                <FormControl fullWidth={true}>
                  <InputLabel id="lbl-model-type" variant="standard">
                    Category
                  </InputLabel>
                  <Select
                    labelId="lbl-model-assetInputFieldCategoryId"
                    {...getFormikFieldProps(props, "assetInputFieldCategoryId", "Category")}
                    onChange={(e) => {
                      props.setFieldValue("assetInputFieldCategoryId", e.target.value);
                    }}
                    style={{ display: "block" }}
                    id="txt-model-assetInputFieldCategoryId"
                    variant="standard"
                  >
                    {availableAssetInputFieldCategories.map((category) => (
                      <MenuItem key={category.assetInputFieldCategoryId} value={category.assetInputFieldCategoryId}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>

              <Grid2  size={{xs:12}}>
                <FormControl fullWidth={true}>
                  <InputLabel id="lbl-model-type" variant="standard">
                    Type
                  </InputLabel>
                  <Select
                    labelId="lbl-model-type"
                    {...typeProps}
                    onChange={(e) => {
                      props.setFieldValue("type", e.target.value);
                    }}
                    style={{ display: "block" }}
                    id="txt-model-type"
                    variant="standard"
                  >
                    <MenuItem value={FieldType.Number}>Number</MenuItem>
                    <MenuItem value={FieldType.String}>Text</MenuItem>
                    <MenuItem value={FieldType.DateTime}>Date</MenuItem>
                    <MenuItem value={FieldType.CustomEnumeration}>Custom Enumeration</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>

              {props.values.type === FieldType.Number && (
                <>
                  <Grid2  size={{xs:12, sm:6}}>
                    <TextField
                      onChange={handleDecimalFieldChange("minValue")}
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="standard"
                      //inputProps={{ min: 0, max: MAX_VALUE, step: "any" }}
                      {...getFormikFieldProps(props, "minValue", "Min Value")}
                    />
                  </Grid2>

                  <Grid2  size={{xs:12, sm:6}}>
                    <TextField
                      onChange={handleDecimalFieldChange("maxValue")}
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="standard"
                      //inputProps={{ min: 0, max: MAX_VALUE, step: "any" }}
                      {...getFormikFieldProps(props, "maxValue", "Max Value")}
                    />
                  </Grid2>

                  <Grid2  size={{xs:12, sm:6}}>
                    <TextField
                      onChange={handleDecimalPlaceChange("minValue", "maxValue", "defaultValue")}
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="standard"
                      inputProps={{ min: 0, max: MAX_DECIMAL_PLACES, step: "0" }}
                      {...getFormikFieldProps(props, "decimalPlaces", "Decimal Places")}
                    />
                  </Grid2>

                  <Grid2  size={{xs:12, sm:6}}>
                    <TextField
                      onChange={handleDecimalFieldChange("defaultValue")}
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="standard"
                      //inputProps={{ min: 0, max: MAX_VALUE, step: "any" }}
                      {...getFormikFieldProps(props, "defaultValue", "Default Value")}
                    />
                  </Grid2>
                </>
              )}

              {props.values.type === FieldType.CustomEnumeration && (
                <>
                  <Grid2  size={{xs:12}}>
                    <AssetInputFieldListValueMaintenanceSection canEdit={true} assetInputFieldId={assetInputFieldId} />
                  </Grid2>
                </>
              )}

              {props.values.type === FieldType.String && (
                <>
                  <Grid2  size={{xs:12, sm:6}}>
                    <TextField
                      onChange={props.handleChange}
                      {...getFormikFieldProps(props, "defaultString", "Default Text")}
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="standard"
                    />
                  </Grid2>
                </>
              )}

              {props.values.type === FieldType.DateTime && (
                <Grid2  size={{xs:12}}>
                  <AssetInputFieldDisplayDateTime
                    label="Default Date Time"
                    assetInputField={formProps.assetInputField}
                    onValueChangeCallback={(_, { valueDateTime }) =>
                      props.setFieldValue("defaultDateTime", valueDateTime)
                    }
                    error={props.errors.defaultDateTime}
                  />
                </Grid2>
              )}

              <Grid2 size={{xs:12}}>
                <Divider light={true} />
              </Grid2>
              <Grid2 size={{xs:12}}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ flexBasis: "33%" }}
                    onClick={toggleDeleteConfirmation}
                  >
                    Delete
                  </Button>
                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      type="submit"
                      disabled={submitting}
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: 16 }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting || deleting} />
                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete confirmation"
                  subtitle="Confirm category delete"
                  description="Are you sure that you'd like to remove this category?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteHandler}
                  confirmButtonText="Delete"
                />
              </Grid2>
            </Grid2>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          ...yumInputFieldsWithDefaultDateTime,
          label: Yup.string()
            .label("Label")
            .min(3, "Please input 3 characters or more")
            .max(200, "Please input 200 characters or less")
            .required("Please provide a label"),
          description: Yup.string().label("Description").max(500, "Please input 500 characters or less"),
          orderNumber: Yup.number()
            .label("Max Value")
            .min(0, "Zero or more")
            .required("Please provide an order number")
            .max(999, "Less than 999"),
        });
      }}
    />
  );
};

export default FormAssetInputFieldUpdate;
