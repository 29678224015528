import React, { lazy, useState } from "react";
import { Grid2 } from "@mui/material";

import { RouteComponentProps } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState } from "../../redux";

import { selectorGetOutputJobById } from "../../redux/outputJob/selectors";
import JobDetailPageWrapper from "../generic/JobDetailPageWrapper";
import OutputJobCollapseCard from "./OutputJobCollapseCard";
import SuspenseWrapper from "../generic/SuspenseWrapper";

const LazyPrerequisiteJobsEnquiryWidget = lazy(() => import("../generic/widgets/PrerequisiteJobsEnquiryWidget"));
interface IPageOutputJobDetailsProps {
  routeProps: RouteComponentProps<any>;
  outputJobId: string;
  loading: boolean;
  setReFetchOutputJob: React.Dispatch<React.SetStateAction<boolean>>;
}

const PageOutputJobDetail = ({
  outputJobId,
  loading,
  setReFetchOutputJob,
}: IPageOutputJobDetailsProps) => {
  const [showCollapse, setShowCollapse] = useState<boolean>(false);
  const outputJob = useSelector((store: RootState) =>
    selectorGetOutputJobById(store, outputJobId)
  );
  return (
    <JobDetailPageWrapper jobLabel="Output Jobs" jobDetailsLabel="Output Job Details" loading={loading}>
      <OutputJobCollapseCard
        outputJob={outputJob}
        showCollapse={showCollapse}
        setShowCollapse={setShowCollapse}
        setReFetchOutputJob={setReFetchOutputJob}
      >
        <Grid2 container rowSpacing={4} columnSpacing={2} style={{ padding: 16 }}>
          {outputJob && (
            <Grid2 size={{xs:12,sm:12 ,md:12, lg:6}}>
              <SuspenseWrapper>
                <LazyPrerequisiteJobsEnquiryWidget prerequisites={outputJob.prerequisites} />
              </SuspenseWrapper>
            </Grid2>
          )}
        </Grid2>
      </OutputJobCollapseCard>
    </JobDetailPageWrapper>
  );
};

export default PageOutputJobDetail;
