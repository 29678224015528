import { Divider, Grid2, IconButton, InputBase, Paper as MuiPaper, PaperProps, styled } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { ISolver } from "../../utilities/types/Solver";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { IUseFetchSolversPageHookProps, useFetchSolversPageHook } from "./Hooks";

const Paper = styled(MuiPaper)<PaperProps>(({ theme }) => ({
  padding: "2px 4px",
  display: "flex",
  alignItems: "center",
  boxShadow: "none",
  border: "1px solid #DEDEDE",
  "& .input": {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  "& .iconButton": {
    padding: 10,
  },
  "& .divider": {
    height: 28,
    margin: 4,
  },
}));

interface ISolverSearchWidgetProps {
  setReturnedResultSet(models: ISolver[], morePages: boolean): void;
}

const SolverSearchWidget = ({ setReturnedResultSet }: ISolverSearchWidgetProps) => {
  const [searchText, setSearchTextInternal] = useState<string>();
  const [currentSearchValues, setCurrentSearchValues] = useState<IUseFetchSolversPageHookProps>({
    pageNumber: 1,
    pageSize: 50,
    minPageNumberToFetch: 1,
    text: searchText,
  });
  function setSearchText(text?: string) {
    setSearchTextInternal(text);
  }
  // Fetch first page of models
  const {
    fetching: fetchingSolvers,
    lastResultSet,
    morePages,
  } = useFetchSolversPageHook({
    ...currentSearchValues,
  });

  const GetNextPage = (newSearchValues: IUseFetchSolversPageHookProps) => {
    setCurrentSearchValues(newSearchValues);
  };

  useEffect(() => {
    setReturnedResultSet(lastResultSet, morePages);
  }, [lastResultSet, setReturnedResultSet, morePages]);

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={{xs:12}}>
        <SolverSearchWidgetCriteriaDisplay
          loading={fetchingSolvers}
          currentSearchValues={currentSearchValues}
          setCurrentSearchValues={GetNextPage}
          onSetSearchText={(newText: string) => setSearchText(newText)}
        />
      </Grid2>
      <LoaderAbsoluteCentred loading={fetchingSolvers} />
    </Grid2>
  );
};

interface ISolverSearchWidgetCriteriaDisplay {
  currentSearchValues: IUseFetchSolversPageHookProps;
  setCurrentSearchValues(props: IUseFetchSolversPageHookProps): void;
  onSetSearchText(newText?: string): void;
  loading: boolean;
}

function SolverSearchWidgetCriteriaDisplay({
  currentSearchValues,
  setCurrentSearchValues,
  onSetSearchText,
  loading,
}: ISolverSearchWidgetCriteriaDisplay) {
  const [searchText, setSearchTextFilter] = useState<string>();

  function searchWithFilters() {
    setCurrentSearchValues({ ...currentSearchValues, text: searchText });
  }

  function setSearchText(text?: string) {
    setSearchTextFilter(text);
  }

  function onSearchTextChangeHandler(event: any) {
    setSearchTextFilter(event.target.value);
  }

  function onSearchClick(e: any) {
    e.preventDefault();
    searchWithFilters();
    setSearchText(searchText);
    onSetSearchText(searchText);
    return false;
  }

  return (
    <Paper component="form" onSubmit={onSearchClick}>
      <InputBase
        className="input"
        placeholder="e.g. Strategy Optimisation"
        inputProps={{ "aria-label": "Search Solvers" }}
        onChange={onSearchTextChangeHandler}
        type="text"
        onSubmit={onSearchClick}
      />
      <Divider className="divider" orientation="vertical" />
      <IconButton
        className="iconButton"
        aria-label="search"
        onClick={onSearchClick}
        disabled={loading}
        id="btn-models-search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

export default SolverSearchWidget;
