import React from "react";
import { TextField, Grid2, Divider, Button } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { IUserRole } from "../../../utilities/types/UserRole";
import { fetchCreateUserRole } from "../../../redux/userRole/actions";

interface FormValues {
  userRoleDescription: string;
}

interface FormProps {
  onCompleteCallback(userRole?: IUserRole): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IUserRole, AnyAction>;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
        <Grid2 size={{xs:12}}>
          <TextField
            variant="standard"
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "userRoleDescription", "Role Description")}
            fullWidth
            margin="normal"
            placeholder="E.g. Customer"
          />
        </Grid2>
        <Grid2 size={{xs:12}}>
          <Divider light={true} />
        </Grid2>
        <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormComponentCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    userRoleDescription: "",
  }),
  validationSchema: Yup.object().shape({
    userRoleDescription: Yup.string()
      .label("Label")
      .min(3, "Please input 3 characters or more")
      .max(50, "Please input 50 characters or less")
      .required("Please provide a description"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch } = props;

    // Map dispatch via props
    var createdUserRole = await dispatch(
      fetchCreateUserRole({
        ...values,
      })
    );

    setSubmitting(false);

    if (createdUserRole) onCompleteCallback(createdUserRole);
  },
})(InnerForm);

export default FormComponentCreate;
