import { Breadcrumbs, Container, Grid2, Link, Paper, Typography, styled } from "@mui/material";

import HeaderColor from "@mui/material/colors/blueGrey";
import { default as InputIcon, default as UserNotFoundIcon } from "@mui/icons-material/PersonSharp";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { RootState } from "../../../redux";
import { fetchSearchUserDetails } from "../../../redux/userDetail/actions";
import { selectorGetUserDetailById } from "../../../redux/userDetail/selectors";
import { GetUserId } from "../../../utilities/ApiUtils";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import { FileTypeEnum } from "../../../utilities/types/File";
import { IUserDetail } from "../../../utilities/types/UserDetail";
import FileUploadIcon from "../../file/FileUploadIcon";
import { useFileSourceSingleHook } from "../../file/Hooks";
import AnimationWrapper from "../../generic/animations/AnimationWrapper";
import WidgetSectionBase from "../../generic/widgets/summaries/WidgetSectionBase";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import FormUserDetailUpdate from "../forms/FormUserDetailUpdate";

import { selectorGetUserPermissionIsAdmin } from "../../../redux/userPermission/selectors";
import { GetAdminPage, GetAdminUsersPage } from "../../../routes/RouteLinkHelpers";
import { HomeIconLink } from "../../generic/HomeIconLink";

const DivWrapper = styled("div")(({ theme }) => ({
  "& .breadcrumbWrapper": {
    marginTop: `${theme.spacing(2)} !important`,
  },
  "& .avatarImage": {
    width: 125,
    height: 125,
    borderRadius: "50%",
    marginTop: -theme.spacing(3),
    objectFit: "cover",
    objectPosition: "50% 50%",
    boxShadow: theme.shadows[4],
    backgroundColor: "#EEE",
  },
  "& .detailsDisplayWrapper": {
    height: "100%",
    marginTop: 10,
    padding: theme.spacing(3),
    paddingTop: 0,
  },
}));

interface IPageUserDetailsProps {
  routeProps: RouteComponentProps<any>;
  userId?: string;
}

const PageUserDetails = ({ userId }: IPageUserDetailsProps) => {
  const userDetail = useSelector((store: RootState) => selectorGetUserDetailById(store, userId || ""));

  const currentUserId = GetUserId();
  const canEdit = currentUserId && currentUserId === userDetail?.userDetailId ? true : false;
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state, currentUserId));

  const useAdminNav = isAdmin && !canEdit; // Admins who are not on the 'manage user' screen

  return (
    <DivWrapper>
      <Container maxWidth="xl">
        <AnimationWrapper>
          <Grid2 size={{xs:12}}  className="breadcrumbWrapper">
            <Breadcrumbs aria-label="breadcrumb">
              <HomeIconLink />
              {useAdminNav && (
                <NavLink color="inherit" to={GetAdminPage()} style={{ textDecoration: "none", color: "inherit" }}>
                  Admin
                </NavLink>
              )}
              {useAdminNav && (
                <NavLink color="inherit" to={GetAdminUsersPage()} style={{ textDecoration: "none", color: "inherit" }}>
                  Users
                </NavLink>
              )}
              <Link color="textPrimary" aria-current="page" style={{ textDecoration: "none" }}>
                {userDetail ? userDetail.displayName : "Me"}
              </Link>
            </Breadcrumbs>
          </Grid2>
        </AnimationWrapper>
        <AnimationWrapper>
          <Grid2 container spacing={5} style={{ marginTop: 16 }}>
            {userDetail ? (
              <Grid2 size={{xs:12, md:7}}>
                <UserDetailSectionForm canEdit={canEdit} userDetail={userDetail} />
              </Grid2>
            ) : null}
            {!userDetail ? (
              <Grid2 size={{xs:12}}>
                <WidgetNoResultsPlaceholder text="User not found" fade={true} icon={UserNotFoundIcon} />
              </Grid2>
            ) : (
              <Grid2 size={{xs:12, md:5}}>
                <UserDetailSectionDisplay userDetail={userDetail} loading={false} canEdit={canEdit} />
              </Grid2>
            )}
          </Grid2>
        </AnimationWrapper>
      </Container>
    </DivWrapper>
  );
};

type UserDetailSectionDisplayProps = {
  canEdit: boolean;
  userDetail: IUserDetail;
  loading: boolean;
};

const UserDetailSectionDisplay = ({ canEdit, userDetail }: UserDetailSectionDisplayProps) => {
  const imageUrl = useFileSourceSingleHook({ fileId: userDetail.fileId || "", fileType: FileTypeEnum.Image });
  const [, setUploading] = useState(false);
  const dispatch = useDispatch();

  async function onUploadComplete() {
    try {
      await dispatch(fetchSearchUserDetails({ pageNumber: 1, pageSize: 1, userDetailId: userDetail.userDetailId }));
    } catch {
      console.error("Failed to upload user details.");
    } finally {
      setUploading(false);
    }
  }

  if (!userDetail) return null;

  return (
    <Paper className="detailsDisplayWrapper">
      <Grid2
        container
        direction="column"
        justifyContent="center"
        alignContent="center"
        spacing={3}
        style={{ textAlign: "center" }}
      >
        <Grid2 size={{xs:12}} style={{ position: "relative" }}>
          <img src={imageUrl} className="avatarImage" alt={userDetail.displayName} />
          {canEdit ? (
            <div style={{ position: "absolute", bottom: 60, right: 60 }}>
              <FileUploadIcon
                entityId={userDetail?.userDetailId}
                entityType={EntityTypeEnum.User}
                description={userDetail.displayName}
                fileType={FileTypeEnum.Image}
                onUploadComplete={onUploadComplete}
                imageHeight={125}
                imageWidth={125}
              />
            </div>
          ) : null}
        </Grid2>
        <Grid2 size={{xs:10}} style={{ textAlign: "center" }}>
          <Typography variant="caption">{userDetail?.company || "-"}</Typography>
          <Typography variant="subtitle1">{userDetail?.displayName || "-"}</Typography>
          <Typography variant="body2">{userDetail?.aboutMe || "-"}</Typography>
        </Grid2>
      </Grid2>
    </Paper>
  );
};

type UserDetailSectionFormProps = {
  canEdit: boolean;
  userDetail: IUserDetail;
};

const UserDetailSectionForm = ({ userDetail, canEdit }: UserDetailSectionFormProps) => {
  const dispatch = useDispatch();

  if (!userDetail) return null;

  return (
    <WidgetSectionBase
      headerText="User Profile"
      subheaderText={canEdit ? "Maintain your profile and settings." : "View user profile and settings."}
      headerIcon={<InputIcon />}
      headerColor={HeaderColor[800]}
    >
      <FormUserDetailUpdate
        canEdit={canEdit}
        userDetail={userDetail}
        dispatch={dispatch}
        onCompleteCallback={() => {}}
        onCancelCallback={() => {}}
      />
    </WidgetSectionBase>
  );
};

export default PageUserDetails;
