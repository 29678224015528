import { Button, Divider, FormControl, FormControlLabel, Grid2, Switch, TextField } from "@mui/material";
import { FormikProps, withFormik } from "formik";
import React from "react";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";
import { Autocomplete } from "@mui/lab";
import { useSelector } from "react-redux";

import { RootState } from "../../../redux";
import { fetchSearchAssetInputFields } from "../../../redux/assetInputField/actions";
import { fetchSearchAssetInputFieldCategories } from "../../../redux/assetInputFieldCategory/actions";
import { fetchCreatePlan } from "../../../redux/plan/actions";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { IEvent } from "../../../utilities/types/Event";
import { IPlan, PlanTypeEnum } from "../../../utilities/types/Plan";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { selectorGetEventsByModelId } from "../../../redux/event/selectors";
import StringListInput from "../../generic/widgets/StringListInput";

interface FormValues {
  name: string[];
  disabled: boolean;
  orderNumber: number;
  eventInterval: number;
  doNotOptimise: boolean;
  planType: PlanTypeEnum;
  code: string;
  primaryEventId: string;
  createCorrespondingInput: boolean;
  createCorrespondingEvent: boolean;
}

interface FormProps {
  modelId: string;
  primaryEventObj?: IEvent;
  onCompleteCallback(plan?: IPlan): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IPlan, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  var events = useSelector((store: RootState) => selectorGetEventsByModelId(store, props.modelId));
  const { values, handleChange } = props;

  const handleNameChange = (newStringList: string[]) => {
    handleChange({
      target: {
        name: "name",
        value: newStringList,
      },
    });
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
        <Grid2 size={{xs:12}}>
          <StringListInput label="Name(s)" onStringListChange={handleNameChange} stringList={values.name} errors={props.errors.name} />
        </Grid2>

        <Grid2 size={{xs:12}} style={{ alignSelf: "flex-end" }}>
          <FormControlLabel
            control={
              <Switch
                checked={props.values.createCorrespondingInput}
                onChange={() => {
                  props.setFieldValue("createCorrespondingInput", !props.values.createCorrespondingInput);
                }}
                name="CreateInput"
              />
            }
            label="Create Corresponding Input"
          />
        </Grid2>
        <Grid2 size={{xs:12}}>
          <FormControlLabel
            control={
              <Switch
                checked={props.values.createCorrespondingEvent}
                onChange={() => {
                  props.setFieldValue("createCorrespondingEvent", !props.values.createCorrespondingEvent);
                }}
                name="CreateEvent"
              />
            }
            label="Create Corresponding Event"
          />
        </Grid2>
        {props.values.createCorrespondingEvent === false && (
          <Grid2 size={{xs:12}}>
            <FormControl fullWidth={true}>
              <Autocomplete
                {...getFormikFieldProps(props, "primaryEventObj", "Primary Event")}
                style={{ display: "block" }}
                options={events}
                getOptionLabel={(option) => option.name}
                defaultValue={events.find((element) => {
                  return element.eventId === props.values.primaryEventId;
                })}
                onChange={(_, value) => {
                  props.setFieldValue("primaryEventId", value ? value.eventId : "");
                  props.setFieldValue("primaryEventObj", value);
                }}
                renderInput={(params) => <TextField variant="standard" {...params} label="Event" />}
              />
            </FormControl>
          </Grid2>
        )}

        <Grid2 size={{xs:12}}>
          <Divider light={true} />
        </Grid2>

        <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormPlanCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    name: [],
    disabled: false,
    code: "",
    orderNumber: 0,
    eventInterval: 0,
    doNotOptimise: false,
    createCorrespondingInput: true,
    createCorrespondingEvent: true,
    planType: PlanTypeEnum.Recurring,
    primaryEventId: "00000000-0000-0000-0000-000000000000",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.array().of(Yup.string().label("Name").min(3, "Please input 3 characters or more").max(500, "Please input 500 characters or less")),
    orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId } = props;

    const plansToCreate = values.name.map((name) => ({
      modelId, // Assuming modelId is obtained from some variable
      name,
      code: values.code,
      disabled: values.disabled,
      orderNumber: values.orderNumber,
      eventInterval: values.eventInterval,
      doNotOptimise: values.doNotOptimise,
      planType: values.planType,
      primaryEventId: values.primaryEventId,
      createCorrespondingInput: values.createCorrespondingInput,
      createCorrespondingEvent: values.createCorrespondingEvent,
    }));

    // Map dispatch via props
    var createdPlan = await dispatch(
      fetchCreatePlan({
        plans: plansToCreate,
      })
    );

    // Get new categories if any
    await dispatch(
      fetchSearchAssetInputFieldCategories({
        pageNumber: 1,
        pageSize: 20,
        modelId,
      })
    );

    // Get new inputs
    await dispatch(
      fetchSearchAssetInputFields({
        pageNumber: 1,
        pageSize: 500,
        modelId,
      })
    );

    setSubmitting(false);

    if (createdPlan) onCompleteCallback(createdPlan);
  },
})(InnerForm);

export default FormPlanCreate;
