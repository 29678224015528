import React, { useState } from "react";
import { TextField, Grid2, Divider, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { ShowMessage, UpdateMessage, getFormikFieldProps } from "../../../utilities/Helpers";
import { Formik } from "formik";

import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { fetchDeleteWorkflow, fetchUpdateWorkflow } from "../../../redux/workflow/actions";
import { IWorkflow } from "../../../utilities/types/Workflow";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { workflowValidationSchema } from "../constants";
import { IFetchUpdateWorkflowProps } from "../../../redux/workflow/actions";

interface FormValues {
  clientName: string;
  workflowName: string;
  clientBaseBucket: string;
}

interface FormProps {
  workflow: IWorkflow;
  onCompleteCallback(): void;
  onCancelCallback(): void;
}

const FormWorkflowUpdate: React.FC<FormProps> = ({ workflow, onCompleteCallback, onCancelCallback }) => {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  async function deleteHandler() {
    setDeleting(true);
    const message = await ShowMessage("Removing...", MessageTypeEnum.Information);
    setShowDeleteConfirmation(false);

    // Perform delete
    const resp = await dispatch(fetchDeleteWorkflow({ workflowId: workflow.workflowId }));

    // Cleanup
    setDeleting(false);
    if (resp != null) {
      UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
      onCancelCallback();
    }
  }

  const handleSubmit = async (values: FormValues) => {
    const message = await ShowMessage("Updating", MessageTypeEnum.Information);
    const reqData: IFetchUpdateWorkflowProps = {
      ...values,
      workflowId: workflow.workflowId,
    };

    if (workflow.workflowObject) {
      reqData.workflowObject = workflow.workflowObject;
    }

    const updatedWorkflow = await dispatch(fetchUpdateWorkflow(reqData));

    if (updatedWorkflow !== null) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback();
    }
  };
  const { clientName, workflowName, clientBaseBucket } = workflow;
  return (
    <Formik
      initialValues={{ clientName, workflowName, clientBaseBucket }}
      validationSchema={workflowValidationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Grid2 container spacing={2}>
          <Grid2 size={{xs:12}}>
            <TextField
              onChange={props.handleChange}
              {...getFormikFieldProps(props, "clientName", "Client Name")}
              fullWidth
              margin="normal"
              variant="standard"
            />
          </Grid2>
          <Grid2 size={{xs:12}}>
            <TextField
              onChange={props.handleChange}
              {...getFormikFieldProps(props, "workflowName", "Workflow Name")}
              fullWidth
              margin="normal"
              variant="standard"
            />
          </Grid2>
          <Grid2 size={{xs:12}}>
            <TextField
              onChange={props.handleChange}
              {...getFormikFieldProps(props, "clientBaseBucket", "Client Bucket")}
              fullWidth
              margin="normal"
              variant="standard"
            />
          </Grid2>
          <Grid2 size={{xs:12}}>
            <Divider light={true} />
          </Grid2>
          <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
            <Button
              color="secondary"
              disabled={props.isSubmitting || deleting}
              style={{ marginRight: 16 }}
              variant="contained"
              onClick={toggleDeleteConfirmation}
            >
              Delete
            </Button>
            <Button
              disabled={props.isSubmitting || deleting}
              variant="text"
              style={{ marginRight: 16 }}
              onClick={onCancelCallback}
            >
              Close
            </Button>
            <Button
              disabled={props.isSubmitting || deleting}
              variant="outlined"
              color="primary"
              onClick={props.submitForm}
            >
              Update
            </Button>
          </Grid2>
          <LoaderAbsoluteCentred loading={props.isSubmitting || deleting} />
          <WidgetModalConfirmationDialog
            open={showDeleteConfirmation}
            title="Delete workflow"
            subtitle="Confirm workflow delete"
            description="Are you sure that you'd like to remove this workflow?"
            onCancelCallback={toggleDeleteConfirmation}
            onConfirmCallback={deleteHandler}
            confirmButtonText="Delete"
          />
        </Grid2>
      )}
    </Formik>
  );
};

export default FormWorkflowUpdate;
