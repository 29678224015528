import React, { useState } from "react";

import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  styled,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import { useDispatch } from "react-redux";
import { ACTIONS_WORKFLOW_TYPE } from "../../redux/workflow/types";
import ModalWorkflowCreate from "./modals/ModalWorkflowCreate";
import ModalWorkflowUpdate from "./modals/ModalWorkflowUpdate";
import { IWorkflow } from "../../utilities/types/Workflow";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import {
  fetchCreateWorkflow,
  IFetchCreateWorkflowProps,
} from "../../redux/workflow/actions";

const WorkflowWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "16px",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
  "& .workflowHeader_inner": {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    flexWrap: "wrap",
    flex: 1,
    flexShrink: 0,
  },
  "& .workflowHeader_actions": {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    flexWrap: "wrap",
  },
  "& .workflowHeader_select": {
    maxWidth: "fit-content",
    minWidth: "150px",
    overflow: "hidden",
    flex: 1,
  },
}));

interface WorkflowHeaderWidgetProps {
  allWorkflows: IWorkflow[];
  selectedWorkFlow?: IWorkflow;
  handleRunWorkflow: (workflowId: string) => void;
  handleSaveWorkflow: (workflow: IWorkflow) => void;
  setApiLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkflowHeaderWidget: React.FC<WorkflowHeaderWidgetProps> = ({
  allWorkflows,
  selectedWorkFlow,
  handleSaveWorkflow,
  handleRunWorkflow,
  setApiLoading,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("xs"));

  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showWorkflowModal, setShowWorkflowModal] = useState<boolean>(false);

  const handleChange = (event: any) => {
    dispatch({
      type: ACTIONS_WORKFLOW_TYPE.SET_SELECTED,
      workflowId: event.target.value,
    });
  };

  function handleToggleShowWorkflowModal() {
    setShowWorkflowModal((prev) => !prev);
  }

  function handleToggleShowUpdateWorkflowModal() {
    setShowUpdateModal((prev) => !prev);
  }
  const handleCloneWorkflow = async () => {
    setApiLoading(true);
    const data: IFetchCreateWorkflowProps & {
      workflowObject: string | JSON | Object | null;
    } = {
      clientName: selectedWorkFlow?.clientName as string,
      workflowName: `${selectedWorkFlow?.workflowName}-copy` as string,
      clientBaseBucket: selectedWorkFlow?.clientBaseBucket as string,
      workflowObject: selectedWorkFlow?.workflowObject || null,
    };
    try {
      const response = (await dispatch(
        fetchCreateWorkflow(data)
      )) as unknown as IWorkflow[];

      if (response) {
        dispatch({
          type: ACTIONS_WORKFLOW_TYPE.SET_SELECTED,
          workflowId: response[0].workflowId,
        });
      }
    } catch (error) {
      console.error("Error cloning workflow:", error);
    }
    setApiLoading(false);
  };
  return (
    <WorkflowWrapper>
      <div className="workflowHeader_inner">
        <Typography variant={isSmallDevice ? "h6" : "h5"}>
          Workflows:
        </Typography>
        <FormControl size="small" className="workflowHeader_select">
          <Select
            labelId="workflow-type-label"
            value={selectedWorkFlow ? selectedWorkFlow.workflowId : ""}
            onChange={handleChange}
            style={{ display: "block" }}
            inputProps={{ "aria-label": "Without label" }}
            id="workflow-type"
            variant="standard"
          >
            {allWorkflows.map(({ workflowId, workflowName }: IWorkflow) => (
              <MenuItem key={workflowId} value={workflowId}>
                {workflowName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedWorkFlow && (
          <IconButton
            size={isSmallDevice ? "small" : "medium"}
            onClick={handleToggleShowUpdateWorkflowModal}
          >
            <EditIcon />
          </IconButton>
        )}
        <IconButton
          size={isSmallDevice ? "small" : "medium"}
          onClick={handleToggleShowWorkflowModal}
        >
          <AddIcon />
        </IconButton>
        <Tooltip title="Clone Workflow">
          <IconButton
            size={isSmallDevice ? "small" : "medium"}
            onClick={handleCloneWorkflow}
          >
            <CopyAllIcon />
          </IconButton>
        </Tooltip>
      </div>
      {selectedWorkFlow && (
        <div className="workflowHeader_actions">
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSaveWorkflow(selectedWorkFlow)}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleRunWorkflow(selectedWorkFlow.workflowId)}
          >
            Run
          </Button>
        </div>
      )}
      <ModalWorkflowCreate
        open={showWorkflowModal}
        onCancelCallback={handleToggleShowWorkflowModal}
        onCompleteCallback={handleToggleShowWorkflowModal}
      />
      {selectedWorkFlow && (
        <ModalWorkflowUpdate
          open={showUpdateModal}
          workflow={selectedWorkFlow}
          onCancelCallback={handleToggleShowUpdateWorkflowModal}
          onCompleteCallback={handleToggleShowUpdateWorkflowModal}
        />
      )}
    </WorkflowWrapper>
  );
};

export default WorkflowHeaderWidget;
