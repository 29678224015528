import { AppBar, Box, Button, Grid2, Tab, Tabs, Typography, styled } from "@mui/material";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../redux";
import { selectorGetComponentsByModelId } from "../../redux/component/selectors";
import { GetModelsSearchPage } from "../../routes/RouteLinkHelpers";
import { IPlan } from "../../utilities/types/Plan";
import FunctionsRenderer from "../function/FunctionsRenderer";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import ModelBuilderComponent from "../model/ModelBuilderComponent";
import { PreloadModelStructure } from "../model/ModelBuilderMainTabsContainer";
import useJobCreateState from "./WizardState";
import { fetchCreateAssetJob } from "../../redux/assetJob/actions";
import { IAssetJob } from "../../utilities/types/AssetJobs";
import PlanReviewCausesTab from "../plan/PlanReviewCausesTab";
import CostBundleReviewCausesTab from "../costBundle/CostBundleReviewCausesTab";
import TaskReviewCausesTab from "../task/TaskReviewCausesTab";
import EventReviewCausesTab from "../event/EventReviewCausesTab";

interface IJobCreateReviewStepProps {
  onCompleteCallback(): void;
}

const GridWrapper = styled(Grid2)(({ theme }) => ({
  position: "relative",
  "& .appBar": {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    border: "1px solid #EEE",
    borderRadius: theme.shape.borderRadius,
  },
}));

function JobCreateReviewStep({ onCompleteCallback }: IJobCreateReviewStepProps) {
  const modelId = useJobCreateState((s) => s.modelId);
  const appliedCausesOutput = useJobCreateState((s) => s.appliedCausesOutput);
  const setPrerequisites = useJobCreateState((s) => s.setPrerequisites);
  const [applyingCauses] = useState<boolean>(false);
  let history = useHistory();
  const components = useSelector((store: RootState) => selectorGetComponentsByModelId(store, modelId));
  const [selectedTab, setSelectedTab] = React.useState(0);

  const plans = appliedCausesOutput?.plans?.filter((x: IPlan) => !(x.eventInterval === -1)) || [];
  const costBundles = appliedCausesOutput?.costBundles || [];
  const tasks = appliedCausesOutput?.tasks || [];
  const events = appliedCausesOutput?.events || [];

  function viewModels() {
    history.push(GetModelsSearchPage());
  }

  const handleTabChange = (event: any, newValue: React.SetStateAction<number>) => {
    setSelectedTab(newValue);
  };
  const dispatch = useDispatch();
  var { fetching } = PreloadModelStructure({ modelId });

  async function onSubmit() {
    const { modelId, fileId, assetInputValuesObject } = useJobCreateState.getState();

    var assetJob: IAssetJob = (await dispatch(
      fetchCreateAssetJob({
        modelId,
        fileId,
        jobName: "UIJOB",
        assetInputFieldValueMap: assetInputValuesObject,
        outputBucket: null,
        inputBucket: null,
        terminateOnErrorCount: null,
        prerequisites: {},
      })
    )) as any;

    if (assetJob && assetJob?.assetJobId) {
      setPrerequisites("assetJobIds", [assetJob.assetJobId]);
      onCompleteCallback();
    }
  }

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={{xs:12}} style={{ display: "flex", flexDirection: "row", position: "relative" }}>
        <div style={{ flexBasis: "100%", textAlign: "right" }}>
          <Button id="btn-next" color="primary" variant="contained" onClick={onSubmit}>
            Next
          </Button>
          <LoaderAbsoluteCentred loading={applyingCauses} />
        </div>
      </Grid2>
      <GridWrapper size={{xs:12}}>
        <AppBar position="static" className="appBar">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Structure" {...a11yProps(0)} />
            <Tab label="Plans" {...a11yProps(1)} />
            <Tab label="Cost Bundles" {...a11yProps(2)} />
            <Tab label="Tasks" {...a11yProps(3)} />
            <Tab label="Events" {...a11yProps(4)} />
          </Tabs>
        </AppBar>

        <TabPanel value={selectedTab} index={0}>
          {components.length > 0 ? (
            components.map((component) => {
              return (
                <>
                  <ModelBuilderComponent
                    component={component}
                    canEdit={false}
                    onUploadComplete={() => {}}
                    onComponentClick={() => {}}
                    canDiscuss={false}
                  />
                  <FunctionsRenderer component={component} canEdit={false} loading={false} />
                </>
              );
            })
          ) : (
            <Typography textAlign="center" variant="subtitle2">
              Specific asset not available for bulk loaders
            </Typography>
          )}
          <LoaderAbsoluteCentred loading={fetching} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <PlanReviewCausesTab plans={plans} events={events} />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <CostBundleReviewCausesTab costBundles={costBundles} />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <TaskReviewCausesTab tasks={tasks} />
        </TabPanel>
        <TabPanel value={selectedTab} index={4}>
          <EventReviewCausesTab events={events} tasks={tasks} costBundles={costBundles} />
        </TabPanel>
      </GridWrapper>
      <Grid2 size={{xs:12}} style={{ display: "flex", flexDirection: "row", position: "relative" }}>
        <div style={{ flexBasis: "50%", textAlign: "left" }}>
          <Button variant="text" style={{ marginRight: 8 }} onClick={viewModels}>
            Cancel
          </Button>
        </div>
        <div style={{ flexBasis: "50%", textAlign: "right" }}>
          <Button color="primary" variant="contained" onClick={onSubmit}>
            Next
          </Button>
          <LoaderAbsoluteCentred loading={applyingCauses} />
        </div>
      </Grid2>
    </Grid2>
  );
}

function a11yProps(index: number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

interface ITabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

function TabPanel({ children, value, index }: ITabPanelProps) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default JobCreateReviewStep;
