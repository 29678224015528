import React, { lazy, useState } from "react";
import { Typography, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Grid2, styled } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import WidgetModalConfirmationDialog from "../generic/widgets/modals/WidgetModalConfirmationDialog";
import { ShowMessage, UpdateMessage } from "../../utilities/Helpers";
import { MessageTypeEnum } from "../../utilities/types/Message";
import { selectorGetFunctionalFailureById } from "../../redux/functionalFailure/selectors";
import { IFunctionalFailure } from "../../utilities/types/FunctionalFailure";
import { fetchDeleteFunctionalFailure } from "../../redux/functionalFailure/actions";
import FunctionalFailureIcon from "@mui/icons-material/SmsFailedRounded";
import FunctionIcon from "@mui/icons-material/FunctionsOutlined";
import { IdentifierMappingTypeEnum } from "../../utilities/types/IdentifierMapping";
import IdentifierMappingsRenderer from "../identifierMapping/IdentifierMappingsRenderer";
import EditFunctionalFailureIcon from "@mui/icons-material/Edit";
import AddFunctionalFailureIcon from "@mui/icons-material/SmsFailedOutlined";
import IconColor from "@mui/material/colors/indigo";
import { selectorGetModelById } from "../../redux/model/selectors";
import { EnableComponentHierachy } from "../../redux/component/actions";
import { IModel, ModelStatusEnum } from "../../utilities/types/Model";
import { green } from "@mui/material/colors";
import { selectorGetUserPermissionOfType } from "../../redux/userPermission/selectors";
import { UserPermissionTypeEnum } from "../../utilities/types/UserPermission";
import { GetUserId } from "../../utilities/ApiUtils";
import SuspenseWrapper from "../generic/SuspenseWrapper";

const LazyModalFailureModeCreate = lazy(() => import("../failureMode/modals/ModalFailureModeCreate"));
const LazyModalIdentifierMappingCreate = lazy(() => import("../identifierMapping/modals/ModalIdentifierMappingCreate"));

const DivWrapper = styled("div")(({ theme }) => ({
  border: "1px solid #EEE",
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  paddingBottom: 0,
  "& .iconWrapper": {
    backgroundColor: IconColor[500],
    color: "#FFF",
    padding: theme.spacing(1),
    paddingBottom: 0,
    borderRadius: 40,
    height: 40,
    width: 40,
    textAlign: "center",
    overflow:'hidden',
    position:'relative',
    "&:after":{
      content: '""',
      height: '108%',
      width: '2px',
      display: 'inline-block',
      position: 'absolute',
      top: '0px',
      bottom: 0,
      left: '50%',
      transform: 'rotate(45deg)',
      background: '#ececec',
      // border:'0.25px solid #ececec'
    }
  },
  "& .textWrapper": {
    marginLeft: theme.spacing(3),
  },
  "& .menuWrapper": {
    textAlign: "right",
  },
  "& .greenIcon": {
    color: green[500],
  },
  "& .orangeDraft": {
    color: "orange",
  },
}));

type IFunctionalFailureRendererProps = {
  canEdit: boolean;
  functionalFailureId: string;
  editFunctionalFailure(): void;
  cloneFunctionalFailure(): void;
};

const FunctionalFailureRenderer = (props: IFunctionalFailureRendererProps) => {
  const { functionalFailureId } = props;
  const componentFunctionalFailure: IFunctionalFailure | undefined = useSelector((store: RootState) =>
    selectorGetFunctionalFailureById(store, functionalFailureId)
  );

  if (!componentFunctionalFailure) {
    console.error(`Component functionalFailure not found: ${functionalFailureId}`);
    return null;
  }

  return <FunctionalFailureRendererDisplay componentFunctionalFailure={componentFunctionalFailure} {...props} />;
};

interface IFunctionalFailureRendererDisplayProps extends IFunctionalFailureRendererProps {
  componentFunctionalFailure: IFunctionalFailure;
}

const FunctionalFailureRendererDisplay = (props: IFunctionalFailureRendererDisplayProps) => {
  const { componentFunctionalFailure, canEdit, editFunctionalFailure, cloneFunctionalFailure } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteConfirmation = () => setShowDeleteModal(!showDeleteModal);
  const [, setDeleting] = useState(false);
  const [showCreateMappingModel, setShowCreateMappingModal] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [showAddFailureModeModal, setShowAddFailureModeModal] = useState(false);
  const model = useSelector((store: RootState) => selectorGetModelById(store, componentFunctionalFailure.modelId));
  const dispatch = useDispatch();
  const canAddFailureModes = useSelector((state: RootState) =>
    selectorGetUserPermissionOfType(state, GetUserId(), UserPermissionTypeEnum.ServicesFailureModeCreate)
  );
  const canEditFunctionalFailures = useSelector((state: RootState) =>
    selectorGetUserPermissionOfType(state, GetUserId(), UserPermissionTypeEnum.ServicesFunctionalFailureUpdate)
  );

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  async function toggleCreateMappingModal() {
    setShowCreateMappingModal(!showCreateMappingModel);
  }

  async function toggleShowAddFailureModeModal() {
    setMenuAnchorEl(undefined);
    setShowAddFailureModeModal(!showAddFailureModeModal);
  }

  async function deleteHandler() {
    // Inform user
    setDeleting(true);
    var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
    setShowDeleteModal(false);

    // Perform delete
    var resp = await dispatch(
      fetchDeleteFunctionalFailure({ functionalFailureId: componentFunctionalFailure.functionalFailureId })
    );

    // Cleanup
    setDeleting(false);
    if (resp != null) {
      UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
      toggleDeleteConfirmation();
    }
  }

  async function closeEditModal() {
    setMenuAnchorEl(undefined);
    editFunctionalFailure();
  }

  async function closeCloneModal() {
    setMenuAnchorEl(undefined);
    cloneFunctionalFailure();
  }

  function onCreateCallback() {
    toggleShowAddFailureModeModal();
    dispatch(EnableComponentHierachy({ model: model as IModel }));
  }

  return (
    <DivWrapper>
      <div>
        <Grid2 container>
          <Grid2 size={{xs:1}}>
            <div className="iconWrapper">
              <FunctionIcon />
            </div>
          </Grid2>
          <Grid2 size={{xs:10}}>
            <div className="textWrapper">
              {componentFunctionalFailure.status === ModelStatusEnum.Draft ? (
                <Typography className="orangeDraft">{componentFunctionalFailure.description} (Draft)</Typography>
              ) : (
                <Typography variant="body1">{componentFunctionalFailure.description}</Typography>
              )}
              <div>
                <IdentifierMappingsRenderer
                  canEdit={canEdit}
                  modelId={componentFunctionalFailure.modelId}
                  type={IdentifierMappingTypeEnum.FunctionalFailure}
                  targetId={String(componentFunctionalFailure.functionalFailureId)}
                  loading={false}
                  showValues={false}
                />
                <SuspenseWrapper showFullLoader>
                  <LazyModalIdentifierMappingCreate
                    open={showCreateMappingModel}
                    onCancelCallback={toggleCreateMappingModal}
                    onCompleteCallback={toggleCreateMappingModal}
                    targetId={String(componentFunctionalFailure.functionalFailureId)}
                    modelId={componentFunctionalFailure.modelId}
                    identifierMappingType={IdentifierMappingTypeEnum.FunctionalFailure}
                  />
                </SuspenseWrapper>
              </div>
            </div>
          </Grid2>
          <Grid2 size={{xs:1}}>
            {canEdit ? (
              <div className="menuWrapper">
                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
                  <EditFunctionalFailureIcon className="greenIcon" />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={menuAnchorEl}
                  keepMounted
                  open={Boolean(menuAnchorEl)}
                  onClose={() => setMenuAnchorEl(undefined)}
                >
                  {canAddFailureModes ? (
                    <MenuItem onClick={toggleShowAddFailureModeModal}>
                      <ListItemIcon>
                        <AddFunctionalFailureIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Add failure mode" />
                    </MenuItem>
                  ) : null}

                  {canEditFunctionalFailures ? (
                    <MenuItem onClick={closeEditModal}>
                      <ListItemIcon>
                        <EditFunctionalFailureIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Edit functional failure" />
                    </MenuItem>
                  ) : null}

                  {canEditFunctionalFailures ? (
                    <MenuItem onClick={closeCloneModal}>
                      <ListItemIcon>
                        <EditFunctionalFailureIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Clone functional failure" />
                    </MenuItem>
                  ) : null}
                </Menu>
              </div>
            ) : null}
          </Grid2>
        </Grid2>
      </div>

      <WidgetModalConfirmationDialog
        open={showDeleteModal}
        title="Delete confirmation"
        subtitle="Confirm identifier delete"
        description="Are you sure that you'd like to remove this identifier?"
        onCancelCallback={toggleDeleteConfirmation}
        onConfirmCallback={deleteHandler}
        confirmButtonText="Delete"
      />
      <SuspenseWrapper showFullLoader>
        <LazyModalFailureModeCreate
          open={showAddFailureModeModal}
          onCancelCallback={toggleShowAddFailureModeModal}
          onCompleteCallback={onCreateCallback}
          modelId={componentFunctionalFailure.modelId}
          functionalFailureId={componentFunctionalFailure.functionalFailureId}
        />
      </SuspenseWrapper>
    </DivWrapper>
  );
};

export default FunctionalFailureRenderer;
