import React, { lazy, useState } from "react";
import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Avatar,
  Grid2,
  styled,
  Tooltip,
  SvgIcon,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { RootState } from "../../redux";
import WidgetModalConfirmationDialog from "../generic/widgets/modals/WidgetModalConfirmationDialog";
import { ShowMessage, UpdateMessage, FormatNumberWithCommas } from "../../utilities/Helpers";
import { MessageTypeEnum } from "../../utilities/types/Message";
import { selectorGetFailureModeById } from "../../redux/failureMode/selectors";
import { IFailureMode } from "../../utilities/types/FailureMode";
import { fetchDeleteFailureMode } from "../../redux/failureMode/actions";
import { IdentifierMappingTypeEnum } from "../../utilities/types/IdentifierMapping";
import IdentifierMappingsRenderer from "../identifierMapping/IdentifierMappingsRenderer";
import EditFailureModeIcon from "@mui/icons-material/Edit";
import IconColor from "@mui/material/colors/orange";
import { green, blue, pink, red, grey } from "@mui/material/colors";
import { ModelStatusEnum } from "../../utilities/types/Model";
import ArrowIcon from "@mui/icons-material/ArrowForward";
import SuspenseWrapper from "../generic/SuspenseWrapper";
import FunctionalFailureIcon from "@mui/icons-material/SmsFailedRounded";
import {  VisibilityOffOutlined } from "@mui/icons-material";
import { SkullIcon } from "../../utilities/Constants";

const LazyModalIdentifierMappingCreate = lazy(() => import("../identifierMapping/modals/ModalIdentifierMappingCreate"));

const DivWrapper = styled("div")(({ theme }) => ({
  border: "1px solid #EEE",
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1),
  "& .iconWrapper": {
    backgroundColor: IconColor[500],
    color: "#FFF",
    padding: theme.spacing(1),
    paddingBottom: 0,
    borderRadius: 40,
    height: 40,
    width: 40,
    textAlign: "center",
  },
  "& .orangeDraft": {
    color: "orange",
  },
  "& .iconGreen": {
    color: green[500],
  },
  "& .textWrapper": {
    marginLeft: theme.spacing(3),
  },
  "& .flex-box": {
    display: "flex",
    alignItems: "start",
    gap: theme.spacing(1.2),
  },
  "& .menuWrapper": {
    textAlign: "right",
  },
  "& .statChip": {
    color: "#FFF",
    marginRight: theme.spacing(1),
  },
  "& .statChipAvatar": {
    backgroundColor: "rgba(0,0,0,0.2)",
    color: "#FFF !important",
  },
  "& .chipWrapper": {
    margin: theme.spacing(1),
  },
}));
const SkullIconSvg = (props:any) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
  {SkullIcon}
  </SvgIcon>
);

type IFailureModeRendererProps = {
  canEdit: boolean;
  failureModeId: string;
  editFailureMode(): void;
  cloneFailureMode(): void;
};

const FailureModeRenderer = (props: IFailureModeRendererProps) => {
  const { failureModeId } = props;
  const failureMode: IFailureMode | undefined = useSelector((store: RootState) =>
    selectorGetFailureModeById(store, failureModeId)
  );

  if (!failureMode) {
    console.error(`Component failureMode not found: ${failureModeId}`);
    return null;
  }

  return <FailureModeRendererDisplay failureMode={failureMode} {...props} />;
};

interface IFailureModeRendererDisplayProps extends IFailureModeRendererProps {
  failureMode: IFailureMode;
}

const FailureModeRendererDisplay = (props: IFailureModeRendererDisplayProps) => {
  const { failureMode, canEdit, editFailureMode, cloneFailureMode } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteConfirmation = () => setShowDeleteModal(!showDeleteModal);
  const dispatch = useDispatch();
  const [, setDeleting] = useState(false);
  const [showCreateMappingModel, setShowCreateMappingModal] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  var gamma = require("math-gamma");



  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  async function toggleCreateMappingModal() {
    setShowCreateMappingModal(!showCreateMappingModel);
  }

  async function deleteHandler() {
    // Inform user
    setDeleting(true);
    var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
    setShowDeleteModal(false);

    // Perform delete
    var resp = await dispatch(fetchDeleteFailureMode({ failureModeId: failureMode.failureModeId }));

    // Cleanup
    setDeleting(false);
    if (resp != null) {
      UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
      toggleDeleteConfirmation();
    }
  }

  async function closeEditModal() {
    setMenuAnchorEl(undefined);
    editFailureMode();
  }

  async function closeCloneModal() {
    setMenuAnchorEl(undefined);
    cloneFailureMode();
  }

  return (
    <DivWrapper>
      <div>
        <Grid2 container>
          <Grid2 size={{xs:1}}>
            <div className="iconWrapper">
              <FunctionalFailureIcon />
            </div>
          </Grid2>
          <Grid2 size={{xs:8}}>
            <div className="flex-box">
              <div>
              <div className="flex-box">
                {failureMode.status === ModelStatusEnum.Draft ? (
                  <Typography className="orangeDraft">{failureMode.description} (Draft)</Typography>
                ) : (
                  <Typography variant="body1">{failureMode.description}</Typography>
                )}
                {failureMode.conditionRelatedMode && <Tooltip title="Condition Related Mode"><FavoriteIcon style={{ color: red[600] }} /></Tooltip>}
                
                {failureMode.primary && <Tooltip title="End of Life Mode"><span><SkullIconSvg style={{ fill: grey[600] }}/></span></Tooltip>}

                {failureMode.hidden && <Tooltip title="Hidden Mode"><VisibilityOffOutlined style={{ color: grey[600] }} /></Tooltip>}
                </div>
                <div>
                  {failureMode.quantity > 1 ? (
                    <Chip
                      className="statChip"
                      avatar={<Avatar className="statChipAvatar">x</Avatar>}
                      label={FormatNumberWithCommas(failureMode.quantity)}
                      size="small"
                      style={{ backgroundColor: grey[800] }}
                    />
                  ) : null}
                  <Chip
                    className="statChip"
                    avatar={<Avatar className="statChipAvatar">&eta;</Avatar>}
                    label={FormatNumberWithCommas(failureMode.eta)}
                    size="small"
                    style={{ backgroundColor: pink[300] }}
                  />
                  <Chip
                    className="statChip"
                    avatar={<Avatar className="statChipAvatar">&beta;</Avatar>}
                    label={FormatNumberWithCommas(failureMode.beta)}
                    size="small"
                    style={{ backgroundColor: blue[300] }}
                  />
                  <Chip
                    className="statChip"
                    avatar={<Avatar className="statChipAvatar">&gamma;</Avatar>}
                    label={FormatNumberWithCommas(failureMode.gamma)}
                    size="small"
                    style={{ backgroundColor: green[300] }}
                  />
                  {failureMode.initialAge !== 0 ? (
                    <Chip
                      className="statChip"
                      icon={<ArrowIcon />}
                      label={FormatNumberWithCommas(failureMode.initialAge)}
                      size="small"
                      style={{ backgroundColor: grey[300] }}
                    />
                  ) : null}

                  <IdentifierMappingsRenderer
                    canEdit={canEdit}
                    modelId={failureMode.modelId}
                    type={IdentifierMappingTypeEnum.FailureMode}
                    targetId={String(failureMode.failureModeId)}
                    loading={false}
                    showValues={false}
                  />
                </div>
              </div>
            </div>
          </Grid2>
          <Grid2 size={{xs:2}}>
            <div className="textWrapper">
              <Typography variant="body1" align="center">
                Failures / Year
              </Typography>
              <div className="chipWrapper">
                <Chip
                  className="statChip"
                  avatar={<Avatar className="statChipAvatar">&lambda;</Avatar>}
                  label={((1 / (failureMode.eta / 8760)) * gamma(1 / failureMode.beta + 1)).toFixed(9)}
                  size="small"
                  style={{ backgroundColor: pink[300] }}
                />
              </div>
            </div>
          </Grid2>
          <Grid2 size={{xs:1}}>
            <div className="menuWrapper">
              <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <EditFailureModeIcon className="iconGreen" />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(undefined)}
              >
                <MenuItem onClick={closeEditModal}>
                  <ListItemIcon>
                    <EditFailureModeIcon fontSize="small" className="iconGreen" />
                  </ListItemIcon>
                  <ListItemText primary="Edit failure mode" />
                </MenuItem>

                <MenuItem onClick={closeCloneModal}>
                  <ListItemIcon>
                    <EditFailureModeIcon fontSize="small" className="iconGreen" />
                  </ListItemIcon>
                  <ListItemText primary="Clone failure mode" />
                </MenuItem>
              </Menu>
            </div>
          </Grid2>
        </Grid2>
      </div>
      <SuspenseWrapper showFullLoader>
        <LazyModalIdentifierMappingCreate
          open={showCreateMappingModel}
          onCancelCallback={toggleCreateMappingModal}
          onCompleteCallback={toggleCreateMappingModal}
          targetId={String(failureMode.failureModeId)}
          modelId={failureMode.modelId}
          identifierMappingType={IdentifierMappingTypeEnum.FailureMode}
        />
      </SuspenseWrapper>

      <WidgetModalConfirmationDialog
        open={showDeleteModal}
        title="Delete confirmation"
        subtitle="Confirm identifier delete"
        description="Are you sure that you'd like to remove this identifier?"
        onCancelCallback={toggleDeleteConfirmation}
        onConfirmCallback={deleteHandler}
        confirmButtonText="Delete"
      />
    </DivWrapper>
  );
};

export default FailureModeRenderer;
