import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import FormFunctionalFailureUpdate from "../forms/FormFunctionalFailureUpdate";
import { IFunctionalFailure } from "../../../utilities/types/FunctionalFailure";
import FunctionIcon from "@mui/icons-material/FunctionsOutlined";
import styled from "@emotion/styled";
export interface IModalFunctionalFailureUpdateProps {
  open: boolean;
  functionalFailure: IFunctionalFailure;
  onCancelCallback(): void;
  onCompleteCallback(functionalFailure?: IFunctionalFailure): void;
  canEdit: boolean;
}

function ModalFunctionalFailureUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  functionalFailure,
}: IModalFunctionalFailureUpdateProps) {
  const dispatch = useDispatch();

  
  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a functional failure"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<DivWrapper><div className="iconWrapper">
        <FunctionIcon />
      </div></DivWrapper>}
    >
      <DialogContent>
        <FormFunctionalFailureUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          functionalFailure={functionalFailure}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}
export const DivWrapper = styled("div")(({ theme }) => ({
  "& .iconWrapper": {
    color: "#FFF",
    paddingBottom: 0,
    borderRadius: 40,
    height: 40,
    width: 40,
    textAlign: "center",
    overflow:'hidden',
    "&:after":{
      content: '""',
      height: '48%',
      width: '2px',
      display: 'inline-block',
      position: 'absolute',
      top: '21px',
      bottom: 0,
      left: '51%',
      transform: 'rotate(45deg)',
      background: '#ececec',
    }
  },
}));
export default ModalFunctionalFailureUpdate;
