import React, { useState } from "react";
import { TextField, Grid2, Divider, Button, FormControl, IconButton } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { IEvent } from "../../../utilities/types/Event";
import { fetchCreateEvent } from "../../../redux/event/actions";
import { useSelector } from "react-redux";
import { selectorGetTasksByModelId } from "../../../redux/task/selectors";
import { selectorGetCostBundlesByModelId } from "../../../redux/costBundle/selectors";
import AddIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ModalCostBundleCreate from "../../costBundle/modals/ModalCostBundleCreate";
import ModalTaskCreate from "../../task/modals/ModalTaskCreate";
import { Autocomplete } from "@mui/lab";
import { ICostBundle } from "../../../utilities/types/CostBundle";
import { ITask } from "../../../utilities/types/Task";
import StringListInput from "../../generic/widgets/StringListInput";

interface FormValues {
  name: string[];
  disabled: boolean;
  costBundleId: string;
  taskId: string;
}

interface FormProps {
  modelId: string;
  costBundleObj?: ICostBundle;
  taskObj?: ITask;
  onCompleteCallback(event?: IEvent): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IEvent, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  const tasks = useSelector((store: RootState) => selectorGetTasksByModelId(store, props.modelId));
  const costBundles = useSelector((store: RootState) => selectorGetCostBundlesByModelId(store, props.modelId));
  const [showCostBundleCreate, setShowCostBundleCreate] = useState(false);
  const [showTaskCreate, setShowTaskCreate] = useState(false);

  const { values, errors, handleChange } = props;

  const handleNameChange = (newStringList: string[]) => {
    handleChange({
      target: {
        name: "name",
        value: newStringList,
      },
    });
  };

  function toggleShowCostBundleCreate() {
    setShowCostBundleCreate(!showCostBundleCreate);
  }

  function toggleShowTaskCreate() {
    setShowTaskCreate(!showTaskCreate);
  }

  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
        <Grid2 size={{xs:12}}>
          <StringListInput label="Name(s)" onStringListChange={handleNameChange} stringList={values.name} errors={errors.name} />
        </Grid2>

        <Grid2 size={{xs:10}}>
          <FormControl fullWidth={true}>
            <Autocomplete
              {...getFormikFieldProps(props, "costBundleObj", "Cost Bundle")}
              style={{ display: "block" }}
              options={costBundles}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                props.setFieldValue("costBundleId", value ? value.costBundleId : "");
                props.setFieldValue("costBundleObj", value);
              }}
              renderInput={(params) => <TextField variant="standard" {...params} label="Cost Bundle" />}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={{xs:2}}>
          <IconButton onClick={toggleShowCostBundleCreate} style={{ marginTop: 8 }}>
            <AddIcon />
          </IconButton>
        </Grid2>

        <Grid2 size={{xs:10}}>
          <FormControl fullWidth={true}>
            <Autocomplete
              {...getFormikFieldProps(props, "taskObj", "Task")}
              style={{ display: "block" }}
              options={tasks}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                props.setFieldValue("taskId", value ? value.taskId : "");
                props.setFieldValue("taskObj", value);
              }}
              renderInput={(params) => <TextField variant="standard" {...params} label="Task" />}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={{xs:2}}>
          <IconButton onClick={toggleShowTaskCreate} style={{ marginTop: 8 }}>
            <AddIcon />
          </IconButton>
        </Grid2>

        <Grid2 size={{xs:12}}>
          <Divider light={true} />
        </Grid2>
        <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
      {showCostBundleCreate && (
        <ModalCostBundleCreate
          open={showCostBundleCreate}
          onCancelCallback={toggleShowCostBundleCreate}
          modelId={props.modelId}
          onCompleteCallback={toggleShowCostBundleCreate}
        />
      )}
      {showTaskCreate && (
        <ModalTaskCreate
          open={showTaskCreate}
          onCancelCallback={toggleShowTaskCreate}
          modelId={props.modelId}
          onCompleteCallback={toggleShowTaskCreate}
        />
      )}
    </form>
  );
};

const FormEventCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    name: [],
    disabled: false,
    costBundleId: "00000000-0000-0000-0000-000000000000",
    taskId: "00000000-0000-0000-0000-000000000000",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.array().of(
      Yup.string()
        .label("Label")
        .min(3, "Please input 3 characters or more")
        .max(100, "Please input 100 characters or less")
        .required("Please provide a name")
    ),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId } = props;

    const eventsToCreate = values.name.map((name) => ({
      modelId,
      name,
      disabled: values.disabled,
      costBundleId: values.costBundleId,
      taskId: values.taskId,
    }));

    // Map dispatch via props
    var createdEvent = await dispatch(fetchCreateEvent({ events: eventsToCreate }));

    setSubmitting(false);

    if (createdEvent) onCompleteCallback(createdEvent);
  },
})(InnerForm);

export default FormEventCreate;
