import React, { memo, useCallback, useEffect, useState } from "react";
import { TextField, Grid2, Divider, Button, Autocomplete } from "@mui/material";
import { Formik } from "formik";

import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { outputValidationSchema, NODE_TYPES } from "../constants";
import { IOutputDetails, IOutputFormValues } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import {
  selectorGetOutputTypeById,
  selectorGetOutputTypes,
} from "../../../redux/outputType/selectors";
import { TOutputTypeInputFieldValue } from "../../../utilities/types/OutputType";
import OutputTypeInputFieldTabPanel from "../../outputTypeInputField/OutputTypeInputFieldTabPanel";
import { IOutputTypeInputField } from "../../../utilities/types/OutputTypeInputField";
import useJobCreateState from "../../solverJobs/WizardState";
import {
  applyFieldChangeToValueMap,
  getReportInputsFromValueMap,
  setupFieldValueMap,
} from "../../outputTypeInputField/valueMapHelper";

const ShowOutputParameters = memo(
  ({
    outputTypeId,
    outputTypeInputFieldValueMap,
    isEditMode,
  }: {
    outputTypeId: string;
    outputTypeInputFieldValueMap?: IOutputDetails["outputTypeInputFieldValueMap"];
    isEditMode: boolean;
  }) => {
    const [initialLoaded, setInitialLoaded] = useState<boolean>(false);

    const outputInputValuesObjectMap = useJobCreateState((s) => s.outputInputValuesObjectMap);

    const outputType = useSelector((store: RootState) =>
      selectorGetOutputTypeById(store, outputTypeId)
    );

    const setupOutputFieldValues = useCallback((fields: IOutputTypeInputField[]) => {
      const valuesMap = useJobCreateState.getState().outputInputValuesObjectMap;
      const newValuesMap = setupFieldValueMap(valuesMap, fields);

      useJobCreateState.setState({ outputInputValuesObjectMap: newValuesMap });
      setInitialLoaded(true);
    }, []);

    const applyOutputFieldValueChange = useCallback(
      (outputFieldTypeId: string, fieldId: string, value: TOutputTypeInputFieldValue) => {
        const valuesMap = useJobCreateState.getState().outputInputValuesObjectMap;
        const newValuesMap = applyFieldChangeToValueMap(valuesMap, outputFieldTypeId, fieldId, value);

        useJobCreateState.setState({ outputInputValuesObjectMap: newValuesMap });
      },
      []
    );

    // Prepare inital values for solver input fields
    useEffect(() => {
      if (
        initialLoaded &&
        isEditMode &&
        outputType &&
        outputTypeInputFieldValueMap &&
        Object.keys(outputTypeInputFieldValueMap).length > 0
      ) {
        const valuesMap = useJobCreateState.getState().outputInputValuesObjectMap;
        let initialValues =
          valuesMap && valuesMap.hasOwnProperty(outputType.outputTypeId)
            ? valuesMap[outputType.outputTypeId]
            : {};

        useJobCreateState.setState({
          outputInputValuesObjectMap: {
            [outputType.outputTypeId]: { ...initialValues, ...outputTypeInputFieldValueMap },
          },
        });
      }
    }, [initialLoaded, isEditMode, outputTypeInputFieldValueMap, outputType]);

    if (!outputType) return null;

    return (
      <OutputTypeInputFieldTabPanel
        outputType={outputType}
        defaultValueOverrides={outputInputValuesObjectMap[outputType.outputTypeId] || {}}
        onValueChangeCallback={applyOutputFieldValueChange}
        onInputFieldsChanged={setupOutputFieldValues}
        onLoadChange={() => {}}
        key={outputType.outputTypeId}
        showDivider={false}
      />
    );
  }
);

interface FormAddUpdateOutputProps {
  outputDetails: IOutputDetails;
  onCancelCallback(): void;
  onCompleteCallback(newOutputDetails: IOutputFormValues, nodeType: NODE_TYPES, nodeId?: string): void;
  onDelete(nodeId: string, nodeType: NODE_TYPES): void;
}

const FormAddUpdateOutput: React.FC<FormAddUpdateOutputProps> = ({
  outputDetails: {
    isEditMode,
    nodeId = "",
    outputName = "",
    outputId = "",
    outputTypeInputFieldValueMap,
  },
  onCancelCallback,
  onCompleteCallback,
  onDelete,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const outputList = useSelector((store: RootState) => selectorGetOutputTypes(store));

  async function deleteHandler() {
    const selectedOutput = outputList.find((output) => {
      return output.outputTypeId === outputId;
    });
    const state = useJobCreateState.getState();

    if (
      selectedOutput &&
      state?.outputInputValuesObjectMap &&
      state?.outputInputValuesObjectMap[selectedOutput.outputTypeId]
    ) {
      let newOutputInputValuesObjectMap = state?.outputInputValuesObjectMap;
      delete newOutputInputValuesObjectMap[selectedOutput.outputTypeId];
      useJobCreateState.setState({ outputInputValuesObjectMap: newOutputInputValuesObjectMap });
    }
    setShowDeleteConfirmation(false);

    onDelete(nodeId, NODE_TYPES.OUTPUT);
  }

  const handleSubmit = async (values: IOutputFormValues) => {
    const selectedOutput = outputList.find((output) => {
      return output.outputTypeId === values.outputId;
    });

    const { outputInputValuesObjectMap } = useJobCreateState.getState();
    const outputTypeInputFieldValues = selectedOutput
      ? getReportInputsFromValueMap(outputInputValuesObjectMap, [selectedOutput as any])
      : null;

    const reqObj = {
      outputId: values.outputId,
      outputName: selectedOutput?.name ?? "",
      outputTypeInputFieldValueMap: outputTypeInputFieldValues
        ? outputTypeInputFieldValues[values.outputId]?.inputFieldValueMap
        : null,
    };

    if (isEditMode) {
      onCompleteCallback(reqObj, NODE_TYPES.OUTPUT, nodeId);
      return;
    }
    onCompleteCallback(reqObj, NODE_TYPES.OUTPUT);
  };

  return (
    <Formik
      initialValues={{ outputName, outputId }}
      validationSchema={outputValidationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        return (
          <Grid2 container spacing={2}>
            <Grid2 size={{xs:12}}>
              <Autocomplete
                {...getFormikFieldProps(props, "outputObj", "Select Output")}
                style={{ display: "block" }}
                defaultValue={outputList.find((output) => {
                  return output.outputTypeId === props.values.outputId;
                })}
                options={outputList}
                getOptionLabel={(option) => option.name}
                onChange={(_, value) => {
                  props.setFieldValue("outputObj", value);
                  props.setFieldValue("outputId", value ? value.outputTypeId : "");
                }}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(props.errors.outputId)}
                    helperText={props.errors.outputId}
                    variant="standard"
                    {...params}
                    label="Select Output"
                  />
                )}
              />
            </Grid2>
            <Grid2 size={{xs:12}}>
              <ShowOutputParameters
                outputTypeId={props.values.outputId}
                outputTypeInputFieldValueMap={outputTypeInputFieldValueMap}
                isEditMode={isEditMode}
              />
            </Grid2>
            <Grid2 size={{xs:12}}>
              <Divider light={true} />
            </Grid2>
            <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
              {isEditMode && (
                <Button
                  color="secondary"
                  disabled={props.isSubmitting}
                  style={{ marginRight: 16 }}
                  variant="contained"
                  onClick={toggleDeleteConfirmation}
                >
                  Delete
                </Button>
              )}
              <Button
                disabled={props.isSubmitting}
                variant="text"
                style={{ marginRight: 16 }}
                onClick={onCancelCallback}
              >
                Close
              </Button>
              <Button disabled={props.isSubmitting} variant="outlined" color="primary" onClick={props.submitForm}>
                {isEditMode ? "Update" : "Add"}
              </Button>
            </Grid2>
            <LoaderAbsoluteCentred loading={props.isSubmitting} />
            <WidgetModalConfirmationDialog
              open={showDeleteConfirmation}
              title="Delete output"
              subtitle="Confirm output delete"
              description="Are you sure that you'd like to remove this output?"
              onCancelCallback={toggleDeleteConfirmation}
              onConfirmCallback={deleteHandler}
              confirmButtonText="Delete"
            />
          </Grid2>
        );
      }}
    </Formik>
  );
};

export default FormAddUpdateOutput;
