import React, { useState } from "react";
import { TextField, Grid2, Divider, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { ITaskImpact } from "../../../utilities/types/TaskImpact";
import { fetchCreateTaskImpact } from "../../../redux/taskImpact/actions";
import { useSelector } from "react-redux";
import { selectorGetFailureModesByModelId } from "../../../redux/failureMode/selectors";
import ModalFailureModeCreate from "../../failureMode/modals/ModalFailureModeCreate";
import { Autocomplete } from "@mui/lab";
import { IFailureMode } from "../../../utilities/types/FailureMode";
import { StepLabourEnum } from "../../../utilities/types/TaskApplicability";
import TimeConverter from "../../taskApplicability/forms/TimeConverter";

interface FormValues {
  ageReductionFactor: number;
  orderNumber: number;
  failureModeIds: string[];
  stepDescription: string;
  acceptableLimit: string;
  stepLabour: number;
  duration: number;
  labourQuantity: number;
}

interface FormProps {
  modelId: string;
  taskId: string;
  failureModeObj?: IFailureMode;
  onCompleteCallback(taskImpacts?: ITaskImpact[]): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ITaskImpact, AnyAction>;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  const failureModes = useSelector((store: RootState) => selectorGetFailureModesByModelId(store, props.modelId));
  const [showCreateFailureModeModal, setShowCreateFailureModeModal] = useState(false);

  function toggleShowFailureModeCreate() {
    setShowCreateFailureModeModal(!showCreateFailureModeModal);
  }

  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
        <Grid2 size={{xs:12}}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "stepDescription", "Step Description")}
            fullWidth
            margin="normal"
            variant="standard"
          />
        </Grid2>
      <Grid2 size={{xs:12, sm:6}}>
          <TextField
            onChange={props.handleChange}
            type="number"
            fullWidth
            margin="normal"
            variant="standard"
            {...getFormikFieldProps(props, "ageReductionFactor", "Age Reduction Factor")}
          />
        </Grid2>
      <Grid2 size={{xs:12, sm:6}}>
          <TimeConverter
            label="Duration"
            defaultValueOverride={props.values.duration}
            onValueChangeCallback={(value) => {
              props.setFieldValue("duration", value);
            }}
            error={props.errors.duration}
          />
        </Grid2>
        <Grid2 size={{xs:12}}>
          <FormControl fullWidth={true}>
            <Autocomplete
              {...getFormikFieldProps(props, "failureModeObj", "Failure Mode")}
              style={{ display: "block" }}
              options={failureModes}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                props.setFieldValue("failureModeIds", value ? [value.failureModeId] : []);
                props.setFieldValue("failureModeObj", value);
              }}
              renderInput={(params) => <TextField variant="standard" {...params} label="Failure Mode" required />}
            />
          </FormControl>
        </Grid2>

        <Grid2 size={{xs:12}}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "acceptableLimit", "Acceptable Limit")}
            fullWidth
            margin="normal"
            variant="standard"
          />
        </Grid2>
        <Grid2 size={{xs:12}}>
          <FormControl fullWidth={true} margin="normal">
            <InputLabel id="lbl-model-type" variant="standard">
              Step Labour
            </InputLabel>
            <Select
              labelId="lbl-model-type"
              variant="standard"
              {...getFormikFieldProps(props, "stepLabour", "Step Labour")}
              onChange={(e) => {
                props.setFieldValue("stepLabour", e.target.value);
              }}
              style={{ display: "block" }}
              id="txt-model-type"
            >
              <MenuItem value={StepLabourEnum.Electrical}>Electrical</MenuItem>
              <MenuItem value={StepLabourEnum.Mechanical}>Mechanical</MenuItem>
              <MenuItem value={StepLabourEnum.Operations}>Operations</MenuItem>
              <MenuItem value={StepLabourEnum.ConditionMonitoring}>Condtition Monitoring</MenuItem>
              <MenuItem value={StepLabourEnum.InstrumentTech}>Instrumentation</MenuItem>
              <MenuItem value={StepLabourEnum.Fitter}>Fitter</MenuItem>
              <MenuItem value={StepLabourEnum.CraneDriver}>Crane Driver</MenuItem>
              <MenuItem value={StepLabourEnum.Rigger}>Rigger</MenuItem>
            </Select>
          </FormControl>
        </Grid2>

      <Grid2 size={{xs:12, sm:6}}>
          <TextField
            onChange={props.handleChange}
            type="number"
            fullWidth
            margin="normal"
            variant="standard"
            {...getFormikFieldProps(props, "labourQuantity", "Labour Quantity (Persons)")}
          />
        </Grid2>
        <Grid2 size={{xs:12}}>
          <Divider light={true} />
        </Grid2>
        <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
          <ModalFailureModeCreate
            open={showCreateFailureModeModal}
            onCancelCallback={toggleShowFailureModeCreate}
            modelId={props.modelId}
            onCompleteCallback={toggleShowFailureModeCreate}
          />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormTaskImpactCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    ageReductionFactor: 1,
    failureModeIds: [],
    orderNumber: 0,
    stepDescription: "",
    acceptableLimit: "",
    stepLabour: 0,
    duration: 0,
    labourQuantity: 1,
  }),
  validationSchema: Yup.object().shape({
    failureModeIds: Yup.string().required("Please select a failure mode"),
    stepDescription: Yup.string().label("Step Instruction").max(500, "Please input 500 characters or less"),
    ageReductionFactor: Yup.number()
      .label("Max Value")
      .min(0, "Zero or more.")
      .max(1, "100% or less (1 = 100%).")
      .required("Please provide an age reduction factor"),
    orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, taskId } = props;

    // Map dispatch via props
    var createdTaskImpacts = await dispatch(
      fetchCreateTaskImpact({
        ...values,
        taskId,
      })
    );

    setSubmitting(false);

    if (createdTaskImpacts) onCompleteCallback(createdTaskImpacts);
  },
})(InnerForm);

export default FormTaskImpactCreate;
