import React from "react";
import { Grid2, Divider, Button, FormControlLabel, Switch } from "@mui/material";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { IFunction } from "../../../utilities/types/Function";
import { fetchCreateFunction } from "../../../redux/function/actions";
import StringListInput from "../../generic/widgets/StringListInput";

interface FormValues {
  name: string[];
  description: string;
  orderNumber: number;
  inheritIdentifiers: boolean;
}

interface FormProps {
  modelId: string;
  componentId: string;
  onCompleteCallback(componentFunction?: IFunction): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IFunction, AnyAction>;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  const { values, handleChange } = props;

  const handleNameChange = (newStringList: string[]) => {
    handleChange({
      target: {
        name: "name",
        value: newStringList,
      },
    });
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <Grid2 container spacing={2}>
        <Grid2 size={{xs:12}}>
          <StringListInput
            label="Function Name(s)"
            placeholder="E.g. To Transfer Fluid"
            onStringListChange={handleNameChange}
            stringList={values.name}
            errors={props.errors.name}
          />
        </Grid2>
        <Grid2 size={{xs:12}}>
          <FormControlLabel
            control={
              <Switch
                checked={props.values.inheritIdentifiers}
                onChange={() => {
                  props.setFieldValue("inheritIdentifiers", !props.values.inheritIdentifiers);
                }}
                name="InheritIdentifiers"
              />
            }
            label="Inherit Identifiers"
          />
        </Grid2>
        <Grid2 size={{xs:12}}>
          <Divider light={true} />
        </Grid2>
        <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid2>
      </Grid2>
    </form>
  );
};

const FormFunctionCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    modelId: "",
    name: [],
    description: "",
    orderNumber: 0,
    inheritIdentifiers: true,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.array().of(
      Yup.string()
        .label("Label")
        .min(3, "Please input 3 characters or more")
        .max(500, "Please input 500 characters or less")
        .required("Please provide a name")
    ),
    orderNumber: Yup.number()
      .label("Max Value")
      .min(0, "Zero or more.")
      .required("Please provide an order number")
      .max(999, "Less than 999"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId, componentId } = props;

    const functionsToCreate = values.name.map((name) => ({
      modelId, // Assuming modelId is obtained from some variable
      name,
      description: values.description,
      orderNumber: values.orderNumber,
      inheritIdentifiers: values.inheritIdentifiers,
      componentId,
    }));

    // Map dispatch via props
    var createdFunction = await dispatch(
      fetchCreateFunction({
        functions: functionsToCreate,
      })
    );

    setSubmitting(false);

    if (createdFunction) onCompleteCallback(createdFunction);
  },
})(InnerForm);

export default FormFunctionCreate;
