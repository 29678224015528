import React, { useState } from "react";
import { Grid2, Typography } from "@mui/material";
import { Container } from "@mui/material";
import ModelsRecentWidget from "../ModelsRecentWidget";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { ModelsSearchWidget } from "../ModelsSearchWidget";
import { IModel, ModelStatusEnum } from "../../../utilities/types/Model";
import ModelSearchResultWidget from "../ModelSearchResultWidget";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import SearchIcon from "@mui/icons-material/Search";
import ModelCreateModal from "../../model/modals/ModalModelCreate";
import { GetModelLinkByModel } from "../../../routes/RouteLinkHelpers";
import { sortModelsByTitleAsc } from "../../../redux/model/selectors";
import AnimationWrapper from "../../generic/animations/AnimationWrapper";
import AddNewModelWidget from "../AddNewModelWidget";
import { useFetchIdentifiersPageHook } from "../../identifier/Hooks";
import { useFetchIdentifierMappingsPageHook } from "../../identifierMapping/Hooks";

const PageModelSearch = (routeProps: RouteComponentProps<any>) => {
  const [searchResults, setSearchResults] = useState<IModel[]>([]);
  const [modelCreateModalOpen, setModelCreateModalOpen] = useState(false);
  const history = useHistory();

  // Fetch first page of models
  const { fetching: fetchingIdentifiers } = useFetchIdentifiersPageHook({
    pageNumber: 1,
    pageSize: 200,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingIdentifierMappings } = useFetchIdentifierMappingsPageHook({
    pageNumber: 1,
    pageSize: 200,
    minPageNumberToFetch: 1,
  });

  const setReturnedResults = (models: IModel[], morePages: boolean) => setSearchResults(models);
  const onModelCreateCancelCallback = () => setModelCreateModalOpen(false);
  const viewModelDetails = (model: IModel) => history.push(GetModelLinkByModel(model));

  const onModelCreateCompleteCallback = (model?: IModel) => {
    if (model) {
      setModelCreateModalOpen(false);
      viewModelDetails(model);
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid2 container>
         <Grid2 size={{xs:12}} style={{ marginTop: 16 }}>
          <Typography variant="h5">Search Assets</Typography>
        </Grid2>

         <Grid2 size={{xs:12}} style={{ marginTop: 16 }}>
          <ModelsSearchWidget setReturnedResultSet={setReturnedResults} />
        </Grid2>

        <Grid2 size={{xs:12}}>
          <Grid2 container spacing={3} style={{ marginTop: 16 }} id="search-results-wrapper">
              <AnimationWrapper key={"Test"}>
                <Grid2 size={{xs:12 ,sm:6, md:4}}>
                  <AddNewModelWidget />
                </Grid2>
              </AnimationWrapper>

            {searchResults.length ? (
              sortModelsByTitleAsc(searchResults).map((searchResult, i) => {
                //if (i >= 9) return null;

                return (
                  <AnimationWrapper key={searchResult.modelId}>
                    <Grid2 size={{xs:12 ,sm:6, md:4}}>
                      <ModelSearchResultWidget
                        model={searchResult}
                        loading={fetchingIdentifiers || fetchingIdentifierMappings}
                      />
                    </Grid2>
                  </AnimationWrapper>
                );
              })
            ) : (
              <Grid2 size={{xs:12}}>
                <WidgetNoResultsPlaceholder text="No results" icon={SearchIcon} />
              </Grid2>
            )}
          </Grid2>
        </Grid2>

        <Grid2 size={{xs:12}} style={{ marginTop: 32 }}>
          <Grid2 container spacing={3}>
            <Grid2 size={{xs:12}}>
              <Typography variant="h5">Recently Updated</Typography>
            </Grid2>
            <Grid2 size={{xs:12}}>
              <ModelsRecentWidget loading={false} />
            </Grid2>
          </Grid2>
        </Grid2>

        <ModelCreateModal
          status={ModelStatusEnum.Private}
          onCompleteCallback={onModelCreateCompleteCallback}
          onCancelCallback={onModelCreateCancelCallback}
          open={modelCreateModalOpen}
        />
      </Grid2>
    </Container>
  );
};

export default PageModelSearch;
