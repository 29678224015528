import React, { useState } from "react";
import { Grid2, Divider, Button, Typography, styled } from "@mui/material";

import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetModelById } from "../../redux/model/selectors";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import { selectorGetUserPermissionIsAdmin } from "../../redux/userPermission/selectors";
import { GetUserId } from "../../utilities/ApiUtils";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { ITask, TaskTypeEnum } from "../../utilities/types/Task";
import { blueGrey } from "@mui/material/colors";
import { selectorGetTaskById } from "../../redux/task/selectors";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import PlaceholderIcon from "@mui/icons-material/AssignmentTwoTone";
import ModalTaskUpdate from "./modals/ModalTaskUpdate";

const DivWrapper = styled("div")(({ theme }) => ({
  "& .actionsWrapper": {
    marginTop: theme.spacing(2),
  },
  "& .taskDetailsWrapper": {
    paddingRight: 16,
  },
}));

type TaskMaintenanceSectionProps = {
  modelId: string;
  taskId: string;
};

const TaskMaintenanceSection = ({ modelId, taskId }: TaskMaintenanceSectionProps) => {
  const model = useSelector((state: RootState) => selectorGetModelById(state, modelId));
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state));
  const canEdit = isAdmin || (model != null && model.createdBy === GetUserId());
  const task = useSelector((store: RootState) => selectorGetTaskById(store, taskId));

  return <TaskMaintenanceSectionDisplay modelId={modelId} canEdit={canEdit} task={task} loading={false} />;
};

type TaskMaintenanceSectionDisplayProps = {
  modelId?: string;
  task?: ITask;
  canEdit: boolean;
  loading: boolean;
};

const TaskMaintenanceSectionDisplay = ({ loading, canEdit, task }: TaskMaintenanceSectionDisplayProps) => {
  const [taskToUpdate, setTaskToUpdate] = useState<ITask>();

  function hideTaskUpdate() {
    setTaskToUpdate(undefined);
  }

  function onTaskClick() {
    setTaskToUpdate(task);
  }

  return (
    <WidgetSectionBase
      headerText="Manage Task"
      subheaderText="Update and maintain task properties."
      headerIcon={<PlaceholderIcon />}
      headerColor={blueGrey[800]}
    >
      <DivWrapper>
        <LoaderAbsoluteCentred loading={loading} />
        {!loading && !task && (
          <div style={{ marginTop: 16 }}>
            <WidgetNoResultsPlaceholder text="Task not found." fade={true} icon={PlaceholderIcon} />
          </div>
        )}
        <div className="taskDetailsWrapper">
          <TaskDetails task={task} canEdit={canEdit} />
        </div>
        {canEdit && (
          <>
            <Grid2 container className="actionsWrapper" spacing={2}>
              <Grid2 size={{xs:12}}>
                <Divider light={true} />
              </Grid2>

              <Grid2 size={{xs:12}} style={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  // disabled={submitting}
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: 16 }}
                  onClick={onTaskClick}
                >
                  Update
                </Button>
              </Grid2>
            </Grid2>

            {taskToUpdate && (
              <ModalTaskUpdate
                open={taskToUpdate !== undefined}
                onCancelCallback={hideTaskUpdate}
                onCompleteCallback={hideTaskUpdate}
                task={taskToUpdate}
                canEdit={canEdit}
              />
            )}
          </>
        )}
      </DivWrapper>
    </WidgetSectionBase>
  );
};

const TaskDetails = ({ task, canEdit }: { task?: ITask; canEdit: boolean }) => {
  if (!task) return null;

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={{xs:12}}>
        <Typography variant={"body1"} style={{ marginRight: "16px", marginBottom: "16px", fontWeight: "bold" }}>
          {task.name}
        </Typography>
        <Typography variant={"caption"} style={{ marginRight: "16px" }}>
          Task Description: {task.description}
        </Typography>
        <Typography variant={"caption"} style={{ marginRight: "16px" }}>
          Task Duration: {task.duration}
        </Typography>
        <Typography variant={"caption"} style={{ marginRight: "16px" }}>
          Task Ramp Time: {task.rampTime}
        </Typography>
        <Typography variant={"caption"} style={{ marginRight: "16px" }}>
          Task Type: {TaskTypeEnum.Impact === task.type ? "Planned/corrective" : "Inspection/monitoring"}
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default TaskMaintenanceSection;
