import { Button, Divider, Grid2, TextField } from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";
import { RootState } from "../../../redux";
import { fetchUpdateUserDetail } from "../../../redux/userDetail/actions";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import { IUserDetail } from "../../../utilities/types/UserDetail";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";

interface FormValues {
  displayName: string;
  company?: string;
  linkedIn?: string;
  city?: string;
  country?: string;
  aboutMe?: string;
}

interface FormProps {
  userDetail: IUserDetail;
  onCompleteCallback(userDetail?: IUserDetail): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IUserDetail, AnyAction>;
  canEdit: boolean;
}

const FormUserDetailUpdate = ({ canEdit, ...formProps }: FormProps) => {
  const {
    userDetail: { userDetailId },
  } = formProps;
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch, userDetail } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    setSubmitting(true);

    // Map dispatch via props
    var updatedUserDetail = await dispatch(
      fetchUpdateUserDetail({
        ...values,
        userDetailId: userDetail.userDetailId,
      })
    );

    setSubmitting(false);
    if (updatedUserDetail) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedUserDetail);
    }
  };

  return (
    <Formik
      initialValues={{
        ...formProps.userDetail,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        return (
          <form onSubmit={props.handleSubmit}>
            <fieldset style={{ border: "none" }} disabled={!canEdit}>
              <input type="hidden" value={userDetailId} name="userDetailId" />
              <Grid2 container spacing={2}>
              <Grid2 size={{xs:12, sm:6}}>
                  <TextField
                    onChange={props.handleChange}
                    {...getFormikFieldProps(props, "displayName", "Display Name")}
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />
                </Grid2>

              <Grid2 size={{xs:12, sm:6}}>
                  <TextField
                    onChange={props.handleChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    {...getFormikFieldProps(props, "company", "Company")}
                  />
                </Grid2>

              <Grid2 size={{xs:12, sm:6}}>
                  <TextField
                    onChange={props.handleChange}
                    {...getFormikFieldProps(props, "title", "Title")}
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />
                </Grid2>

              <Grid2 size={{xs:12, sm:6}}>
                  <TextField
                    onChange={props.handleChange}
                    {...getFormikFieldProps(props, "linkedIn", "LinkedIn")}
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />
                </Grid2>

              <Grid2 size={{xs:12, sm:6}}>
                  <TextField
                    onChange={props.handleChange}
                    {...getFormikFieldProps(props, "city", "City")}
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />
                </Grid2>

              <Grid2 size={{xs:12, sm:6}}>
                  <TextField
                    onChange={props.handleChange}
                    {...getFormikFieldProps(props, "country", "Country")}
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />
                </Grid2>

                <Grid2 size={{xs:12}}>
                  <TextField
                    onChange={props.handleChange}
                    multiline
                    rows={3}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    {...getFormikFieldProps(props, "aboutMe", "About")}
                  />
                </Grid2>

                <Grid2 size={{xs:12}}>
                  <Divider light={true} />
                </Grid2>
                {canEdit && (
                  <Grid2 size={{xs:12}}>
                    <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                      <Button
                        type="submit"
                        disabled={submitting}
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: 16 }}
                      >
                        Update
                      </Button>

                      <LoaderAbsoluteCentred loading={submitting} />
                    </div>
                  </Grid2>
                )}
              </Grid2>
            </fieldset>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          displayName: Yup.string()
            .label("Label")
            .min(3, "Please input 3 characters or more")
            .max(50, "Please input 50 characters or less")
            .required("Please provide a display name"),
          company: Yup.string()
            .label("Company")
            .nullable()
            .min(3, "Please input 3 characters or more")
            .max(50, "Please input 50 characters or less"),
          linkedIn: Yup.string().label("LinkedIn").nullable().max(500, "Please input 500 characters or less"),
          city: Yup.string().label("City").nullable().max(500, "Please input 50 characters or less"),
          country: Yup.string().label("Country").nullable().max(500, "Please input 50 characters or less"),
          aboutMe: Yup.string().label("About Me").nullable().max(500, "Please input 500 characters or less"),
        });
      }}
    />
  );
};

export default FormUserDetailUpdate;
