import React, { useState } from "react";
import { Grid2 } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import { selectorGetFunctionalFailuresByFunctionId } from "../../redux/functionalFailure/selectors";
import { IFunctionalFailure } from "../../utilities/types/FunctionalFailure";
import FunctionalFailureRenderer from "./FunctionalFailureRenderer";
import FailureModesRenderer from "../failureMode/FailureModesRenderer";
import ModalFunctionalFailureUpdate from "./modals/ModalFunctionalFailureUpdate";
import { IFunction } from "../../utilities/types/Function";
import ModelBuilderNewFunctionalFailure from "./ModelBuilderNewFunctionalFailure";
import ModalFunctionalFailureClone from "./modals/ModalFunctionalFailureClone";

type IFunctionalFailuresRendererProps = {
  canEdit: boolean;
  function: IFunction;
  loading: boolean;
};

const FunctionalFailuresRenderer = (props: IFunctionalFailuresRendererProps) => {
  const functionalFailures = useSelector((store: RootState) =>
    selectorGetFunctionalFailuresByFunctionId(store, props.function.functionId)
  );

  return <FunctionalFailuresRendererDisplay {...props} functionalFailures={functionalFailures} />;
};

interface IFunctionalFailuresRendererDisplayProps extends IFunctionalFailuresRendererProps {
  functionalFailures: IFunctionalFailure[];
}

function FunctionalFailuresRendererDisplay(props: IFunctionalFailuresRendererDisplayProps) {
  const { functionalFailures, canEdit } = props;
  const [loading] = useState(false);
  const [functionalFailureToEdit, setFunctionalFailureToEdit] = useState<IFunctionalFailure>();
  const [functionalFailureToClone, setFunctionalFailureToClone] = useState<IFunctionalFailure>();

  async function CloseEditFunctionalFailureModal() {
    setFunctionalFailureToEdit(undefined);
  }

  async function CloseCloneFunctionalFailureModal() {
    setFunctionalFailureToClone(undefined);
  }

  return (
    <AnimationWrapper>
      <Grid2 container>
        <Grid2 size={{xs:12}}>
            <ModelBuilderNewFunctionalFailure
              componentId={props.function.componentId}
              functionId={props.function.functionId}
              modelId={props.function.modelId}
            />
          {functionalFailures.map((componentFunctionalFailure) => {
            return (
              <React.Fragment key={componentFunctionalFailure.functionalFailureId}>
                <FunctionalFailureRenderer
                  functionalFailureId={componentFunctionalFailure.functionalFailureId}
                  canEdit={canEdit}
                  editFunctionalFailure={() => setFunctionalFailureToEdit(componentFunctionalFailure)}
                  cloneFunctionalFailure={() => setFunctionalFailureToClone(componentFunctionalFailure)}
                />
                <FailureModesRenderer
                  componentId={props.function.componentId}
                  functionalFailure={componentFunctionalFailure}
                  canEdit={canEdit}
                  loading={loading}
                />
              </React.Fragment>
            );
          })}

          <LoaderAbsoluteCentred loading={loading} />
        </Grid2>
        {functionalFailureToEdit ? (
          <ModalFunctionalFailureUpdate
            onCancelCallback={CloseEditFunctionalFailureModal}
            onCompleteCallback={CloseEditFunctionalFailureModal}
            functionalFailure={functionalFailureToEdit}
            canEdit={canEdit}
            open={true}
          />
        ) : null}
        {functionalFailureToClone ? (
          <ModalFunctionalFailureClone
            onCancelCallback={CloseCloneFunctionalFailureModal}
            onCompleteCallback={CloseCloneFunctionalFailureModal}
            functionalFailure={functionalFailureToClone}
            open={true}
            modelId={functionalFailureToClone.modelId}
          />
        ) : null}
      </Grid2>
    </AnimationWrapper>
  );
}

export default FunctionalFailuresRenderer;
