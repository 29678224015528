import React, { useState } from "react";
import { TextField, Grid2, Divider, Button, FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { ITaskApplicability, StepLabourEnum } from "../../../utilities/types/TaskApplicability";
import { fetchUpdateTaskApplicability, fetchDeleteTaskApplicability } from "../../../redux/taskApplicability/actions";
import { selectorGetFailureModesByModelId } from "../../../redux/failureMode/selectors";
import { selectorGetEventsByModelId } from "../../../redux/event/selectors";
import { Autocomplete } from "@mui/lab";
import { IFailureMode } from "../../../utilities/types/FailureMode";
import { IEvent } from "../../../utilities/types/Event";
import { selectorGetUserPermissionIsAdmin } from "../../../redux/userPermission/selectors";
import { ModelStatusEnum } from "../../../utilities/types/Model";
import TimeConverter from "./TimeConverter";

interface FormValues {
  failureModeId: string;
  detectionProbability: number;
  pfInterval: number;
  orderNumber: number;
  stepDescription: string;
  subsequentEventId?: string;
  acceptableLimit: string;
  duration: number;
  stepLabour: number;
  labourQuantity: number;
}

interface FormProps {
  taskApplicability: ITaskApplicability;
  failureModeObj?: IFailureMode;
  subsequentEventObj?: IEvent;
  onCompleteCallback(taskApplicability?: ITaskApplicability): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ITaskApplicability, AnyAction>;
}

const FormTaskApplicabilityUpdate = (formProps: FormProps) => {
  const {
    onCancelCallback,
    taskApplicability: { taskApplicabilityId, modelId },
  } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);
  const failureModes = useSelector((store: RootState) => selectorGetFailureModesByModelId(store, modelId));
  const events = useSelector((store: RootState) => selectorGetEventsByModelId(store, modelId));
  const isAdmin: boolean = useSelector((store: RootState) => selectorGetUserPermissionIsAdmin(store));

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch, taskApplicability } = formProps;

    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var updatedTaskApplicability = await dispatch(
      fetchUpdateTaskApplicability({
        ...values,
        taskApplicabilityId: taskApplicability.taskApplicabilityId,
      })
    );

    setSubmitting(false);
    if (updatedTaskApplicability) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedTaskApplicability);
    }
  };

  return (
    <Formik
      initialValues={{
        ...formProps.taskApplicability,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteTaskApplicability({ taskApplicabilityId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={taskApplicabilityId} name="taskApplicabilityId" />
            <Grid2 container spacing={2}>
              <Grid2 size={{xs:12}}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "stepDescription", "Step Description")}
                  fullWidth
                  margin="normal"
                  multiline
                  minRows={3}
                  maxRows={3}
                  variant="standard"
                />
              </Grid2>
            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "detectionProbability", "Detection Probability")}
                />
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <TimeConverter
                  label="PF Interval (Hours)"
                  defaultValueOverride={props.values.pfInterval}
                  onValueChangeCallback={(value) => {
                    props.setFieldValue("pfInterval", value);
                  }}
                  error={props.errors.pfInterval}
                />
              </Grid2>
              <Grid2 size={{xs:12}}>
                <FormControl fullWidth={true}>
                  <Autocomplete
                    {...getFormikFieldProps(props, "subsequentEventObj", "Subsequent Event")}
                    style={{ display: "block" }}
                    options={events}
                    getOptionLabel={(option) => option.name}
                    defaultValue={events.find((element) => {
                      return element.eventId === props.values.subsequentEventId;
                    })}
                    onChange={(_, value) => {
                      props.setFieldValue("subsequentEventId", value ? value.eventId : "");
                      props.setFieldValue("subsequentEventObj", value);
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} label="Subsequent Event" />}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={{xs:12}}>
                <FormControl fullWidth={true}>
                  <Autocomplete
                    {...getFormikFieldProps(props, "failureModeObj", "Failure Mode")}
                    style={{ display: "block" }}
                    options={failureModes}
                    getOptionLabel={(option) => option.name}
                    defaultValue={failureModes.find((element) => {
                      return element.failureModeId === props.values.failureModeId;
                    })}
                    onChange={(_, value) => {
                      props.setFieldValue("failureModeId", value ? value.failureModeId : "");
                      props.setFieldValue("failureModeObj", value);
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} label="Failure Mode" required />}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={{xs:12}}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "acceptableLimit", "Acceptable Limit")}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid2>
              <Grid2 size={{xs:12}}>
                <FormControl fullWidth={true} margin="normal">
                  <InputLabel id="lbl-model-type" variant="standard">
                    Step Labour
                  </InputLabel>
                  <Select
                    labelId="lbl-model-type"
                    variant="standard"
                    {...getFormikFieldProps(props, "stepLabour", "Step Labour")}
                    onChange={(e) => {
                      props.setFieldValue("stepLabour", e.target.value);
                    }}
                    style={{ display: "block" }}
                    id="txt-model-type"
                  >
                    <MenuItem value={StepLabourEnum.Electrical}>Electrical</MenuItem>
                    <MenuItem value={StepLabourEnum.Mechanical}>Mechanical</MenuItem>
                    <MenuItem value={StepLabourEnum.Operations}>Operations</MenuItem>
                    <MenuItem value={StepLabourEnum.ConditionMonitoring}>Condtition Monitoring</MenuItem>
                    <MenuItem value={StepLabourEnum.InstrumentTech}>Instrumentation</MenuItem>
                    <MenuItem value={StepLabourEnum.Fitter}>Fitter</MenuItem>
                    <MenuItem value={StepLabourEnum.CraneDriver}>Crane Driver</MenuItem>
                    <MenuItem value={StepLabourEnum.Rigger}>Rigger</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>

            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "duration", "Duration  (Hours)")}
                />
              </Grid2>
            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  onChange={props.handleChange}
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="standard"
                  {...getFormikFieldProps(props, "labourQuantity", "Labour Quantity  (Persons)")}
                />
              </Grid2>
              {isAdmin ? (
                <Grid2 size={{xs:12}}>
                  <FormControl fullWidth={true}>
                    <InputLabel id="lbl-model-type" variant="standard">
                      Status
                    </InputLabel>
                    <Select
                      labelId="lbl-model-type"
                      variant="standard"
                      {...getFormikFieldProps(props, "status", "Status")}
                      onChange={(e) => {
                        props.setFieldValue("status", e.target.value);
                      }}
                      style={{ display: "block" }}
                      id="txt-model-type"
                    >
                      <MenuItem value={ModelStatusEnum.Private}>Private</MenuItem>
                      <MenuItem value={ModelStatusEnum.Public}>Public</MenuItem>
                      <MenuItem value={ModelStatusEnum.Draft}>Draft</MenuItem>
                      <MenuItem value={ModelStatusEnum.PendingEdits}>Pending Edits</MenuItem>
                    </Select>
                  </FormControl>
                </Grid2>
              ) : null}
            <Grid2 size={{xs:12, sm:6}}>
                <TextField
                  variant="standard"
                  onChange={props.handleChange}
                  type="number"
                  fullWidth
                  margin="normal"
                  {...getFormikFieldProps(props, "orderNumber", "Order Number")}
                />
              </Grid2>
              <Grid2 size={{xs:12}}>
                <Divider light={true} />
              </Grid2>
              <Grid2 size={{xs:12}}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ flexBasis: "33%" }}
                    onClick={toggleDeleteConfirmation}
                  >
                    Delete
                  </Button>
                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      type="submit"
                      disabled={submitting}
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: 16 }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting || deleting} />
                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete taskApplicability"
                  subtitle="Confirm taskApplicability delete"
                  description="Are you sure that you'd like to remove this taskApplicability?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteHandler}
                  confirmButtonText="Delete"
                />
              </Grid2>
            </Grid2>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          stepDescription: Yup.string().required("Please enter a step description"),
          failureModeId: Yup.string().label("Failure mode").required("Please select a failure mode"),
          orderNumber: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999, "Less than 999"),
          detectionProbability: Yup.number().required(),
          pfInterval: Yup.number().required(),
        });
      }}
    />
  );
};

export default FormTaskApplicabilityUpdate;
