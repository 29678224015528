import { FormControl, FormHelperText, Grid2, InputLabel, TextField } from "@mui/material";
import React, { useCallback } from "react";
import { useSplitDateTime, useUpdateSplitDateTime } from "../../utilities/inputFields/dateTimeFieldUtils";
import { TSolverInputFieldValue } from "../../utilities/types/Solver";
import { ISolverInputField } from "../../utilities/types/SolverInputField";

interface ISolverInputFieldDisplayNumberProps {
  solverInputField: ISolverInputField;
  disabled?: boolean;
  onValueChangeCallback(fieldId: string, value: TSolverInputFieldValue): void;
  defaultValueOverride?: TSolverInputFieldValue;
}

export default function SolverInputFieldDisplayDateTime({
  solverInputField,
  defaultValueOverride,
  onValueChangeCallback,
  disabled = false,
}: ISolverInputFieldDisplayNumberProps) {
  const onDateChange = useCallback(
    (valueDateTime: any) => onValueChangeCallback(solverInputField.solverInputFieldId, { valueDateTime }),
    [solverInputField.solverInputFieldId, onValueChangeCallback]
  );

  const defaultValue = defaultValueOverride?.valueDateTime || solverInputField.defaultDateTime;
  const [dateState, timeState, setDate, setTime, error] = useUpdateSplitDateTime(defaultValue, onDateChange);

  // If the field is display then show the default value
  const [dateDisplay, timeDisplay] = useSplitDateTime(defaultValue);
  const date = disabled ? dateDisplay : dateState;
  const time = disabled ? timeDisplay : timeState;

  return (
    <FormControl fullWidth>
      <InputLabel style={{ position: "relative", transform: "translate(0, 1.5px) scale(0.75)" }}>
        {solverInputField.description}
      </InputLabel>
      <Grid2 container spacing={2}>
      <Grid2 size={{xs:12, sm:6}}>
          <TextField
            type="date"
            value={date}
            onChange={(e) => setDate(e.currentTarget.value)}
            error={!!error}
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="standard"
            {...(disabled === true ? { disabled } : null)}
          />
        </Grid2>

      <Grid2 size={{xs:12, sm:6}}>
          <TextField
            type="time"
            value={time}
            onChange={(e) => setTime(e.currentTarget.value)}
            error={!!error}
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 300 }}
            variant="standard"
            {...(disabled === true ? { disabled } : null)}
          />
        </Grid2>
      </Grid2>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
}
