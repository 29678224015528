import React, { useState, useEffect } from "react";
import { Typography, IconButton, Grid2, Button, Tooltip, styled } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetModelById } from "../../redux/model/selectors";
import { IModel } from "../../utilities/types/Model";
import { IComponent } from "../../utilities/types/Component";
import { selectorGetComponentsByModelId } from "../../redux/component/selectors";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import ModalComponentCreate from "./modals/ModalComponentCreate";
import { fetchSearchComponents, EnableComponentHierachy } from "../../redux/component/actions";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import ModalComponentUpdate from "./modals/ModalComponentUpdate";
import FunctionsRenderer from "../function/FunctionsRenderer";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import PlaceholderIcon from "@mui/icons-material/SettingsTwoTone";
import ModelBuilderComponent from "../model/ModelBuilderComponent";
import ExpandIcon from "@mui/icons-material/ExpandMoreTwoTone";
import CollapseIcon from "@mui/icons-material/ExpandLessTwoTone";
import ModelBuilderNewComponent from "./ModelBuilderNewComponent";

const GridWrapper = styled(Grid2)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  "& .expandCollapseButton": {
    fontWeight: 500,
    textTransform: "capitalize",
    marginRight: theme.spacing(3),
    transition: "background 200ms ease-out",
    width: "100%",
    marginTop: `${theme.spacing(2)} !important`,
    marginBottom: `${theme.spacing(2)} !important`,
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    alignItems: "center",
  },
}));

const ComponentSectionWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(4),
  "& .expandComponentIcon": {
    position: "absolute",
    left: "50%",
    bottom: "-16px",
    border: "1px solid #EEE",
    backgroundColor: "#FFF",
    padding: 3,
    "&:hover": {
      backgroundColor: "#EEE",
    },
  },
}));

type IComponentModelBuilderTabProps = {
  modelId: string;
  canEdit: boolean;
  canDiscuss?: boolean;
  loading: boolean;
};

const ComponentModelBuilderTab = ({ modelId, canEdit, canDiscuss, loading }: IComponentModelBuilderTabProps) => {
  const model = useSelector((state: RootState) => selectorGetModelById(state, modelId));
  const dispatch = useDispatch();
  const components = useSelector((store: RootState) => selectorGetComponentsByModelId(store, modelId));

  const onUploadComplete = async (componentId: string) => {
    await dispatch(fetchSearchComponents({ componentId, modelId, pageNumber: 1, pageSize: 1 }));
  };

  return (
    <ComponentModelBuilderTabDisplay
      model={model}
      canEdit={canEdit}
      components={components}
      canDiscuss={canDiscuss}
      loading={loading}
      onUploadComplete={onUploadComplete}
    />
  );
};

type IComponentModelBuilderTabDisplayProps = {
  canEdit: boolean;
  model?: IModel;
  canDiscuss?: boolean;
  components: IComponent[];
  loading: boolean;
  onUploadComplete(componentId: string): void;
};

const ComponentModelBuilderTabDisplay = ({
  model,
  components,
  loading,
  canEdit,
  onUploadComplete,
  canDiscuss,
}: IComponentModelBuilderTabDisplayProps) => {
  const [showCreateComponentModal, setShowCreateComponentModal] = useState(false);
  const [expandedHeirarchy, setExpandedHeirarchy] = useState(false);
  const dispatch = useDispatch();
  const [elementsEnabled, setElementsEnabled] = useState<boolean>(false);
  const [preparingToEnable, setPreparingToEnable] = useState<boolean>(false);

  function toggleShowCreateComponentModal() {
    setShowCreateComponentModal(!showCreateComponentModal);
  }

  function onCompleteCallback() {
    dispatch(EnableComponentHierachy({ model: model as IModel }));
    setShowCreateComponentModal(!showCreateComponentModal);
  }
  function toggleAll() {
    setExpandedHeirarchy(!expandedHeirarchy);
  }

  useEffect(() => {
    if (!elementsEnabled && !loading && model && components && components.length) {
      setPreparingToEnable(true);
      setTimeout(() => {
        dispatch(EnableComponentHierachy({ model }));
        setPreparingToEnable(false);
      }, 1250);
      setElementsEnabled(true);
    }
  }, [model, dispatch, components, loading, setElementsEnabled, elementsEnabled]);

  if (!model) return <Typography variant="caption">Model not found.</Typography>;

  return (
    <div>
      {loading ? (
        <LoaderAbsoluteCentred loading={loading} />
      ) : (
        <AnimationWrapper>
          <div>
              <Grid2 container>
                <Grid2 size={{xs:11}}>
                  <ModelBuilderNewComponent model={model} />
                </Grid2>
                <GridWrapper  size={{xs:1}}>
                  <Tooltip title="Expand / Collapse All">
                    <Button
                      id="btn-expand-component"
                      variant="outlined"
                      className="expandCollapseButton"
                      onClick={toggleAll}
                    >
                      {expandedHeirarchy ? <CollapseIcon /> : <ExpandIcon />}
                    </Button>
                  </Tooltip>
                </GridWrapper>
              </Grid2>
            {components.map((component) => (
              <ComponentHierachyRenderer
                key={component.componentId}
                component={component}
                onUploadComplete={onUploadComplete}
                canEdit={canEdit}
                canDiscuss={canDiscuss}
                expanded={expandedHeirarchy}
              />
            ))}
            {(!components || !components.length) && (
              <WidgetNoResultsPlaceholder text="No components" icon={PlaceholderIcon} spin={true} />
            )}
            <LoaderAbsoluteCentred loading={preparingToEnable} />
          </div>
        </AnimationWrapper>
      )}

      <ModalComponentCreate
        open={showCreateComponentModal}
        onCancelCallback={toggleShowCreateComponentModal}
        onCompleteCallback={onCompleteCallback}
        modelId={model.modelId}
      />
    </div>
  );
};

interface IComponentHieracyRenderer {
  onUploadComplete(componentId: string): void;
  component: IComponent;
  canEdit: boolean;
  canDiscuss?: boolean;
  expanded: boolean;
}

function ComponentHierachyRenderer({
  component,
  canEdit,
  onUploadComplete,
  canDiscuss,
  expanded,
}: IComponentHieracyRenderer) {
  const [componentToUpdate, setComponentToUpdate] = useState<IComponent>();
  const [expandedItem, setExpandedItem] = useState(expanded);
  const model = useSelector((store: RootState) => selectorGetModelById(store, component.modelId));
  const dispatch = useDispatch();

  function onComponentClick(component: IComponent) {
    setComponentToUpdate(component);
  }

  function hideComponentUpdate() {
    setComponentToUpdate(undefined);
  }

  function onCompleteCallback() {
    dispatch(EnableComponentHierachy({ model: model as IModel }));
    setComponentToUpdate(undefined);
  }

  function Test() {
    setExpandedItem(!expandedItem);
  }

  useEffect(() => {
    setExpandedItem(expanded);
  }, [expanded]);

  return (
    <ComponentSectionWrapper>
      <ModelBuilderComponent
        component={component}
        canEdit={canEdit}
        onUploadComplete={onUploadComplete}
        onComponentClick={onComponentClick}
        canDiscuss={canDiscuss}
      />
      {expandedItem ? (
        <>
          <FunctionsRenderer component={component} canEdit={canEdit} loading={false} />
        </>
      ) : null}
      <IconButton onClick={Test} className="expandComponentIcon">
        {expandedItem ? <CollapseIcon /> : <ExpandIcon />}
      </IconButton>
      {componentToUpdate && (
        <ModalComponentUpdate
          open={componentToUpdate !== undefined}
          onCancelCallback={hideComponentUpdate}
          onCompleteCallback={onCompleteCallback}
          component={componentToUpdate}
          canEdit={canEdit}
        />
      )}
    </ComponentSectionWrapper>
  );
}

export default ComponentModelBuilderTab;
