import React, { useEffect, useState } from "react";
import {Grid2} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { Typography, ListItemAvatar, Avatar, styled, ListItemButton } from "@mui/material";
import { HumanizeString, AddSpacesToSentence } from "../../utilities/Helpers";
import { EntityPermissionTypeEnum } from "../../utilities/types/EntityPermission";

const GridWrapper = styled(Grid2)(({ theme }) => ({
  margin: "auto",
  "& .paper": {
    width: 200,
    height: 230,
    overflow: "auto",
    border: "1px solid rgba(0,0,0,0.1)",
    boxShadow: "none",
    padding: 0,
  },
  "& .button": {
    margin: theme.spacing(0.5, 0),
  },
  "& .listItem": {
    border: "1px solid rgba(0,0,0,0.1)",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    width: "calc(100% - 16px)",
    overflow: "hidden",
    backgroundColor: "rgba(255,255,255,1)",
  },
  "& .listLeft": {
    backgroundColor: "rgba(0,0,0,0.02)",
  },
  "& .listRight": {
    backgroundColor: "rgba(0,0,0,0.02)",
  },
}));

function not(a: number[], b: number[]) {
  return a.filter((aVal) => !b.some((c) => c === aVal));
}

function getUserPermissionsByMappings(userPermissions: number[], mappings: number[]) {
  var test = userPermissions.filter((x) => mappings.some((y) => y === x));
  return test;
}

interface IEntityPermissionPicklistProps {
  userDetailId: string;
  onSelectedItemsChange(mappings: number[]): void;
  allEntityPermissions: number[];
  entityPermissionMappings: number[];
  leftLabel?: string;
  rightLabel?: string;
}

export default function EntityPermissionPicklist({
  allEntityPermissions,
  entityPermissionMappings,
  onSelectedItemsChange,
  leftLabel,
  rightLabel,
}: IEntityPermissionPicklistProps) {
  const [right, setRight] = useState<number[]>(
    getUserPermissionsByMappings(allEntityPermissions, entityPermissionMappings)
  );
  const [left, setLeft] = useState<number[]>(not(allEntityPermissions, right));
  const [assignedMappingsCount, setAssignedMappingsCount] = useState<number>(entityPermissionMappings.length);
  const [userPermissionsCount, setUserPermissionsCount] = useState<number>(allEntityPermissions.length);

  function selectItem(clickedItem: number, rightItems: boolean) {
    if (rightItems) {
      let mergedItems = [...left, clickedItem];
      setLeft(mergedItems);
      setRight(not(allEntityPermissions, mergedItems));
    } else {
      let mergedItems = [...right, clickedItem];
      setRight(mergedItems);
      setLeft(not(allEntityPermissions, mergedItems));
    }
  }

  // Allow parent component to hook into current items
  useEffect(() => {
    onSelectedItemsChange(right);
  }, [right, onSelectedItemsChange]);

  // Ensure that mappings not initially available are still catered for
  useEffect(() => {
    if (
      entityPermissionMappings.length !== assignedMappingsCount ||
      allEntityPermissions.length !== userPermissionsCount
    ) {
      setAssignedMappingsCount(entityPermissionMappings.length);
      setUserPermissionsCount(allEntityPermissions.length);

      var rightUserPermissions = getUserPermissionsByMappings(allEntityPermissions, entityPermissionMappings);
      setRight(rightUserPermissions);
      setLeft(not(allEntityPermissions, rightUserPermissions));
    }
  }, [entityPermissionMappings, assignedMappingsCount, allEntityPermissions, userPermissionsCount]);

  const customList = (items: number[], right: boolean) => (
    <Paper className={`paper ${right ? "listRight" : "listLeft"}`}>
      <List dense component="div" role="list" style={{ paddingTop: 0 }}>
        {items.map((value) => {
          return <CustomListItem userPermission={value} onClick={() => selectItem(Number(value), right)} />;
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <GridWrapper container spacing={2} justifyContent="center" alignItems="center">
      <Grid2>
        <Typography variant="overline">{leftLabel || "Unlinked"}</Typography>
        {customList(left, false)}
      </Grid2>
      <Grid2>
        <Typography variant="overline">{rightLabel || "Linked"}</Typography>
        {customList(right, true)}
      </Grid2>
    </GridWrapper>
  );
}

function CustomListItem({ userPermission, onClick }: { userPermission: number; onClick(): void }) {
  const labelId = `transfer-list-item-${userPermission}-label`;
  const label = EntityPermissionTypeEnum[userPermission];

  return (
    <ListItemButton key={userPermission} role="listitem" onClick={onClick} className="listItem">
      <ListItemAvatar>
        <Avatar>{userPermission}</Avatar>
      </ListItemAvatar>
      <ListItemText id={labelId} primary={HumanizeString(AddSpacesToSentence(label))} />
    </ListItemButton>
  );
}
