import React, { useState } from "react";
import { TextField, Grid2, Divider, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { getFormikFieldProps, ShowMessage, UpdateMessage } from "../../../utilities/Helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { MessageTypeEnum } from "../../../utilities/types/Message";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { IComponent } from "../../../utilities/types/Component";
import { fetchUpdateComponent, fetchDeleteComponent } from "../../../redux/component/actions";
import { ModelStatusEnum } from "../../../utilities/types/Model";
import { selectorGetUserPermissionIsAdmin } from "../../../redux/userPermission/selectors";

interface FormValues {
  name: string;
  description: string;
  orderNumber: number;
  primary: boolean;
}

interface FormProps {
  component: IComponent;
  onCompleteCallback(component?: IComponent): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IComponent, AnyAction>;
}

const FormComponentUpdate = (formProps: FormProps) => {
  const {
    onCancelCallback,
    component: { componentId },
  } = formProps;
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state));

  const onSubmit = async (values: FormValues) => {
    const { onCompleteCallback, dispatch, component } = formProps;

    setSubmitting(true);
    var message = await ShowMessage("Updating", MessageTypeEnum.Information);

    // Map dispatch via props
    var updatedComponent = await dispatch(
      fetchUpdateComponent({
        ...values,
        componentId: component.componentId,
      })
    );

    setSubmitting(false);
    if (updatedComponent) {
      UpdateMessage({ ...message, text: "Updated", type: MessageTypeEnum.Success });
      onCompleteCallback(updatedComponent);
    }
  };

  return (
    <Formik
      initialValues={{
        ...formProps.component,
      }}
      onSubmit={onSubmit}
      render={(props) => {
        async function deleteHandler() {
          // Inform user
          setDeleting(true);
          var message = await ShowMessage("Removing...", MessageTypeEnum.Information);
          setShowDeleteConfirmation(false);

          // Perform delete
          var resp = await dispatch(fetchDeleteComponent({ componentId }));

          // Cleanup
          setDeleting(false);
          if (resp != null) {
            UpdateMessage({ ...message, text: "Removed", type: MessageTypeEnum.Success });
            onCancelCallback();
          }
        }

        return (
          <form onSubmit={props.handleSubmit}>
            <input type="hidden" value={componentId} name="componentId" />
            <Grid2 container spacing={2}>
              <Grid2 size={{xs:12}}>
                <TextField
                  onChange={props.handleChange}
                  {...getFormikFieldProps(props, "name", "Name")}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid2>
              {isAdmin ? (
                <Grid2 size={{xs:12}}>
                  <TextField
                    onChange={props.handleChange}
                    type="number"
                    variant="standard"
                    fullWidth
                    defaultValue={0}
                    margin="normal"
                    {...getFormikFieldProps(props, "orderNumber", "Order Number")}
                  />
                </Grid2>
              ) : null}

              <Grid2 size={{xs:12}}>
                <TextField
                  variant="standard"
                  onChange={props.handleChange}
                  multiline
                  rows={3}
                  fullWidth
                  margin="normal"
                  {...getFormikFieldProps(props, "description", "Description")}
                />
              </Grid2>
              {isAdmin ? (
                <Grid2 size={{xs:12}}>
                  <FormControl fullWidth={true}>
                    <InputLabel id="lbl-model-type" variant="standard">
                      Status
                    </InputLabel>
                    <Select
                      labelId="lbl-model-type"
                      {...getFormikFieldProps(props, "status", "Status")}
                      onChange={(e) => {
                        props.setFieldValue("status", e.target.value);
                      }}
                      style={{ display: "block" }}
                      id="txt-model-type"
                      variant="standard"
                    >
                      <MenuItem value={ModelStatusEnum.Private}>Private</MenuItem>
                      <MenuItem value={ModelStatusEnum.Public}>Public</MenuItem>
                      <MenuItem value={ModelStatusEnum.Draft}>Draft</MenuItem>
                      <MenuItem value={ModelStatusEnum.PendingEdits}>Pending Edits</MenuItem>
                    </Select>
                  </FormControl>
                </Grid2>
              ) : null}

              <Grid2 size={{xs:12}}>
                <Divider light={true} />
              </Grid2>
              <Grid2 size={{xs:12}}>
                <div style={{ flexBasis: "100%", display: "flex" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ flexBasis: "33%" }}
                    onClick={toggleDeleteConfirmation}
                  >
                    Delete
                  </Button>
                  <div style={{ flexBasis: "66%", justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      disabled={submitting}
                      variant="text"
                      onClick={onCancelCallback}
                      style={{ flexBasis: "25%" }}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      type="submit"
                      disabled={submitting}
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: 16 }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
                <LoaderAbsoluteCentred loading={submitting || deleting} />
                <WidgetModalConfirmationDialog
                  open={showDeleteConfirmation}
                  title="Delete component"
                  subtitle="Confirm component delete"
                  description="Are you sure that you'd like to remove this component?"
                  onCancelCallback={toggleDeleteConfirmation}
                  onConfirmCallback={deleteHandler}
                  confirmButtonText="Delete"
                />
              </Grid2>
            </Grid2>
          </form>
        );
      }}
      validationSchema={() => {
        return Yup.object().shape({
          name: Yup.string()
            .label("Label")
            .min(3, "Please input 3 characters or more")
            .max(50, "Please input 50 characters or less")
            .required("Please provide a name"),
          description: Yup.string().label("Description").max(500, "Please input 500 characters or less"),
          orderNumber: Yup.number()
            .label("Max Value")
            .min(0, "Zero or more.")
            .required("Please provide an order number")
            .max(999, "Less than 999"),
        });
      }}
    />
  );
};

export default FormComponentUpdate;
