import {
  FormControl,
  FormHelperText,
  Grid2,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TimeConverterInputFieldTypeEnum } from "../../../utilities/types/TimeConverterInputField";
import { convertFromHours, convertToHours } from "../../../utilities/timeConverterUtils";

interface ITimeConverterProps {
  onValueChangeCallback(value: number): void;
  defaultValueOverride?: number;
  label?: string;
  error?: string;
  step?: number;
}

export default function TimeConverter({
  defaultValueOverride,
  onValueChangeCallback,
  label,
  error,
  step = 0.1,
}: ITimeConverterProps) {
  const [timeValue, setTimeValue] = useState("0");
  const [unit, setUnit] = useState<TimeConverterInputFieldTypeEnum>(TimeConverterInputFieldTypeEnum.Hours);

  useEffect(() => {
    const hours = convertToHours(timeValue, unit);
    onValueChangeCallback(hours);
  }, [unit, timeValue]);

  useEffect(() => {
    if (defaultValueOverride) {
      const { value, unit: defaultUnit } = convertFromHours(defaultValueOverride);
      if (value) setTimeValue(value.toString());
      if (unit) setUnit(defaultUnit as TimeConverterInputFieldTypeEnum);
    }
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel style={{ position: "relative", transform: "translate(0, 1.5px) scale(0.75)" }}>{label}</InputLabel>
      <Grid2 container spacing={0}>
        <Grid2 size={{xs:12,sm:12}}>
          <TextField
            type="number"
            variant="standard"
            fullWidth
            margin="dense"
            inputProps={{ min: 0, step, required: false }}
            value={timeValue}
            onChange={(event) => setTimeValue(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Select
                    style={{ display: "block" }}
                    variant="standard"
                    value={unit}
                    id="time-select"
                    disableUnderline={true}
                    onChange={(event) => setUnit(event.target.value as TimeConverterInputFieldTypeEnum)}
                  >
                    <MenuItem value={TimeConverterInputFieldTypeEnum.Hours}>Hours</MenuItem>
                    <MenuItem value={TimeConverterInputFieldTypeEnum.Days}>Days</MenuItem>
                    <MenuItem value={TimeConverterInputFieldTypeEnum.Weeks}>Weeks</MenuItem>
                    <MenuItem value={TimeConverterInputFieldTypeEnum.Months}>Months</MenuItem>
                    <MenuItem value={TimeConverterInputFieldTypeEnum.Years}>Years</MenuItem>
                  </Select>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
      </Grid2>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
}
