import {
  Button,
  Divider,
  Grid2,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import EditIcon from "@mui/icons-material/EditTwoTone";
import { IEvent } from "../../utilities/types/Event";
import { IModel } from "../../utilities/types/Model";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import ModalEventCreate from "./modals/ModalEventCreate";
import ModalEventUpdate from "./modals/ModalEventUpdate";
import PlaceholderIcon from "@mui/icons-material/EventTwoTone";
import { RootState } from "../../redux";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import { fetchSearchEvents } from "../../redux/event/actions";
import { red } from "@mui/material/colors";
import { selectorGetCostBundleById } from "../../redux/costBundle/selectors";
import { selectorGetEventsByModelId } from "../../redux/event/selectors";
import { selectorGetModelById } from "../../redux/model/selectors";
import { selectorGetTaskById } from "../../redux/task/selectors";
import { useFetchEventsPageHook } from "./Hooks";
import ModalEventDuplicate from "./modals/ModalEventDuplicate";

const imageDiameter = 50;

const DivWrapper = styled("div")(({ theme }) => ({
  "& .eventWrapper": {
    padding: theme.spacing(1),
    position: "relative",
    backgroundColor: "rgba(0,0,0,0)",
  },
  "& .eventImageWrapper": {
    width: imageDiameter,
    borderRadius: "50%",
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
  "& .eventMainWrapper": {
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
  "& .btnAddEvent": {
    display: "flex",
    flexGrow: 1,
    marginLeft: "auto",
    marginRight: 0,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  "& .redText": {
    color: red[500],
  },
}));

type IEventModelBuilderTabProps = {
  modelId: string;
  canEdit: boolean;
};

const EventModelBuilderTab = ({ modelId, canEdit }: IEventModelBuilderTabProps) => {
  const model = useSelector((state: RootState) => selectorGetModelById(state, modelId));
  const dispatch = useDispatch();
  const events = useSelector((store: RootState) => selectorGetEventsByModelId(store, modelId));
  const onUploadComplete = async (eventId: string) => {
    await dispatch(fetchSearchEvents({ eventId, modelId, pageNumber: 1, pageSize: 1 }));
  };

  useFetchEventsPageHook({
    pageNumber: 1,
    pageSize: 200,
    modelId: modelId,
    minPageNumberToFetch: 1,
  });

  return (
    <EventModelBuilderTabDisplay
      model={model}
      canEdit={canEdit}
      events={events}
      loading={false}
      onUploadComplete={onUploadComplete}
    />
  );
};

type IEventModelBuilderTabDisplayProps = {
  canEdit: boolean;
  model?: IModel;
  events: IEvent[];
  loading: boolean;
  onUploadComplete(eventId: string): void;
};

const EventModelBuilderTabDisplay = ({
  model,
  events,
  loading,
  canEdit,
  onUploadComplete,
}: IEventModelBuilderTabDisplayProps) => {
  const [showCreateEventModal, setShowCreateEventModal] = useState<boolean>(false);
  const [eventToUpdate, setEventToUpdate] = useState<IEvent>();
  const [eventToDuplicate, setEventToDuplicate] = useState<IEvent>();

  function toggleShowCreateEventModal() {
    setShowCreateEventModal(!showCreateEventModal);
  }

  function onEventClick(event: IEvent, isDuplicate: boolean) {
    if (isDuplicate) setEventToDuplicate(event);
    else setEventToUpdate(event);
  }

  function hideEventUpdate() {
    setEventToUpdate(undefined);
  }

  function hideEventDuplicate() {
    setEventToDuplicate(undefined);
  }
  if (!model) return <Typography variant="caption">Model not found.</Typography>;

  return (
    <DivWrapper>
        <Button color="primary" variant="outlined" className="btnAddEvent" onClick={toggleShowCreateEventModal}>
          Add Event
        </Button>

      {loading ? (
        <LoaderAbsoluteCentred loading={loading} />
      ) : (
        <AnimationWrapper>
          <div>
            {events.map((event) => {
              return (
                <React.Fragment key={event.eventId}>
                  <ModelBuilderEvent
                    event={event}
                    canEdit={canEdit}
                    onUploadComplete={onUploadComplete}
                    onEventClick={onEventClick}
                  />
                  <Divider light={true} />
                </React.Fragment>
              );
            })}
            {(!events || !events.length) && (
              <WidgetNoResultsPlaceholder text="No events" icon={PlaceholderIcon} flip={true} />
            )}
          </div>
        </AnimationWrapper>
      )}

        <ModalEventCreate
          open={showCreateEventModal}
          onCancelCallback={toggleShowCreateEventModal}
          onCompleteCallback={toggleShowCreateEventModal}
          modelId={model.modelId}
        />

      {/* Update */}
      {eventToUpdate && (
        <ModalEventUpdate
          open={eventToUpdate !== undefined}
          onCancelCallback={hideEventUpdate}
          onCompleteCallback={hideEventUpdate}
          event={eventToUpdate}
          canEdit={canEdit}
        />
      )}

      {eventToDuplicate && (
        <ModalEventDuplicate
          open={!!eventToDuplicate}
          onCancelCallback={hideEventDuplicate}
          onCompleteCallback={hideEventDuplicate}
          event={eventToDuplicate}
          modelId={model.modelId}
        />
      )}
    </DivWrapper>
  );
};

interface IModelBuilderEventProps {
  event: IEvent;
  canEdit: boolean;
  onUploadComplete(eventId: string): void;
  onEventClick(event: IEvent, isDuplicate: boolean): void;
}

function ModelBuilderEvent({ event, onEventClick, canEdit }: IModelBuilderEventProps) {
  const [loading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const task = useSelector((store: RootState) => selectorGetTaskById(store, event.taskId));
  const costbundle = useSelector((store: RootState) => selectorGetCostBundleById(store, event.costBundleId));
  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  async function onEditEventClick(isDuplicate: boolean) {
    setMenuAnchorEl(undefined);
    onEventClick(event, isDuplicate);
  }

  return (
    <Grid2 container className="eventWrapper">
      <Grid2 size={{xs:9}}>
        <div className="eventMainWrapper">
          <div>
            <Typography variant="body1" style={{ textTransform: "capitalize" }}>
              {event.name}
            </Typography>
            <Typography variant="caption">Description: {event.description} </Typography>
            <Typography variant="caption" className={task ? "" : "redText"}>
              Task: {task?.description ?? "Empty"}{" "}
            </Typography>
            <Typography variant="caption" className={costbundle ? "" : "redText"}>
              CostBundle: {costbundle?.name ?? "Empty"}{" "}
            </Typography>
          </div>
        </div>
      </Grid2>
      <Grid2 size={{xs:3}} style={{ textAlign: "right" }}>
        {canEdit ? (
          <>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(undefined)}
            >
              <MenuItem onClick={() => onEditEventClick(false)}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Edit Event" />
              </MenuItem>
              <MenuItem onClick={() => onEditEventClick(true)}>
                <ListItemIcon>
                  <FileCopyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Clone Event" />
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </Grid2>
      <LoaderAbsoluteCentred loading={loading} />
    </Grid2>
  );
}

export default EventModelBuilderTab;
