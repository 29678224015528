import React, { Fragment, useState } from "react";
import {
  Typography,
  Button,
  Grid2,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetModelById } from "../../redux/model/selectors";
import { IModel } from "../../utilities/types/Model";
import { ICostBundle } from "../../utilities/types/CostBundle";
import { selectorGetCostBundlesByModelId } from "../../redux/costBundle/selectors";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import ModalCostBundleCreate from "./modals/ModalCostBundleCreate";
import { fetchSearchCostBundles } from "../../redux/costBundle/actions";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import ModalCostBundleUpdate from "./modals/ModalCostBundleUpdate";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import PlaceholderIcon from "@mui/icons-material/ReceiptTwoTone";
import EditIcon from "@mui/icons-material/EditTwoTone";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import { EmptyGuid, FormatNumberWithCommas } from "../../utilities/Helpers";
import ModalCostBundleDuplicate from "./modals/ModalCostBundleDuplicate";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import IdentifierMappingsRenderer from "../identifierMapping/IdentifierMappingsRenderer";
import { IdentifierMappingTypeEnum } from "../../utilities/types/IdentifierMapping";

const imageDiameter = 50;

const DivWrapper = styled('div')(({ theme }) => ({
  "&. costBundleWrapper": {
    padding: theme.spacing(1),
    position: "relative",
    backgroundColor: "rgba(0,0,0,0)",
  },
  "& .costBundleImageWrapper": {
    width: imageDiameter,
    borderRadius: "50%",
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
  "& .costBundleMainWrapper": {
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
  "& .btnAddCostBundle": {
    display: "flex",
    flexGrow: 1,
    marginLeft: "auto",
    marginRight: 0,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  "& .headerLeft": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 4,
  },
}));

type ICostBundleModelBuilderTabProps = {
  modelId: string;
  canEdit: boolean;
};

const CostBundleModelBuilderTab = ({ modelId, canEdit }: ICostBundleModelBuilderTabProps) => {
  const model = useSelector((state: RootState) => selectorGetModelById(state, modelId));
  const dispatch = useDispatch();
  const costBundles = useSelector((store: RootState) => selectorGetCostBundlesByModelId(store, modelId));
  const onUploadComplete = async (costBundleId: string) => {
    await dispatch(fetchSearchCostBundles({ costBundleId, modelId, pageNumber: 1, pageSize: 1 }));
  };

  return (
    <CostBundleModelBuilderTabDisplay
      model={model}
      canEdit={canEdit}
      costBundles={costBundles}
      loading={false}
      onUploadComplete={onUploadComplete}
    />
  );
};

type ICostBundleModelBuilderTabDisplayProps = {
  canEdit: boolean;
  model?: IModel;
  costBundles: ICostBundle[];
  loading: boolean;
  onUploadComplete(costBundleId: string): void;
};

const CostBundleModelBuilderTabDisplay = ({
  model,
  costBundles,
  loading,
  canEdit,
  onUploadComplete,
}: ICostBundleModelBuilderTabDisplayProps) => {
  const [showCreateCostBundleModal, setShowCreateCostBundleModal] = useState(false);
  const [costBundleToUpdate, setCostBundleToUpdate] = useState<ICostBundle>();
  const [costBundleToDuplicate, setCostBundleToDuplicate] = useState<ICostBundle>();

  function toggleShowCreateCostBundleModal() {
    setShowCreateCostBundleModal(!showCreateCostBundleModal);
  }

  function onCostBundleClick(costBundle: ICostBundle, isDuplicate: boolean) {
    if (isDuplicate) setCostBundleToDuplicate(costBundle);
    else setCostBundleToUpdate(costBundle);
  }

  function hideCostBundleUpdate() {
    setCostBundleToUpdate(undefined);
  }

  function hideCostBundleDuplicate() {
    setCostBundleToDuplicate(undefined);
  }

  if (!model) return <Typography variant="caption">Model not found.</Typography>;

  return (
    <DivWrapper >
        <Button
          color="primary"
          variant="outlined"
          className="btnAddCostBundle"
          onClick={toggleShowCreateCostBundleModal}
        >
          Add Cost Bundle
        </Button>

      {loading ? (
        <LoaderAbsoluteCentred loading={loading} />
      ) : (
        <AnimationWrapper>
          <div>
            {costBundles.map((costBundle) => {
              return (
                <Fragment key={costBundle.costBundleId}>
                  <ModelBuilderCostBundle
                    costBundle={costBundle}
                    canEdit={canEdit}
                    onUploadComplete={onUploadComplete}
                    onCostBundleClick={onCostBundleClick}
                  />
                  <Divider light={true} />
                </Fragment>
              );
            })}
            {(!costBundles || !costBundles.length) && (
              <WidgetNoResultsPlaceholder text="No cost bundles" icon={PlaceholderIcon} flip={true} />
            )}
          </div>
        </AnimationWrapper>
      )}

        <ModalCostBundleCreate
          open={showCreateCostBundleModal}
          onCancelCallback={toggleShowCreateCostBundleModal}
          onCompleteCallback={toggleShowCreateCostBundleModal}
          modelId={model.modelId}
        />

      {/* Update */}
      {costBundleToUpdate && (
        <ModalCostBundleUpdate
          open={costBundleToUpdate !== undefined}
          onCancelCallback={hideCostBundleUpdate}
          onCompleteCallback={hideCostBundleUpdate}
          costBundle={costBundleToUpdate}
          canEdit={canEdit}
        />
      )}

      {costBundleToDuplicate && (
        <ModalCostBundleDuplicate
          open={!!costBundleToDuplicate}
          onCancelCallback={hideCostBundleDuplicate}
          onCompleteCallback={hideCostBundleDuplicate}
          costBundle={costBundleToDuplicate}
        />
      )}
    </DivWrapper>
  );
};

interface IModelBuilderCostBundleProps {
  costBundle: ICostBundle;
  canEdit: boolean;
  onUploadComplete(costBundleId: string): void;
  onCostBundleClick(costBundle: ICostBundle, isDuplicate: boolean): void;
}

function ModelBuilderCostBundle({ costBundle, onCostBundleClick, canEdit }: IModelBuilderCostBundleProps) {
  const [loading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  async function onEditOrDuplicateCostBundleClick(isDuplicate: boolean) {
    setMenuAnchorEl(undefined);
    onCostBundleClick(costBundle, isDuplicate);
  }

  return (
    <Grid2 container className="costBundleWrapper">
      <Grid2 size={{xs:9}}>
        <div className="costBundleMainWrapper">
            <Typography variant="body1">
              {costBundle.code} - <span style={{ opacity: 0.7 }}>${FormatNumberWithCommas(costBundle.cost)}</span>
            </Typography>
            <div className="headerLeft">
            <Typography variant="caption">{costBundle.name}</Typography>
            <IdentifierMappingsRenderer
              type={IdentifierMappingTypeEnum.CostBundle}
              targetId={String(costBundle.costBundleId)}
              modelId={costBundle.modelId}
              canEdit={canEdit}
              loading={false}
              showValues={true}
              secondaryTargetId={EmptyGuid}
            />
            </div>
        </div>
      </Grid2>
      <Grid2 size={{xs:3}} style={{ textAlign: "right" }}>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick} >
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(undefined)}
            >
              <MenuItem onClick={() => onEditOrDuplicateCostBundleClick(false)}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Edit Cost Bundle" />
              </MenuItem>
              <MenuItem onClick={() => onEditOrDuplicateCostBundleClick(true)}>
                <ListItemIcon>
                  <FileCopyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Clone Cost Bundle" />
              </MenuItem>
            </Menu>
      </Grid2>
      <LoaderAbsoluteCentred loading={loading} />
    </Grid2>
  );
}

export default CostBundleModelBuilderTab;
